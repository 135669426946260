import {RenderField, enhancer} from '../../share/Field'
import {withProps, defaultProps, compose, withHandlers} from 'recompose'

const InputComponent = enhancer(RenderField)

const Input = {}

Input.Gender = defaultProps({
  className: 'gender',
  type: 'switch',
})(InputComponent)

Input.Number = compose(
  withProps(props => ({
    type: 'text',
  })),
  // // withHandlers({
  // //   onChange: props => ,
  // // }),
  // withProps(props => ({
  //   input: {...props.input, onChange: props.onChange, onBlur: props.onChange},
  // })),
)(InputComponent)

export default Input
