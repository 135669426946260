import React from 'react'
import {connect} from 'react-redux'
import {compose, withState, withProps} from 'recompose'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CustomField from '../../share/Field'
import {Field} from 'redux-form'
import ReactDOM from 'react-dom'
import useWindowSize from '@rehooks/window-size'

import {responsive, breakpoints} from '../../../core/style'

import Button from '../../share/Button'
import Icon from '../../share/Icon'

import {premiumPay, coveragePeriod, noneStar} from '../options'

import {fetchProductList} from '../../../ducks/product'

const Container = styled.div`
  position: relative;
`

const Mask = styled.div`
  display: ${props => (props.isFilterActive ? 'flex' : 'none')};
  ${props =>
    responsive.extra(css`
      position: ${props.isFilterActive ? 'fixed' : 'fixed'};
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100vw;
      height: ${props.isFilterActive ? '100vh' : '100%'};
      z-index: ${props.isFilterActive ? '6' : '-1'};
      opacity: ${props.isFilterActive ? '0.9' : '0'};
      transition: opacity 0.5s;
      cursor: pointer;
    `)};
`
const FilterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  width: 342px;
  padding: 0 42px;
  transition: 0.5s;
  z-index: 7;
  -webkit-overflow-scrolling: touch;

  ${props =>
    responsive.extra(css`
      position: fixed;
      top: 160px;
      background-color: white;
      height: ${props.isFilterActive ? 'calc(100vh - 160px)' : 'unset'};
      left: ${props.isFilterActive ? '0' : '-342px'};
      overflow-y: ${props.isFilterActive ? 'scroll' : 'unset'};
    `)};

  ${props =>
    responsive.tablet(css`
      position: fixed;
      top: 160px;
      background-color: white;
      height: ${props.isFilterActive ? 'calc(100vh - 160px)' : 'unset'};
      left: ${props.isFilterActive ? '0' : '-342px'};
      overflow-y: ${props.isFilterActive ? 'scroll' : 'unset'};
    `)};
`
const FilterAndSortPart = styled.div`
  display: none;

  ${props =>
    responsive.extra(css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      margin: 10px 0px;
    `)};
`

const FilterAndSortText = styled.span`
  color: #666;
  font-size: 16px;
  margin-right: 10px;
`
const KendoIcon = styled(Icon)`
  color: #666;
  font-size: 20px;
`
const FilterIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  margin: 33px 11px 31px 0;
  color: #666;
`
const ChevronUpIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  color: #265fa7;
`

const FilterHeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`
const FilterHeaderContent = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #265fa7;
  min-width: fit-content;
`

const Line = styled.div`
  background-color: #265fa7;
  height: 2px;
  width: 100%;
  margin-left: 20px;
  margin-right: 17px;
`

const CustomCheckboxes = styled(CustomField)`
  margin: unset;

  &.insuranceType {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '180px')};
    transition: 0.5s;
  }
  &.type {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '220px')};
    transition: 0.5s;
  }
  &.others {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '88px')};
    transition: 0.5s;
  }
  &.companies {
    overflow: ${props => (props.collapseActive ? 'hidden' : 'unset')};
    height: ${props => (props.collapseActive ? '0' : 'fit-content')};
    transition: 0.5s;
  }
  &.carType {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '220px')};
    transition: 0.5s;
  }
  &.firstDamage {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '44px')};
    transition: 0.5s;
  }
  &.othersCoverage {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '176px')};
    transition: 0.5s;
  }
  &.fixing {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '88px')};
    transition: 0.5s;
  }

  &.otherDamage {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '100%')};
  }
  &.insurerCompany {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '100%')};
  }
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .ant-checkbox-group {
    width: 100%;
  }
  .ant-checkbox-group-item {
    margin-right: unset;
  }
  .ant-checkbox-wrapper + span,
  .ant-checkbox + span {
    padding-left: 1px;
  }
`

const Radios = styled(CustomField)`
  width: 100%;

  .ant-radio-wrapper {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0 !important;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ant-radio-wrapper span {
    padding-left: 0;
  }
  &.firstDamage {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '60px')};
    transition: 0.5s;
    margin-bottom: ${props => (props.collapseActive ? '0' : '15px')};
  }
`

const CustomCheckbox = styled(CustomField)`
  margin: unset;

  &.noonStar {
    overflow: hidden;
    height: ${props => (props.collapseActive ? '0' : '93px')};
    transition: 0.5s;
  }

  .checkboxContainer {
    margin-right: unset;
    width: 100%;
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .label {
    margin-left: unset;
  }
`

const FilterContentCheckboxesPartContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ContainerSliderInfo = styled.div`
  width: 259px;
  overflow: ${props => (props.collapseActive ? 'hidden' : 'visible')};
  height: ${props => (props.collapseActive ? '0' : '300px')};
  transition: 0.5s;
`

const HeaderSlider = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderSmall = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`

const Slider = styled(CustomField)`
  height: unset;
  .ant-slider {
    width: 100%;
    margin-left: unset;
  }
`

const Selector = styled(CustomField)`
  margin-bottom: 25px;
  flex-direction: column;
  height: unset;

  text-align: center;
  .label {
    justify-content: flex-start;
    margin-bottom: 15px;
  }
`

const NoonStarContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${props => (props.collapseActive ? '0' : '58px')};
  transition: 0.5s;
`

const NoonStarScoreContainer = styled.div`
  display: flex;
  align-items: center;
`

const StarText = styled.div`
  font-size: 16px;
`

const StarIcon = styled(FilterIcon)`
  margin: 14px 5px 20px 0;
  color: ${props =>
    props.isSelected || props.isHoverStar ? '#ffd900' : '#e5e5e5'};

  :hover {
    color: #ffd900;
  }
`

const ButtonContainer = styled.div`
  position: sticky;
  bottom: ${props => (props.compareActive ? '130px' : '40px')};
  left: 42px;
  width: 250px;
  height: 32px;
  justify-content: center;
  z-index: 7;

  ${() =>
    responsive.extra(css`
      height: 90px;
      > div {
        display: none;
      }
    `)};
`

const MobileButtonContainer = styled.div`
  bottom: 40px;
  left: ${props => (props.isFilterActive ? '42px' : '-332px')};
  width: 250px;
  display: none;
  justify-content: center;
  transition: 0.5s;

  ${() =>
    responsive.extra(css`
      position: fixed;
      display: flex;
      z-index: 7;
    `)};
`

const CustomButton = styled(Button)`
  width: 100px;
  align-self: center;
`

const FilterWrapper = styled.div``

const noonstar = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}]

const StarEnhancer = compose(withState('hoveredStar', 'setHoveredStar', 0))

const Star = StarEnhancer(
  ({input, hoveredStar, setHoveredStar, collapseActive}) => (
    <NoonStarContainer collapseActive={collapseActive}>
      <NoonStarScoreContainer>
        {noonstar.map((value, index) => (
          <StarIcon
            isHoverStar={index < hoveredStar}
            onClick={() => input.onChange(value.id)}
            onMouseOver={() => setHoveredStar(value.id)}
            onMouseOut={() => setHoveredStar(0)}
            isSelected={index < input.value}
            key={value.id}
            icon={['fas', 'star']}
          />
        ))}
        <StarText>ขึ้นไป</StarText>
      </NoonStarScoreContainer>
      {/* <CustomCheckbox
        name="noonStar"
        label="ไม่ระบุ"
        isNegation={true}
        type="checkbox"
        options={noneStar}
      /> */}
    </NoonStarContainer>
  ),
)

const ContentRenderer = ({value, collapseActive, companiesOptions, level}) => {
  if (value.type === 'checkboxes' || value.type === 'company') {
    if (level === 'intermediate' && value.name === 'insuranceType') {
      return null
    } else {
      return (
        <CustomCheckboxes
          className={value.classname}
          name={value.name}
          type={value.type}
          options={
            value.name === 'companyIds' ? companiesOptions : value.options
          }
          collapseActive={collapseActive}
          checkAll={value.checkAll}
        />
      )
    }
  }

  if (value.type === 'radios') {
    if (level === 'intermediate' && value.name === 'insuranceType') {
      return null
    } else {
      return (
        <Radios
          className={value.classname}
          name={value.name}
          type={value.type}
          options={
            value.name === 'companyIds' ? companiesOptions : value.options
          }
          collapseActive={collapseActive}
        />
      )
    }
  }

  if (value.type === 'slider') {
    return (
      <ContainerSliderInfo collapseActive={collapseActive}>
        <HeaderSlider>
          <HeaderSmall>เน้นคุ้มค่า</HeaderSmall>
          <HeaderSmall>เน้นคุ้มครอง</HeaderSmall>
        </HeaderSlider>
        <Slider type="slider" name="weightType" min={1} max={5} />
        <Selector
          name="payPeriod"
          label="ระยะเวลาชำระเบี้ย"
          type="selector"
          placeholder="ไม่ระบุ"
          options={premiumPay}
          themeColor="orange"
        />
        <Selector
          name="protectionPeriodMin"
          label="ระยะเวลาคุ้มครอง"
          type="selector"
          placeholder="ไม่ระบุ"
          options={coveragePeriod}
          themeColor="orange"
        />
      </ContainerSliderInfo>
    )
  }
  if (value.type === 'star') {
    return (
      <Field name="noonStar" component={Star} collapseActive={collapseActive} />
    )
  }
}

const FilterContentCheckboxesPartEnhancer = compose(
  withState('collapseActive', 'setCollapseActive', false),
)

const FilterContentCheckboxesPart = FilterContentCheckboxesPartEnhancer(
  ({value, setCollapseActive, collapseActive, companiesOptions, level}) => (
    <FilterContentCheckboxesPartContainer>
      {level === 'intermediate' && value.name !== 'insuranceType' && (
        <FilterHeaderContentContainer
          onClick={() => setCollapseActive(!collapseActive)}>
          <FilterHeaderContent>{value.checkboxesHeadName}</FilterHeaderContent>
          <Line />
          <ChevronUpIcon
            icon={collapseActive ? ['fas', 'angle-down'] : ['fas', 'angle-up']}
          />
        </FilterHeaderContentContainer>
      )}
      {level !== 'intermediate' && (
        <FilterHeaderContentContainer
          onClick={() => setCollapseActive(!collapseActive)}>
          <FilterHeaderContent>{value.checkboxesHeadName}</FilterHeaderContent>
          <Line />
          <ChevronUpIcon
            icon={collapseActive ? ['fas', 'angle-down'] : ['fas', 'angle-up']}
          />
        </FilterHeaderContentContainer>
      )}
      <ContentRenderer
        collapseActive={collapseActive}
        value={value}
        companiesOptions={companiesOptions}
        level={level}
      />
    </FilterContentCheckboxesPartContainer>
  ),
)

const withPortal = id => Component => props => {
  const windowSize = useWindowSize()
  return windowSize.innerWidth < breakpoints.mobile &&
    document.getElementById(id) ? (
    ReactDOM.createPortal(<Component {...props} />, document.getElementById(id))
  ) : (
    <Component {...props} />
  )
}

const FilterSidebar = withPortal('main_header')(
  ({
    setFilterActive,
    isFilterActive,
    filterList,
    companiesOptions,
    level,
    fetchProductList,
    category,
    params,
    compareList,
  }) => (
    <Container id="slidebar">
      <Mask
        isFilterActive={isFilterActive}
        onClick={() => {
          setFilterActive(false)
          fetchProductList(category, params)
        }}
      />
      <FilterContainer isFilterActive={isFilterActive}>
        <FilterWrapper>
          <FilterAndSortPart
            onClick={() => {
              setFilterActive(false)
              fetchProductList(category, params)
            }}>
            <KendoIcon icon="filter" />
            <FilterAndSortText>ปรับตัวเลือก</FilterAndSortText>
          </FilterAndSortPart>
          {filterList.map(value => (
            <FilterContentCheckboxesPart
              key={value.id}
              value={value}
              companiesOptions={companiesOptions}
              level={level}
            />
          ))}
          <ButtonContainer compareActive={compareList.length >= 1}>
            <CustomButton
              onClick={() => {
                setFilterActive(false)
                fetchProductList(category, params)
              }}
              icons={['fa', 'search']}>
              ค้นหาแบบประกัน
            </CustomButton>
          </ButtonContainer>
        </FilterWrapper>
      </FilterContainer>
      <MobileButtonContainer isFilterActive={isFilterActive}>
        <CustomButton
          onClick={() => {
            setFilterActive(false)
            fetchProductList(category, params)
          }}
          icons={['fa', 'search']}>
          ค้นหาแบบประกัน
        </CustomButton>
      </MobileButtonContainer>
    </Container>
  ),
)

const enhancer = compose(
  withState('isSelected', 'setSelected', false),
  withState('collapseActive', 'setCollapseActive', false),
  connect(
    (state, props) => ({
      filterList: state.product.filterList,
      companiesOptions: state.options.companiesOption,
      compareList: state.product.compareList,
    }),
    {fetchProductList},
  ),
)

export default enhancer(FilterSidebar)
