import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, branch, withProps, withHandlers, defaultProps} from 'recompose'
import {displayNumber, numberParser} from '../../../core/helper'
import {
  withSpidus,
  withValue,
  withCaption,
  withDisabled,
  omitProps,
} from '../enhances/index'
import TextInput from '../../share/Field/TextInput'

const disabledStyled = css`
  ${'' /* opacity: 0.8; */}
  background-color: #e5e5e5;
`

const ReadOnlyInput = styled.div`
  text-align: right;
`

const CustomTextInput = props => (
  <>
    {props.readOnly ? (
      <ReadOnlyInput>{props.value}</ReadOnlyInput>
    ) : (
      <TextInput {...props} />
    )}
  </>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption(),
  withDisabled(disabledStyled),
  defaultProps({maxLength: 8, readOnly: false}),
  withHandlers({
    onChange: props => e => {
      const value = e.target.value ? numberParser(e.target.value) : null
      if (!value || value < 10 ** props.maxLength) {
        props.onChange && props.onChange(value)
      }
    },
    onBlur: props => e => {
      props.onBlur && props.onBlur(props.value)
    },
  }),
  withProps(props => ({
    value:
      props.value === undefined || props.value === null
        ? ''
        : displayNumber(props.value),
  })),
  omitProps('maxLength'),
)

export default enhance(CustomTextInput)
