import {takeLatest, put, select} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {httpGet, httpPost, API_HOST, httpDelete} from '../../core/httpClient'
import humps from 'humps'
import {navigate} from '@reach/router'
import {removefilterObj, obj2Url, filterObj} from '../../core/helper'
import {
  LifeCheckboxesHeaderName,
  motorCheckboxesHeaderName,
  healthCheckboxesHeaderName,
} from './cardDetails'

import {getFavorite} from '../profile'

import {setDataProfile} from '../../ducks/profile'

import packageMockData from './data.json'

const ADD_PRODUCT_COMPARE = 'ADD_PRODUCT_COMPARE'
const ADD_COMPARE_LIST = 'ADD_COMPARE_LIST'
const DELETE_PRODUCT_COMPARE = 'DELETE_PRODUCT_COMPARE'
const SET_COMPARE_LIST = 'SET_COMPARE_LIST'
const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL'
const SET_DATA_PRODUCT = 'SET_DATA_PRODUCT'
const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST'
const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
const SET_LIFE_INSURANCE_PRODUCT_LIST = 'SET_LIFE_INSURANCE_PRODUCT_LIST'
const SET_HEALTH_INSURANCE_PRODUCT_LIST = 'SET_HEALTH_INSURANCE_PRODUCT_LIST'
const SET_PENSION_INSURANCE_PRODUCT_LIST = 'SET_PENSION_INSURANCE_PRODUCT_LIST'
const SET_FILTER_LIST = 'SET_FILTER_LIST'
const GET_FULLBENEFIT = 'GET_FULLBENEFIT'
const SAVE_PLANNING = 'SAVE_PLANNING'
const SET_PRODUCT_COMPARE_LIST = 'SET_PRODUCT_COMPARE_LIST'
const RESET_COMPARE_LIST = 'RESET_COMPARE_LIST'
const BUY_PRODUCT = 'BUY_PRODUCT'
const SET_PRODUCT_DETAILS_COMPARE_LIST = 'SET_PRODUCT_DETAILS_COMPARE_LIST'
const SET_AGENT_INFO = 'SET_AGENT_INFO'
const FETCH_PRODUCT_COMPARE_LIST = 'FETCH_PRODUCT_COMPARE_LIST'
const SET_FAVORITE_PRODUCT = 'SET_FAVORITE_PRODUCT'
const GET_SUGGESTION_PRODUCT_DETAILS = 'GET_SUGGESTION_PRODUCT_DETAILS'
const FETCH_INSURANCE_POLICY_NAME = 'FETCH_INSURANCE_POLICY_NAME'
const SET_INSURANCE_POLICY_NAME = 'SET_INSURANCE_POLICY_NAME'
const GET_COVERAGE_TABLE = 'GET_COVERAGE_TABLE'
const GET_LIFE_INSURANCE_NAME = 'GET_LIFE_INSURANCE_NAME'
const GET_PACKAGE_INSURANCE_POLICY = 'GET_PACKAGE_INSURANCE_POLICY'
const SET_LEAD_PERSONAL_INFO = 'SET_LEAD_PERSONAL_INFO'
const CLEAR_LEAD_PERSONAL_INFO = 'CLEAR_LEAD_PERSONAL_INFO'

export const getPackageInsurancePolicy = Creator(
  GET_PACKAGE_INSURANCE_POLICY,
  'data',
)

export const setInsurancePolicyName = Creator(SET_INSURANCE_POLICY_NAME, 'data')

export const fetchInsurancePolicyName = Creator(
  FETCH_INSURANCE_POLICY_NAME,
  'insurancePolicyName',
)

export const getSuggestionProductDetails = Creator(
  GET_SUGGESTION_PRODUCT_DETAILS,
  'suggestionDetails',
  'productCategory',
  'callback',
)

export const setFavoriteProduct = Creator(
  SET_FAVORITE_PRODUCT,
  'favoriteState',
  'id',
  'category',
  'paramsEncode',
)
export const fetchProductCompareList = Creator(
  FETCH_PRODUCT_COMPARE_LIST,
  'category',
)
export const setAgentInfo = Creator(
  SET_AGENT_INFO,
  'insurancePolicyId',
  'agentInfo',
)
export const addProductCompare = Creator(ADD_PRODUCT_COMPARE, 'data')
export const deleteProductCompare = Creator(
  DELETE_PRODUCT_COMPARE,
  'productIndex',
  'compareList',
)
export const addCompareList = Creator(ADD_COMPARE_LIST, 'data')
export const deleteCompareList = Creator(SET_COMPARE_LIST, 'compareList')
export const setDataProduct = Creator(SET_DATA_PRODUCT, 'data')
export const fetchProductDetail = Creator(
  FETCH_PRODUCT_DETAIL,
  'id',
  'category',
  'params',
)
export const fetchProductList = Creator(
  FETCH_PRODUCT_LIST,
  'category',
  'filter',
)
export const setProductList = Creator(SET_PRODUCT_LIST, 'productList')
export const setLifeInsuranceProductList = Creator(
  SET_LIFE_INSURANCE_PRODUCT_LIST,
  'productList',
)
export const setHeathInsuranceProductList = Creator(
  SET_HEALTH_INSURANCE_PRODUCT_LIST,
  'productList',
)
export const setPensionInsuranceProductList = Creator(
  SET_PENSION_INSURANCE_PRODUCT_LIST,
  'productList',
)
export const setFilterList = Creator(SET_FILTER_LIST, 'filterList')
export const getFullBenefit = Creator(GET_FULLBENEFIT, 'id', 'params')
export const savePlanning = Creator(SAVE_PLANNING, 'data', 'type', 'category')
export const setProductDetailsCompareList = Creator(
  SET_PRODUCT_DETAILS_COMPARE_LIST,
  'productDetailsCompareList',
)
export const setProductCompareList = Creator(
  SET_PRODUCT_COMPARE_LIST,
  'params',
  'category',
)
export const resetCompareList = Creator(RESET_COMPARE_LIST)
export const buyProduct = Creator(BUY_PRODUCT, 'productDetails', 'category')

export const getCoverageTable = Creator(
  GET_COVERAGE_TABLE,
  'id',
  'age',
  'gender',
  'sumInsured',
)
export const getProductName = Creator(
  GET_LIFE_INSURANCE_NAME,
  'lifeInsurancePolicyId',
)
export const setLeadPersonalInfo = Creator(
  SET_LEAD_PERSONAL_INFO,
  'leadPersonalInfo',
)
export const clearLeadPersonalInfo = Creator(CLEAR_LEAD_PERSONAL_INFO)

export function* fetchInsurancePolicyNameSaga({
  payload: {insurancePolicyName},
}) {
  const url = `${API_HOST}life_insurance_policies/insurance_policy_names`

  const fetchResponseInsurancePolicyName = yield httpGet(
    url,
    insurancePolicyName,
  )

  const policyNameOptions = fetchResponseInsurancePolicyName.data.data.insurancePolicyNames.map(
    (value) => ({value: value.id, label: value.name}),
  )
  yield put(
    setInsurancePolicyName({
      insurancePolicyNameList: policyNameOptions,
    }),
  )
}

export function* getSuggestionProductDetailsSaga({
  payload: {suggestionDetails, productCategory, callback},
}) {
  yield put(setDataProduct({isLoading: true}))
  try {
    let weightType = ''
    let searchBy = ''
    let insuranceType = ''

    if (productCategory === 'saving-tax') {
      weightType = 1
      searchBy = 'premium'
      insuranceType = 'saving'
    }
    if (productCategory === 'term-life') {
      weightType = 5
      searchBy = 'sum_insured'
      insuranceType = 'term'
    }
    if (productCategory === 'tax-annuity') {
      weightType = 3
      searchBy = 'premium'
      insuranceType = 'annuity'
    }
    if (productCategory === 'saving-annuity') {
      weightType = 1
      searchBy = 'annuity_refund'
      insuranceType = 'annuity'
    }
    if (productCategory === 'whole-life') {
      weightType = 5
      searchBy = 'sum_insured'
      insuranceType = 'whole_life'
    }
    if (productCategory === 'scholarship-saving') {
      weightType = 3
      searchBy = 'premium'
      insuranceType = 'saving'
    }

    const newData = {
      ...suggestionDetails,
      weightType,
      searchBy,
      insuranceType,
    }

    const suggestionProductDetails = `${API_HOST}life_insurance_policies/top_noon_score`

    const fetchResponseSuggestionDetails = yield httpGet(
      suggestionProductDetails,
      newData,
    )

    navigate(
      `/smart/life/intermediate/product/${
        fetchResponseSuggestionDetails.data.data.lifeInsurancePolicy.id
      }?${obj2Url({
        ...fetchResponseSuggestionDetails.data.data.lifeInsurancePolicy,
        ...suggestionDetails,
      })}`,
    )
  } catch (error) {
    console.error(error.response || error)
    const {response = {}} = error
    if (response.status === 404) {
      callback()
    }
  }
  yield put(setDataProduct({isLoading: false}))
}

export function* setFavoriteProductSaga({
  payload: {favoriteState, id, category, paramsEncode},
}) {
  try {
    const urlFavoriteProduct = `${API_HOST}favorites/${id}`
    let insuranceType = ''
    if (category === 'life') {
      insuranceType = 'LifeInsurancePolicy'
    }
    if (category === 'motor') {
      insuranceType = 'AutoInsurancePolicy'
    }
    if (category === 'health') {
      insuranceType = 'HealthInsurancePolicy'
    }
    const favoriteProduct = yield select(
      (state) => state.product.favoriteProduct,
    )
    const favoriteProductProfile = yield select(
      (state) => state.profile.insurancePolicies,
    )
    const foundFavorite = favoriteProductProfile.find((fav) => fav.id === id)
    if (favoriteState) {
      const favoriteProductResponse = yield httpPost(urlFavoriteProduct, {
        favoritable_type: 'InsurancePolicy',
        paramsEncode,
        insuranceType,
      })
      if (favoriteProductResponse.data.code === 'success') {
        favoriteProduct[id] = true
        yield put(
          setDataProduct({
            detailFavorite: true,
            favoriteProduct: {...favoriteProduct},
          }),
        )
      }
    } else {
      const favoriteProductResponse = yield httpDelete(urlFavoriteProduct, {
        favoritable_type: 'InsurancePolicy',
        paramsEncode,
        insuranceType,
      })

      if (favoriteProductResponse.data.code === 'success') {
        favoriteProduct[id] = false
        yield put(
          setDataProduct({
            detailFavorite: false,
            favoriteProduct: {...favoriteProduct},
          }),
        )
      }
      if (foundFavorite) {
        yield put(
          setDataProfile({
            insurancePolicies: favoriteProductProfile.map((fav) =>
              fav.id === id ? {...fav, favorite: false} : fav,
            ),
          }),
        )
      }
    }

    yield put(getFavorite())
  } catch (error) {
    console.error(error)
  }
}

export function* fetchProductCompareListSaga({payload: {category}}) {
  try {
    yield put(setDataProduct({isLoading: true}))
    switch (category) {
      case 'motor':
        const urlMotorCompareList = `${API_HOST}auto_insurance_policies/compare/list`
        const fetchResponseMotorCompareList = yield httpGet(urlMotorCompareList)
        yield put(
          setProductDetailsCompareList(
            fetchResponseMotorCompareList.data.data.compareList,
          ),
        )
        break
      case 'life':
        const urlLifeCompareList = `${API_HOST}life_insurance_policies/compare/list`
        const fetchResponseLifeCompareList = yield httpGet(urlLifeCompareList)
        yield put(
          setProductDetailsCompareList(
            fetchResponseLifeCompareList.data.data.compareList,
          ),
        )
        break
      default:
        break
    }
  } catch (error) {
    switch (error.response.status) {
      case 401:
        window.location.reload()
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  } finally {
    yield put(setDataProduct({isLoading: false}))
  }
}

const getHeaderParams = () => {
  if (document) {
    return (document.getElementById('header-params') || {}).innerText || ''
  }
}

export function* buyProductSaga({payload: {productDetails, category}}) {
  yield put(setDataProduct({isLoadingModal: true}))
  const personalInfoFullname =
    productDetails.value.firstName + ' ' + productDetails.value.lastName

  const personalInfoBirthDate =
    productDetails.value.birthdate.getDate() +
    '/' +
    (productDetails.value.birthdate.getMonth() + 1) +
    '/' +
    (productDetails.value.birthdate.getFullYear() + 543)
  const personalInfoGender = productDetails.value.gender
  const personalInfoPhoneNumber = productDetails.value.telnumber
  const personalInfoEmail = productDetails.value.email
  const personalInfoContactTime = productDetails.value.time
  const paramsEncode = productDetails.paramsEncode
  const consent = productDetails.value.consent ? 'CS-MK-011164-006' : ''
  const urgentLevel = productDetails.value.urgent
  const searchingWord = getHeaderParams()

  try {
    switch (category) {
      case 'life':
        const urlLifeProductDetails = `${API_HOST}insurance_leads/life`

        const premium = productDetails.premium
        const insurancePolicyId = productDetails.id

        const idResponseLife = yield httpPost(urlLifeProductDetails, {
          premium,
          insurancePolicyId,
          personalInfoFullname,
          personalInfoBirthDate,
          personalInfoGender,
          personalInfoPhoneNumber,
          personalInfoEmail,
          personalInfoContactTime,
          paramsEncode,
          consent,
          urgentLevel,
          searchingWord,
        })

        yield put(
          setLeadPersonalInfo(
            idResponseLife.data.data.lifeInsuranceLead.personalInfo,
          ),
        )

        yield put(
          setAgentInfo(
            insurancePolicyId,
            idResponseLife.data.data.lifeInsuranceLead.agent,
          ),
        )
        break

      case 'motor':
        const urlMotorProductDetails = `${API_HOST}insurance_leads/auto`

        const promotionCode = productDetails.promotionCode
        const idKey = productDetails.params.idKey
        const carUsage = productDetails.params.carUsage
        const cover = productDetails.cover || productDetails.params.cover
        const vat = productDetails.vat
        const amount = productDetails.amount
        const amountNet = productDetails.amountNet
        const companyId = productDetails.companyId
        const filterInsuranceLevel = productDetails.insureLevel

        const idResponseMotor = yield httpPost(urlMotorProductDetails, {
          idKey,
          carUsage,
          cover,
          vat,
          amount,
          amountNet,
          companyId,
          filterInsuranceLevel,
          promotionCode,
          personalInfoFullname,
          personalInfoBirthDate,
          personalInfoGender,
          personalInfoPhoneNumber,
          personalInfoEmail,
          personalInfoContactTime,
          paramsEncode,
          consent,
          urgentLevel,
          searchingWord,
        })

        yield put(setAgentInfo(promotionCode, 'motorAgent'))

        break

      case 'health':
        const urlHealthProductDetails = `${API_HOST}insurance_leads/health`

        const packageId = productDetails.packageId

        const idResponseHealth = yield httpPost(urlHealthProductDetails, {
          packageId,
          personalInfoFullname,
          personalInfoBirthDate,
          personalInfoGender,
          personalInfoPhoneNumber,
          personalInfoEmail,
          personalInfoContactTime,
          paramsEncode,
          consent,
          urgentLevel,
          searchingWord,
        })

        yield put(
          setAgentInfo(
            packageId,
            idResponseHealth.data.data.healthInsuranceLead.agent,
          ),
        )
        break
      default:
        break
    }
  } catch (error) {
    console.error(error.response || error)
  } finally {
    yield put(setDataProduct({isLoadingModal: false}))
  }
}

export function* setProductCompareListSaga({payload: {params = {}, category}}) {
  const policyIdList = []

  if (params.ids)
    params.ids.map((value, index) => (policyIdList[index] = value))

  const companyIds =
    params.companyIds instanceof Array
      ? params.companyIds.reduce(
          (mem, cur, index) => (index === 0 ? cur : mem + ', ' + cur),
          '',
        )
      : params.companyIds

  const insuranceType =
    params.insuranceType instanceof Array
      ? params.insuranceType.reduce(
          (mem, cur, index) => (index === 0 ? cur : mem + ', ' + cur),
          '',
        )
      : params.insuranceType

  switch (category) {
    case 'life':
      yield put(setDataProduct({isLoading: true}))
      const urlLifeCompare = `${API_HOST}life_insurance_policies/compare`
      const lifeResponse = yield httpGet(urlLifeCompare, {
        policyIdList,
        ...params,
        companyIds,
        insuranceType,
      })

      const lifePolicyList = [
        ...lifeResponse.data.data.policyList,
        ...new Array(5 - lifeResponse.data.data.policyList.length),
      ]
      yield put(setProductDetailsCompareList(lifePolicyList))
      yield put(setDataProduct({isLoading: false}))
      break

    case 'motor':
      yield put(setDataProduct({isLoading: true}))
      const urlMotorCompare = `${API_HOST}auto_insurance_policies/compare`
      const motorResponse = yield httpGet(urlMotorCompare, {
        promotionCodeList: policyIdList,
        ...params,
      })

      const motorPolicyList = [
        ...motorResponse.data.data.policyList,
        ...new Array(5 - motorResponse.data.data.policyList.length),
      ]
      yield put(setProductDetailsCompareList(motorPolicyList))
      yield put(setDataProduct({isLoading: false}))
      break
    default:
      break
  }
}

export function* fetchProductListSaga({payload: {category, filter}}) {
  let newData = humps.decamelizeKeys(filter)
  if (filter.companyIds) {
    if (
      filter.companyIds[0] === 'all' ||
      filter.companyIds[0] === '' ||
      isNaN(filter.companyIds[0])
    ) {
      const modifiredCompany = filter.companyIds.slice(1)
      newData = {...newData, company_ids: modifiredCompany.toString()}
    } else {
      newData = {...newData, company_ids: filter.companyIds.toString()}
    }
  }
  if (filter.insuranceType) {
    newData = {...newData, insurance_type: filter.insuranceType.toString()}
  }

  switch (category) {
    case 'life':
      yield put(setFilterList(LifeCheckboxesHeaderName))
      yield put(setDataProduct({isLoading: true}))

      const urlLife =
        newData.level === 'advance'
          ? `${API_HOST}life_insurance_policies/advance_search`
          : `${API_HOST}life_insurance_policies/search_life_products`

      try {
        const response = yield httpGet(urlLife, newData)
        yield put(setProductList(response.data.data.lifeProducts))
        yield put(setLifeInsuranceProductList(response.data.data.lifeProducts))
        yield put(
          setDataProduct({
            favoriteProduct: response.data.data.lifeProducts.reduce(
              (mem, cur) => {
                mem[cur.id] = cur.favorite
                return mem
              },
              {},
            ),
          }),
        )
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
      }
      yield put(setDataProduct({isLoading: false}))
      yield put(resetCompareList())
      break
    case 'motor':
      yield put(setFilterList(motorCheckboxesHeaderName))
      yield put(setDataProduct({isLoading: true}))
      const urlAuto = `${API_HOST}auto_insurance_policies/search_auto_products`

      if (filter.otherDamage) {
        if (filter.otherDamage.includes('cover_lost_fire')) {
          newData = {...newData, cover_lost_fire: true}
        } else if (!filter.otherDamage.includes('cover_lost_fire')) {
          newData = {...newData, cover_lost_fire: false}
        }
        if (filter.otherDamage.includes('cover_flood')) {
          newData = {...newData, cover_flood: true}
        } else if (!filter.otherDamage.includes('cover_flood')) {
          newData = {...newData, cover_flood: false}
        }
        if (filter.otherDamage.includes('cover_terrorism')) {
          newData = {...newData, cover_terrorism: true}
        } else if (!filter.otherDamage.includes('cover_terrorism')) {
          newData = {...newData, cover_terrorism: false}
        }
        delete newData.other_damage
      }

      if (filter.coverCarSelfRepair) {
        delete newData.specify_other_driver
      }

      if (filter.repairServiceType) {
        if (filter.repairServiceType[0] === '') {
          const modifiredService = filter.repairServiceType.slice(1)
          newData = {
            ...newData,
            repair_service_type: modifiredService.toString(),
          }
        } else {
          newData = {
            ...newData,
            repair_service_type: filter.repairServiceType.toString(),
          }
        }
      }

      if (filter.insuranceLevel) {
        if (filter.insuranceLevel[0] === '') {
          const modifiredInsuranceLevel = filter.insuranceLevel.slice(1)
          newData = {
            ...newData,
            insurance_level: modifiredInsuranceLevel.toString(),
          }
        } else {
          newData = {
            ...newData,
            insurance_level: filter.insuranceLevel.toString(),
          }
        }
      }
      try {
        const response = yield httpGet(urlAuto, newData)
        yield put(setProductList(response.data.data.autoProducts))
        yield put(
          setDataProduct({
            favoriteProduct: response.data.data.autoProducts.reduce(
              (mem, cur) => {
                mem[cur.id] = cur.favorite
                return mem
              },
              {},
            ),
          }),
        )
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
      }
      yield put(setDataProduct({isLoading: false}))
      yield put(resetCompareList())
      break
    case 'health':
      yield put(setFilterList(healthCheckboxesHeaderName))
      yield put(setDataProduct({isLoading: true}))
      const urlHealth = `${API_HOST}health_insurance_policies/search_health_products`
      let healthData = {...newData}

      if (newData.insurance_type === 'hs') {
        healthData = {
          ...healthData,
          hs_type: 2,
          ci_type: 3,
          hs_amount: healthData.search_value,
        }
        healthData = removefilterObj(healthData, [
          'ci_diseases',
          'ci_roles',
          'ci_amount',

          'search_value',
          'weight_type',
          'sort_by',
        ])
      } else if (newData.insurance_type === 'ci') {
        healthData = {
          ...healthData,
          hs_type: 3,
          ci_type: 2,
          ci_amount: healthData.search_value,
        }
        healthData = removefilterObj(healthData, [
          'hs_goal',
          'hs_amount',

          'search_value',
          'weight_type',
          'sort_by',
        ])
      }
      try {
        const response = yield httpGet(urlHealth, healthData)
        yield put(setProductList(response.data.data.healthProducts))
        yield put(
          setHeathInsuranceProductList(response.data.data.healthProducts),
        )
        yield put(
          setDataProduct({
            favoriteProduct: response.data.data.healthProducts.reduce(
              (mem, cur) => {
                mem[cur.id] = cur.favorite
                return mem
              },
              {},
            ),
          }),
        )
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
      }
      yield put(setDataProduct({isLoading: false}))
      break
    default:
      break
  }
}

export function* addProductCompareSaga({payload: {data}}) {
  yield put(addCompareList(data))
}

export function* deleteProductCompareSaga({
  payload: {productIndex, compareList},
}) {
  compareList.splice(productIndex, 1)

  yield put(deleteCompareList(compareList))
}

export function* getFullBenefitSaga({payload: {id, params}}) {
  const url = `${API_HOST}life_insurance_policies/${id}/full_benefit`
  const newData = filterObj(params, ['age', 'gender', 'sumInsured'])

  try {
    const response = yield httpGet(url, newData)
    yield put(setDataProduct({fullBenefit: response.data.data}))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* fetchProductDetailSaga({payload: {id, category, params}}) {
  let newData = humps.decamelizeKeys(params)
  switch (category) {
    case 'life':
      yield put(setDataProduct({isLoading: true}))
      if (params.companyIds) {
        if (params.companyIds === 'NaN') {
          newData = {...newData, company_ids: ''}
        } else if (
          params.companyIds[0] === 'all' ||
          params.companyIds[0] === '' ||
          isNaN(params.companyIds[0])
        ) {
          const modifiredCompany = params.companyIds.slice(1)
          newData = {...newData, company_ids: modifiredCompany.toString()}
        } else {
          newData = {...newData, company_ids: params.companyIds.toString()}
        }
      }

      if (params.insuranceType) {
        newData = {...newData, insurance_type: params.insuranceType.toString()}
      }
      const url = `${API_HOST}life_insurance_policies/${id}`
      try {
        const response = yield httpGet(url, newData)
        yield put(
          setDataProduct({
            initializeProduct: true,
            productLifeDetail: response.data.data.lifeInsurancePolicy,
            fullBenefit: response.data.data.benefit,
          }),
        )
        yield put(setDataProduct({isLoading: false}))
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
        yield put(setDataProduct({isLoading: false, initializeProduct: true}))
      }
      break
    case 'package':
      yield put(setDataProduct({isLoading: true}))
      const urlPackage = `${API_HOST}package_policies/${id}`
      try {
        const response = yield httpGet(urlPackage, {
          age: params.age,
          gender: params.gender,
        })
        yield put(
          setDataProduct({
            initializeProduct: true,
            productPackageDetail: {
              ...response.data.data.package,
              insuranceType: 'life',
            },
            fullBenefit: response.data.data.package.benefit,
          }),
        )
        yield put(setDataProduct({isLoading: false}))
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
        yield put(setDataProduct({isLoading: false, initializeProduct: true}))
      }
      break
    case 'motor':
      yield put(setDataProduct({isLoading: true}))

      const urlMotor = `${API_HOST}auto_insurance_policies/auto_product_by_promotion_code`

      if (params.insuranceLevel) {
        if (
          params.insuranceLevel[0] === 'all' ||
          params.insuranceLevel[0] === '' ||
          isNaN(params.insuranceLevel[0])
        ) {
          const modiifredinsuranceLevel = params.insuranceLevel.slice(1)
          newData = {
            ...newData,
            insuranceLevel: modiifredinsuranceLevel.toString(),
          }
        } else {
          newData = {
            ...newData,
            insuranceLevel: params.insuranceLevel.toString(),
          }
        }
      }

      try {
        const response = yield httpGet(urlMotor, newData)
        initializeProduct: true,
          yield put(
            setDataProduct({
              productMotorDetail: response.data.data.autoProductFilter,
              recommendProductsDetail: response.data.data.recommendProducts,
              detailFavorite: response.data.data.autoProductFilter.favorite,
              favoriteProduct: response.data.data.recommendProducts.reduce(
                (mem, cur) => {
                  mem[cur.id] = cur.favorite
                  return mem
                },
                {},
              ),
            }),
          )
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
      }
      yield put(setDataProduct({isLoading: false, initializeProduct: true}))
      break
    case 'health':
      yield put(setDataProduct({isLoading: true}))
      const urlHealth = `${API_HOST}health_insurance_policies/${newData.package_id}`
      try {
        const response = yield httpGet(urlHealth)
        yield put(
          setDataProduct({
            initializeProduct: true,
            productHealthDetail: response.data.data.package,
            recommendProductsDetail: response.data.data.recommendProducts,
            detailFavorite: response.data.data.package.favorite,
            favoriteProduct: response.data.data.recommendProducts.reduce(
              (mem, cur) => {
                mem[cur.id] = cur.favorite
                return mem
              },
              {},
            ),
          }),
        )
      } catch (error) {
        switch (error.response.status) {
          default:
            yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
            break
        }
      }
      yield put(setDataProduct({isLoading: false, initializeProduct: true}))
      break
    default:
      break
  }
}

export function* savePlanningSaga({payload: {data, type, category}}) {
  let newType = humps.decamelize(type)
  let url
  if (category === 'life') {
    switch (type) {
      case 'intermediate':
        url = `${API_HOST}planning/life/non_starter`
        break
      case 'advance':
        url = `${API_HOST}planning/life/non_starter`
        break
      default:
        url = `${API_HOST}planning/life/starter/${newType}`
        break
    }
  }
  if (category === 'health') {
    url = `${API_HOST}planning/health`
  }
  if (category === 'auto') {
    url = `${API_HOST}planning/auto`
  }

  try {
    yield httpPost(url, data)
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* getCoverageTableSaga({
  payload: {id, age, gender, sumInsured},
}) {
  const url = `${API_HOST}life_insurance_policies/${id}/full_benefit`
  const newData = {age: age, gender: gender, sumInsured: sumInsured}

  try {
    const response = yield httpGet(url, newData)
    yield put(setDataProduct({fullBenefit: response.data.data}))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* getProductNameSaga({payload: {lifeInsurancePolicyId}}) {
  const url = `${API_HOST}life_insurance_policies/life_insurance_policy_name`

  try {
    const response = yield httpGet(url, {lifeInsurancePolicyId})
    yield put(
      setInsurancePolicyName({
        insurancePolicyNameList: [
          {
            value: lifeInsurancePolicyId,
            label: (response.data.data.insurancePolicyName || {}).name || '',
          },
        ],
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProduct({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* getPackageInsurancePolicySaga({payload: {data, filter}}) {
  yield put(setFilterList(LifeCheckboxesHeaderName))
  yield put(setProductList(packageMockData.data))
}

export function* watchProductSaga() {
  yield takeLatest(ADD_PRODUCT_COMPARE, addProductCompareSaga)
  yield takeLatest(DELETE_PRODUCT_COMPARE, deleteProductCompareSaga)
  yield takeLatest(FETCH_PRODUCT_DETAIL, fetchProductDetailSaga)
  yield takeLatest(FETCH_PRODUCT_LIST, fetchProductListSaga)
  yield takeLatest(GET_FULLBENEFIT, getFullBenefitSaga)
  yield takeLatest(SAVE_PLANNING, savePlanningSaga)
  yield takeLatest(SET_PRODUCT_COMPARE_LIST, setProductCompareListSaga)
  yield takeLatest(BUY_PRODUCT, buyProductSaga)
  yield takeLatest(FETCH_PRODUCT_COMPARE_LIST, fetchProductCompareListSaga)
  yield takeLatest(SET_FAVORITE_PRODUCT, setFavoriteProductSaga)
  yield takeLatest(
    GET_SUGGESTION_PRODUCT_DETAILS,
    getSuggestionProductDetailsSaga,
  )
  yield takeLatest(FETCH_INSURANCE_POLICY_NAME, fetchInsurancePolicyNameSaga)
  yield takeLatest(GET_COVERAGE_TABLE, getCoverageTableSaga)
  yield takeLatest(GET_LIFE_INSURANCE_NAME, getProductNameSaga)
  yield takeLatest(GET_PACKAGE_INSURANCE_POLICY, getPackageInsurancePolicySaga)
}

const initial = {
  initializeProduct: false,
  isLoading: false,
  isLoadingModal: false,
  compareList: [],
  saleRep: {},
  productLifeDetail: {hashTag: []},
  productMotorDetail: {hashTag: []},
  productHealthDetail: {hashTag: []},
  recommendProductsDetail: [],
  noOpacityHeader: false,
  productList: [],
  lifeInsuranceProductList: [],
  healthInsuranceProductList: [],
  pensionInsuranceProductList: [],
  filterList: [],
  agentInfo: {},
  favoriteProduct: {},
  insurancePolicyNameList: [],
  packageInsuranceProductList: [],
  leadPersonalInfo: {},
}

export default createReducer(initial, (state) => ({
  [ADD_COMPARE_LIST]: ({data}) => ({
    ...state,
    compareList: [...state.compareList, data],
  }),
  [SET_COMPARE_LIST]: ({compareList}) => ({
    ...state,
    compareList: [...compareList],
  }),
  [SET_DATA_PRODUCT]: ({data}) => ({...state, ...data}),
  [SET_INSURANCE_POLICY_NAME]: ({data}) => ({...state, ...data}),
  [SET_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    productList: [...productList],
  }),
  [SET_LIFE_INSURANCE_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    lifeInsuranceProductList: [...productList],
  }),
  [SET_HEALTH_INSURANCE_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    healthInsuranceProductList: [...productList],
  }),
  [SET_PENSION_INSURANCE_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    pensionInsuranceProductList: [...productList],
  }),
  [SET_FILTER_LIST]: ({filterList}) => ({
    ...state,
    filterList: [...filterList],
  }),
  [RESET_COMPARE_LIST]: () => ({...state, compareList: []}),
  [SET_PRODUCT_DETAILS_COMPARE_LIST]: ({productDetailsCompareList}) => ({
    ...state,
    productDetailsCompareList,
  }),
  [SET_AGENT_INFO]: ({insurancePolicyId, agentInfo}) => ({
    ...state,
    saleRep: {...state.saleRep, [insurancePolicyId]: agentInfo},
  }),
  [SET_LEAD_PERSONAL_INFO]: ({leadPersonalInfo}) => ({
    ...state,
    leadPersonalInfo: leadPersonalInfo,
  }),
  [CLEAR_LEAD_PERSONAL_INFO]: () => ({
    ...state,
    leadPersonalInfo: {},
  }),
}))
