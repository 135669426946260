import React from 'react'
import styled, {css} from 'react-emotion'
import {Button as AntButton} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {responsive} from '../../core/style'

const CustomButton = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content !important;
  padding: 0 25px;
  overflow: hidden;
  border-radius: 4px;
  border: ${props =>
    props.type === 'border'
      ? `${'#66b8fd'} 1px solid`
      : `${'white'} 1px solid`};
  color: ${props => (props.type === 'border' ? '#66b8fd' : 'white')};
  background-color: ${props => (props.type === 'border' ? 'white' : '#66b8fd')};

  &[disabled] {
    border: ${props =>
      props.type === 'border' ? `${'white'} 1px solid` : 'unset'};
    opacity: 0.5;
    cursor: not-allowed;
    :hover {
      background-color: ${props => 'white'};
      opacity: 0.5;
    }
  }

  :hover {
    color: ${props => (props.type === 'border' ? '#66b8fd' : 'white')};
    background-color: ${props =>
      props.type === 'border' ? 'white' : '#66b8fd'};
    border: ${props =>
      props.type === 'border'
        ? `${'#66b8fd'} 1px solid`
        : `${'white'} 1px solid`};
    .icon {
      width: 12px !important;
      margin-left: 10px;

      opacity: 1;
    }
  }
  :focus {
    color: ${props => (props.type === 'border' ? '#66b8fd' : 'white')};
    background-color: ${props =>
      props.type === 'border' ? 'white' : '#66b8fd'};
    border: ${props =>
      props.type === 'border'
        ? `${'#66b8fd'} 1px solid`
        : `${'white'} 1px solid`};
  }
`

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const ContainerErrorMessage = styled.div`
  color: ${props => props.theme.PINKRED};
  font-size: 12px;
  margin-top: 15px;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => props.theme.PINKRED};
  margin-right: 5px;
`

const ButtonIcon = styled(FontAwesomeIcon)`
  width: 0 !important;

  margin-left: 0;
  font-size: 12px;
  color: '#66b8fd';

  opacity: 0;
  transition: all 0.5s;

  ${props =>
    responsive.mobile(css`
      width: 12px !important;
      margin-left: 10px;
      transition: unset;
      opacity: 1;
    `)};

  ${props =>
    responsive.tablet(css`
      width: 12px !important;
      margin-left: 10px;
      transition: unset;
      opacity: 1;
    `)};
`

const Button = props => (
  <ContainerButton>
    {props.errorMessage && (
      <ContainerErrorMessage>
        <ExclamationCircle icon={['fas', 'exclamation-circle']} />
        {props.errorMessage}
      </ContainerErrorMessage>
    )}
    <CustomButton {...props}>
      {props.children}
      {props.icons && <ButtonIcon className="icon" icon={props.icons} />}
    </CustomButton>
  </ContainerButton>
)

export default Button
