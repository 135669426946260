import React from 'react'
import styled from 'react-emotion'
import {compose, withProps} from 'recompose'

export const HolddingTagContainer = styled.div`
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  right: 3px;
  top: -31px;
  /* width: 170px; */
  height: 30px;
  border-radius: 5px 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFB933')};
  padding: 6px 20px;
  color: #333333;
`

// {target === 'holding' ? (
//   <HolddingTagContainer target="#e5e5e5">
//     แบบประกันที่คุณถืออยู่
//   </HolddingTagContainer>
// ) : target === 'interest' ? (
//   <HolddingTagContainer target="#9FD134">
//     แบบประกันที่คุณสนใจ
//   </HolddingTagContainer>
// ) : target === 'suggest' ? (
//   <HolddingTagContainer target="#FFB933">
//     แบบประกันแนะนำ
//   </HolddingTagContainer>
// ) : (
//   ''
// )}

const TagContaniner = props => (
  <HolddingTagContainer clasaName={props.clasaName} bgColor={props.bgColor}>
    {props.caption}
  </HolddingTagContainer>
)

export default TagContaniner
