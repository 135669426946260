import React, {useEffect} from 'react'

const reset = () => {
  document.title =
    'noon Insure With Accuracy - ค้นหาแบบประกันภัยที่ใช่ ตามไลฟ์สไตล์คุณ'
  const meta = document.getElementsByTagName('meta')
  Object.values(meta).forEach(tag => {
    if (tag.name === 'description') {
      tag.content =
        'noon เครื่องมือในการวางแผนและเลือกซื้อแบบประกันภัยที่เหมาะสม ตอบโจทย์ทุกไลฟ์สไตล์ของคุณ คิดเรื่องประกันไม่ออกให้บอกนูน เพราะนูนรับประกันความเที่ยงตรง'
    }
    if (tag.name === 'keywords') {
      tag.content =
        'แบบประกันภัยครบวงจร,ซื้อประกันอะไรดี,ทุกเรื่องครบ จบเกี่ยวกับการประกันภัย'
    }
  })
}

const withSEO = (
  title = '',
  description = '',
  keywords = '',
) => Component => props => {
  // useEffect(() => {
  //   if (document) {
  //     document.title = title
  //     const meta = document.getElementsByTagName('meta')
  //     Object.values(meta).forEach(tag => {
  //       if (tag.name === 'description') {
  //         tag.content = description
  //       }
  //       if (tag.name === 'keywords') {
  //         tag.content = keywords
  //       }
  //     })
  //   }
  //   return reset
  // }, [])
  return <Component {...props} />
}

export default withSEO
