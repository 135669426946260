import React from 'react'
import {Modal} from 'antd'
import {responsive, breakpoints} from '../../core/style'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled, {css} from 'react-emotion'

const CustomModal = styled(Modal)`
  /* position: relative; */

  .ant-modal-content {
    .ant-modal-header {
      background-color: #008afc;
      .ant-modal-title {
        text-align: center;
        color: white;
      }
    }
    .ant-modal-body {
      background: white;
      color: black;
      border-radius: 5px;
      padding: 0%;
    }
    .ant-modal-close {
      .ant-modal-close-x {
        /* display: none; */
        color: white;
      }
    }
  }

  ${props =>
    responsive.tablet(css`
      width: auto !important;
      .ant-modal-content {
        margin: 0 10px;
      }
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 20px;
  top: -13px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(127, 127, 127, 0.5);
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;
  z-index: 99;
`

const ModalShare = ({
  onClose = () => {},
  isShowModal,
  setIsShowModal,
  children,
  className,
  width = '500px',
  title,
}) => (
  <CustomModal
    className={className}
    visible={isShowModal}
    footer={null}
    width={width}
    onCancel={() => {
      setIsShowModal(false)
      onClose()
    }}
    title={title}>
    {/* <CustomIcon
      onClick={() => {
        setIsShowModal(false)
        onClose()
      }}
      icon={['fas', 'times-circle']}
    /> */}
    {children}
  </CustomModal>
)

export default ModalShare
