import React from 'react'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {reduxForm, formValueSelector} from 'redux-form'
import windowSize from '../../../../core/react-window-size'
import withSEO from '../../../share/withSEO'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '../../../share/Button'
import {compose, lifecycle} from 'recompose'
import {responsive, breakpoints} from '../../../../core/style'
import Field from '../../../share/Field'
import {
  displayNumber,
  numberParser,
  obj2Url,
  url2Obj,
} from '../../../../core/helper'
import PersonalForm from '../../../share/PersonalForm'
import Tooltip from '../../../share/Tooltip'
import {HSOptions, CIOptions} from '../../options'
import {getDiseasesOption} from '../../../../ducks/options'
import {savePlanning} from '../../../../ducks/product'

const Container = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const ContainerBox = styled.div`
  display: flex;
  width: 395px;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

const BgIcon = styled(FontAwesomeIcon)`
  position: absolute;
  color: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  right: -35px;
  top: 20px;
  font-size: 100px;
`

const CustomIconInfo = styled(FontAwesomeIcon)`
  position: absolute;
  top: ${props => (props.type === 'ci' ? '34px' : '5px')};
  right: ${props => (props.type === 'ci' ? '41px' : '65px')};
  font-size: 20px;
  margin-left: 17px;
  cursor: pointer;
  color: #7f7f7f;
  border-right-color: #7f7f7f;
  ${props =>
    responsive.mobile(css`
      right: 5px;
      top: ${props.type === 'ci' ? '29px' : '0'};
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;
`
const LabelRadio = styled.div`
  height: ${props => (props.type === 'ci' ? '100px' : '62px')};
  width: ${props => (props.type === 'ci' ? '130px' : '240px')};
  font-size: 16px;
  padding-top: 5px;
  padding-left: ${props => (props.type === 'ci' ? '3px' : '12px')};
  ${props =>
    responsive.mobile(css`
      height: ${props.type === 'ci' ? '30px' : '62px'};
      padding: 0;
    `)};
`
const ContainerRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${props => (props.type === 'ci' ? '100px' : '62px')};
  margin-left: ${props => (props.type === 'ci' ? '30px' : '150px')};
  .ci {
    height: 100px;
    margin-right: -10px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 280px;
      height: ${props.type === 'ci' ? 'auto' : '108px'};
      margin-left: 0;
      align-items: unset;
      justify-content: unset;
      margin-bottom: ${props.type === 'ci' ? '0' : '20px'};
    `)};
`

const ContainerSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 33px;
  ${props =>
    responsive.mobile(css`
      margin-right: 0;
    `)};
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  ${props =>
    responsive.mobile(css`
      width: 280px;
    `)};
`
const Selector = styled(Field)`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 25px;
  margin-right: 82px;
  width: unset;
  .Select-control {
    text-align: center;
  }
  .input-selector-container {
    width: 180px;
    height: 40px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin: 0;
      margin-top: 30px;
      .input-selector-container {
        width: 280px;
      }
      span {
        margin-bottom: 10px;
        margin-right: 0;
        width: 100%;
        justify-content: flex-start;
      }
    `)};
`
const Radios = styled(Field)`
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 62px;
  margin-right: 13px;
  margin-left: 25px;

  .input-radios-container {
    height: 100%;
  }
  .ant-radio-group {
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    flex-wrap: unset;
    height: 100%;
    justify-content: space-between;
    label {
      width: 100%;
      margin-right: 0;
      margin: 5px 0;
      white-space: normal;
    }
    .ant-radio-wrapper {
    }
  }

  ${props =>
    responsive.mobile(css`
      margin-top: 10px;
      height: 130px;
      .ant-radio-group {
        .ant-radio-wrapper {
          align-items: flex-start;
          .ant-radio {
            padding-top: 2px;
          }
        }
      }
    `)};
`

const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: unset;

  margin: 0;
  margin-left: 30px;
  .label {
    margin: 0;
    margin-right: 25px;
    min-width: 0;
  }
  .input-text-container {
    width: unset;
  }

  input {
    width: 180px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin: 0;
      .label {
        width: 100%;
        justify-content: flex-start;
        margin: 45px 0 15px;
        padding: 0 2px;
      }
      input {
        width: 280px;
        text-align: center;
      }
    `)};
`

const CustomContainerPersonalForm = styled(PersonalForm)`
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 10px;
  .property {
    margin-bottom: 35px;
  }
`

const Unit = styled.span`
  font-size: 16px;
  margin-left: 22px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const CustomButton = styled(Button)`
  width: 150px;
  height: 30px;
  font-size: 12px;
  margin-bottom: 55px;
  margin-top: 39px;

  ${props =>
    responsive.mobile(css`
      margin-top: 80px;
    `)};
`

export const HealthIntermediate = ({
  onSubmit,
  type,
  diseasesOptions,
  reset,
  windowWidth,
  formType,
}) => (
  <Container onSubmit={onSubmit}>
    <CustomContainerPersonalForm />
    {type === 'hs' ? (
      <ContainerRadio>
        <LabelRadio>ประเภทวงเงินคุ้มครอง</LabelRadio>
        <Radios name="hsGoal" type="radios" options={HSOptions} />
        <CustomTooltip
          placement="right"
          title={`คุ้มครองค่ารักษาพยาบาลที่เกิดขึ้นภายในโรงพยาบาล ทั้งแบบเหมาจ่ายและจ่ายตามจริง`}>
          <CustomIconInfo icon={['fas', 'info-circle']} />
        </CustomTooltip>
      </ContainerRadio>
    ) : (
      <React.Fragment>
        <ContainerRadio type={type}>
          <LabelRadio type={type}>ระยะคุ้มครอง</LabelRadio>
          <Radios
            className={type}
            name="ciRoles"
            type="radios"
            options={CIOptions}
          />
        </ContainerRadio>

        <ContainerSelector>
          <Selector
            label="โรคที่สนใจคุ้มครองเป็นพิเศษ"
            name="ciDiseases"
            type="selector"
            options={diseasesOptions}
            themeColor="orange"
            placeholder="เลือก"
          />
          <CustomTooltip
            placement="right"
            title={`เลือกกลุ่มโรคที่อยากให้มีความคุ้มครองครอบคลุมกลุ่มโรคนี้แน่นอน หรือไม่ระบุก็ได้`}>
            <CustomIconInfo type={type} icon={['fas', 'info-circle']} />
          </CustomTooltip>
        </ContainerSelector>
      </React.Fragment>
    )}
    {type === 'hs' ? (
      <FieldContainer>
        <TextInput
          name="searchValue"
          label={
            windowWidth < breakpoints.mobile
              ? `เป็นจำนวนเงิน (บาท)`
              : `เป็นจำนวนเงิน`
          }
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
    ) : (
      <FieldContainer>
        <TextInput
          name="searchValue"
          label={
            windowWidth < breakpoints.mobile
              ? `ความคุ้มครองค่ารักษาพยาบาล (บาท)`
              : `ความคุ้มครองค่ารักษาพยาบาล`
          }
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
        />
        <Unit>วงเงินคุ้มครอง (บาท)</Unit>
      </FieldContainer>
    )}
    <CustomButton htmlType="submit" icons={['fa', 'search']}>
      เลือกแบบประกัน
    </CustomButton>
  </Container>
)

const PlanningHealth = ({
  handleSubmit,
  type,
  diseasesOptions,
  reset,
  savePlanning,
  windowWidth,
  onSubmit,
  formType,
}) => (
  <HealthIntermediate
    formType={formType}
    type={type}
    diseasesOptions={diseasesOptions}
    reset={reset}
    windowWidth={windowWidth}
    onSubmit={
      onSubmit ||
      handleSubmit(value => {
        savePlanning(
          {
            gender: value.gender,
            age: value.age,
            value: value.searchValue,
            sub_type: value.insuranceType,
            coverage_type: value.hsGoal,
            protection_period: value.ciRoles,
            disease: value.ciDiseases,
          },
          'intermediate',
          'health',
        )
        window.open(
          `/smart/health/intermediate/product?${obj2Url({
            ...value,
            sortBy: 'min_premium',
          })}`,
          '_self',
        )
      })
    }
  />
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  withSEO(
    'noon Smart Health',
    'noon Smart-Health ค้นหา ศึกษาข้อมูล เลือกซื้อประกันสุขภาพ ทั้งแบบคุ้มครองโรคร้ายแรง และแบบเน้นค่ารักษาพยาบาล ด้วยระบบที่พัฒนาตอบโจทย์ ความต้องการของผู้บริโภคในยุคดิจิตัล',
    'เครื่องมือค้นหาประกันสุขภาพ,เลือกซื้อประกันสุขภาพ,ค่าห้อง,ค่ารักษาพยาบาล,โรคร้ายแรง,มะเร็ง,ไต,เบาหวาน,ความดัน,โรงพยาบาล,ประกันสังคม,รักษาพยาบาล,สุขภาพ,ป่วย,หัวใจ,ผู้สูงอายุ',
  ),
  connect(
    (state, props) => ({
      initialValues: {
        gender: url2Obj(props.location.search).gender,
        age: url2Obj(props.location.search).age,
        hsGoal: url2Obj(props.location.search).hsGoal || 'sum_insured',
        ciRoles: 'middle',
        insuranceType:
          url2Obj(props.location.search).insuranceType || props.formType,
        ciDiseases:
          url2Obj(props.location.search).ciDiseases &&
          url2Obj(props.location.search).ciDiseases.toString(),
      },
      gender: selector(state, 'gender'),
      age: selector(state, 'age'),
      type: selector(state, 'insuranceType'),
      diseasesOptions: state.options.diseasesOptions,
    }),
    {getDiseasesOption, savePlanning},
  ),
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getDiseasesOption()
    },
  }),
)

export default windowSize(enhancer(PlanningHealth))
