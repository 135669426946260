import React from 'react'
import styled from 'react-emotion'
import {Router} from '@reach/router'
import Landing from './Landing'
import NoonSmart from './NoonSmart'
import TaxCalculator from './NoonTax/TaxCalculator'
import TaxPro from './NoonTax/TaxPro'
import SubCategory from './NoonSmart/SubCategory'
import Product from './NoonSmart/Product/index'
import ProductDetail from './NoonSmart/Product/ProductDetail/index'
import Compare from './NoonSmart/Compare'
import Auth from './Auth'
import NotFound from './404'
import Consent from './Consent'
import Profile from './Profile'
import InsuranceCategory from './NoonSmart/InsuranceCategory'
import MotorIntermediate from './NoonSmart/Motor/Intermediate/index'
import HealthIntermediate from './NoonSmart/Health/Intermediate/index'
import LifeIntermediate from './NoonSmart/Life/Intermediate/index'

const CustomRouter = styled(Router)`
  width: 100%;
  min-height: calc(100vh - 214px);
  padding-top: 80px;
`

const Route = ({isOnTop, isFooterAppear}) => (
  <CustomRouter>
    <Consent path="/consent/CS-MK-011164-006" />
    <Auth path="/authorize/:category" />
    <Landing path="/" isOnTop={isOnTop} />
    <Profile path="/profile" />
    <SubCategory path="/smart/InsuranceCategory/:category/:level" />
    <LifeIntermediate path="/smart/InsuranceCategory/:category/:level/Lifeform/:formType/:weightType" />
    <Product
      path="/smart/:category/:level/product"
      isFooterAppear={isFooterAppear}
    />
    <ProductDetail path="/smart/:category/:level/product/:id" />
    <Compare path="/smart/:category/:level/product/compare" />
    <NotFound default />
  </CustomRouter>
)

export default Route
