import React from 'react'
import styled, { css } from 'react-emotion'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import FooterMenuList from './FooterMenuList'
import windowSize from '../../core/react-window-size'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { responsive } from '../../core/style'

const Container = styled.footer`
  display: ${props => (props.isNoFooter ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #333333;
  color: white;
  ${props =>
    responsive.tablet(css`
      padding: 0;
    `)};
`

const CopyrightPart = styled.div`
  margin-top: 35px;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  max-width: 915px;
  justify-content: space-between;
  align-items: center;

  ${props =>
    responsive.tablet(css`
      margin-top: 5px;
      padding: 0 25px;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;
      min-height: 159px;
      justify-content: center;
    `)};
`

const CopyrightContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .span {
    font-size: 20px;
    margin-right: 5px;
  }
  br {
    display: none;
  }
  @media(max-width: 560px) {
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;
    br {
      display: block;
    }
    .span {
      font-size: 26px;
      margin-right: 5px;
      display: flex;
      align-items: flex-end;
      height: 16px;
    }
    .text {
      text-align: center;
    }
  }
`

const IconPart = styled.div`
  display: flex;
  @media(max-width: 560px) {
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`
const WrapIcon = styled.div`
  background: #FFFFFF;
  height: 40px;
  overflow: hidden;
  min-width: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer; 
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #333333;
`
const WrapFooter = styled.div`
  height: 100px;
  background: #333333;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  font-size: 16px;
  width: 100%;
  @media(max-width: 560px) {
    height: 143px;
    flex-direction: column-reverse;
  }
`
const Footer = ({ windowWidth, isNoFooter }) => (
  <Container id="footer" isNoFooter={isNoFooter}>
    <WrapFooter>
      <CopyrightContent onClick={() => navigate('/policy')}>
        <div className="span">© </div><div className="text">2019 TQM Corporation Group.<br /> All rights reserved.</div>
      </CopyrightContent>
      <IconPart>
        <WrapIcon>
          <CustomIcon
            icon={['fab', 'facebook-f']}
            onClick={() =>
              window.open('https://www.facebook.com/Nooninsure/', '_blank')
            }
          />
        </WrapIcon>
        <WrapIcon>
          <CustomIcon
            icon={['fab', 'twitter']}
            onClick={() => window.open('https://twitter.com/nooninth', '_blank')}
          />
        </WrapIcon>
        <WrapIcon>
          <CustomIcon
            icon={['fab', 'youtube']}
            onClick={() =>
              window.open(
                'https://www.youtube.com/channel/UC27zHa7O3PaHOBOb34QHGwQ',
                '_blank',
              )
            }
          />
        </WrapIcon>
      </IconPart>
    </WrapFooter>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isNoFooter: state.notFound.isNoFooter,
    }),
    {},
  ),
)

export default windowSize(enhancer(Footer))
