import React from 'react'
import {compose, withProps, withHandlers} from 'recompose'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {clearLeadPersonalInfo} from '../../../ducks/product/index'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 83px 60px 30px 60px;
`
const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px #66b8fd solid;
  padding-bottom: 25px;
  padding-top: 20px;
`
const HeaderCaption = styled.span`
  font-size: 20px;
  color: #000;
  align-self: center;
`
const InfoCaption = styled.span`
  width: 70%;
  font-size: 16px;
  color: #666;
`
const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px #66b8fd solid;
  padding-bottom: 25px;
  padding-top: 20px;
`
const DataCaption = styled.span`
  margin-top: 16px;
  font-size: 20px;
  color: #000;
  align-self: center;
`
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`
const FooterCaption = styled.span`
  margin-top: 16px;
  font-size: 20px;
  color: #000;
  align-self: center;
`
const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  border-top: 1px solid #eeeef2;
  color: #666666;
  cursor: pointer;
`
const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const InfoValue = styled(InfoCaption)`
  width: 30%;
  color: #000;
`
const FooterInfoCaption = styled.span`
  font-size: 16px;
  color: #666;
`

const LeadResult = ({fullname, contactTime, email, phoneNumber, onClose}) => (
  <Layout>
    <Container>
      <HeaderCaption>ขอบคุณที่ใช้บริการของเรา</HeaderCaption>
      <CaptionContainer>
        <FieldContainer>
          <InfoCaption>รายละเอียดคำสั่งซื้อได้ถูกส่งไปที่</InfoCaption>
          <InfoValue>{email}</InfoValue>
        </FieldContainer>
      </CaptionContainer>
      <DataCaption>ข้อมูลของคุณ</DataCaption>
      <DataContainer>
        <FieldContainer>
          <InfoCaption>ชื่อ</InfoCaption>
          <InfoValue>{fullname}</InfoValue>
        </FieldContainer>
        <FieldContainer>
          <InfoCaption>เบอร์ติดต่อ</InfoCaption>
          <InfoValue>{phoneNumber}</InfoValue>
        </FieldContainer>
        <FieldContainer>
          <InfoCaption>ช่วงเวลาที่สะดวกให้ติดต่อกลับ</InfoCaption>
          <InfoValue>{contactTime}</InfoValue>
        </FieldContainer>
      </DataContainer>
      <FooterCaption>เจ้าหน้าที่จะติดต่อกลับในเวลาที่ระบุไว้</FooterCaption>
      <FooterContainer>
        <FooterInfoCaption>
          ดำเนินการโดย บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ จำกัด
        </FooterInfoCaption>
      </FooterContainer>
    </Container>
    <CloseButton onClick={onClose}>ปิด</CloseButton>
  </Layout>
)

const enhance = compose(
  connect(
    (state, props) => ({
      leadPersonalInfo: state.product.leadPersonalInfo,
    }),
    {clearLeadPersonalInfo},
  ),
  withProps(props => {
    const {fullname, contactTime, email, phoneNumber} =
      props.leadPersonalInfo || {}

    const {
      personalFirstName,
      personalLastName,
      personalPhoneNumber,
      personalEmail,
      personalContactTime,
    } = props.leadParams || {}

    return {
      fullname: fullname || `${personalFirstName} ${personalLastName}`,
      contactTime: contactTime || personalContactTime,
      email: email || personalEmail,
      phoneNumber: phoneNumber || personalPhoneNumber,
    }
  }),
  withHandlers({
    onClose: props => () => {
      props.clearLeadPersonalInfo && props.clearLeadPersonalInfo()
      props.setIsShowModal && props.setIsShowModal(false)
      props.setIsShowLeadPackageResultModal &&
        props.setIsShowLeadPackageResultModal(false)
    },
  }),
)

export default enhance(LeadResult)
