import React from 'react'
import styled from 'react-emotion'
import ReCAPTCHA from 'react-google-recaptcha'

const CustomReCaptcha = styled(ReCAPTCHA)``

const ReacaptCha = ({
  setRecaptchaToken = () => {},
  setData = () => {},
  sizes,
}) => (
  <CustomReCaptcha
    sitekey={process.env.RECAPTCHA_SITEKEY}
    onChange={e => {
      setRecaptchaToken(e)
      setData({errorMessage: ''})
    }}
    size={sizes}></CustomReCaptcha>
)

export default ReacaptCha
