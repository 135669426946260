import spidusStore from './spidusStore'
import {connect} from 'react-redux'
import {getFormValues} from 'redux-form'
import objectPath from 'object-path'

const withAnswers = (configName, mapAnswerToProps) =>
  connect(state =>
    Object.entries(mapAnswerToProps).reduce(
      (props, [name, valuePath]) => ({
        ...props,
        [name]: objectPath.get(
          getFormValues(spidusStore.STORE_NAME)(state),
          `answers.${configName}.${valuePath}`,
          undefined,
        ),
      }),
      {},
    ),
  )

export default withAnswers
