import React from 'react'
import styled, {css} from 'react-emotion'
import Modal from '../../share/Modal'
import windowSize from '../../../core/react-window-size'

const LANG = 'en'

const CustomModal = styled(Modal)`
  .ant-modal-body > svg:first-child {
    position: absolute;
    top: 13px;
  }
`
const Footer = styled.div`
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 120px;
    height: 48px;
    margin: 12px auto;
    border-radius: 4px;
    border: #fff 1px solid;
    color: #fff;
    background-color: #66b8fd;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 550px;
  overflow-y: scroll;
  border-radius: 4px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 0 auto;
  padding: 36px 36px;
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
  .Header {
    font-weight: bold;
    text-align: center;
  }
  .Header.Main {
    display: block;
  }
  .Header.Sub {
    display: none;
  }
  .Header.Minor {
    display: none;
  }
  .Topic {
    font-weight: bold;
  }
  .Detail {
  }
  .Consent {
    font-size: 16px;
  }
  .Tab {
    padding-right: 60px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
    .Header.Main {
      display: none;
    }
    .Header.Sub {
      display: block;
    }
    .Header.Minor {
      display: none;
    }
    .Consent {
      font-size: 14px;
    }
  }
  @media (max-width: 560px) {
    font-size: 16px;
    .Header.Main {
      display: none;
    }
    .Header.Sub {
      display: none;
    }
    .Header.Minor {
      display: block;
    }
    .Consent {
      font-size: 12px;
    }
  }
`

const ModalConsent = ({
  isShowModal,
  setIsShowModal,
  consent,
  confirmConsent,
}) => {
  return (
    <CustomModal
      isShowModal={isShowModal}
      setIsShowModal={setIsShowModal}
      width="990px">
      <Container>
        <p className="Header Main">
          รายละเอียดนโยบายของ บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด,
          บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์
          <br />
          โบรคเกอร์ จำกัด, บริษัท แคสแมท จำกัด, บริษัท ทีเจเอ็น อินชัวร์รันส์
          โบรกเกอร์ จำกัด และ บริษัท อีซี่ เลนดิ้ง จำกัด
          <br />
          เกี่ยวกับการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของลูกค้า
          <br />
          กรุณาอ่านและ/หรือทำความเข้าใจในเงื่อนไข และข้อตกลงในการใช้บริการ
          ก่อนกดปุ่ม “ยอมรับ”
        </p>
        <p className="Header Sub">
          รายละเอียดนโยบายของ บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด,
          <br />
          บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ จำกัด, บริษัท แคสแมท
          จำกัด,
          <br />
          บริษัท ทีเจเอ็น อินชัวร์รันส์ โบรกเกอร์ จำกัด และ บริษัท อีซี่ เลนดิ้ง จำกัด
          <br />
          เกี่ยวกับการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของลูกค้า
          <br />
          กรุณาอ่านและ/หรือทำความเข้าใจในเงื่อนไข และข้อตกลงในการใช้บริการ
          <br />
          ก่อนกดปุ่ม “ยอมรับ”
        </p>
        <p className="Header Minor">
          รายละเอียดนโยบายของ บริษัท ทีคิวเอ็ม อินชัวร์รันส์ <br />
          โบรคเกอร์ จำกัด บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์
          <br />
          โบรคเกอร์ จำกัด, บริษัท แคสแมท จำกัด,
          <br />
          บริษัท ทีเจเอ็น อินชัวร์รันส์ โบรกเกอร์ จำกัด,
          <br />
          และ บริษัท อีซี่ เลนดิ้ง จำกัด
          <br />
          เกี่ยวกับการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูล
          <br />
          ส่วนบุคคลของลูกค้า
          <br />
          กรุณาอ่านและ/หรือทำความเข้าใจในเงื่อนไข
          <br />
          และข้อตกลงในการใช้บริการ ก่อนกดปุ่ม “ยอมรับ”
        </p>
        <br />
        <p className="Topic" style={{alignSelf: 'flex-start'}}>
          การเคารพสิทธิในความเป็นส่วนตัวของท่าน
        </p>
        <div className="Detail">
          <span className="Tab" />
          บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด, บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์
          โบรคเกอร์ จำกัด, บริษัท แคสแมท จำกัด, บริษัท ทีเจเอ็น
          อินชัวร์รันส์ โบรกเกอร์ จำกัด และ บริษัท อีซี่ เลนดิ้ง จำกัด (ต่อไปในที่นี้เรียกรวมว่า "บริษัทฯ")
          มีความตระหนักดีว่าท่านมีความประสงค์ที่จะได้รับความปลอดภัยสูงสุดในสิทธิความเป็นส่วนตัวของท่าน
          บริษัทฯ จึงให้ความสำคัญ และ เคารพสิทธิในความเป็นส่วนตัวของท่าน
          ในการดำเนินการเก็บรวมรวม ใช้ ประมวลผลและเปิดเผยข้อมูลส่วนบุคคลที่สามารถระบุตัวตนของท่านได้ 
          อาจรวมถึงชื่อ สัญชาติ ที่อยู่ ข้อมูลของลักษณะที่อยู่อาศัย หมายเลขโทรศัพท์ หมายเลขโทรสาร
          รายละเอียดบัญชีธนาคาร รายละเอียดบัตรเครดิต ความสนใจส่วนบุคคล ที่อยู่
          E-mail Address ภาพถ่ายของท่าน หมายเลขประจำตัวที่ราชการออกให้แก่ท่าน
          ข้อมูลชีวภาพ เชื้อชาติ วันเกิด สถานะการสมรส ข้อมูลของสมาชิกในครอบครัว
          ศาสนา ข้อมูลสุขภาพ ข้อมูลยานพาหนะ และการประกันภัย
          โดยบริษัทฯ จะดำเนินการโดยใช้มาตรการที่เข้มงวดในการรักษาความปลอดภัยตามวัตถุประสงค์ที่ได้แจ้งให้ท่านทราบ 
          และ/หรือที่ท่านได้ให้ความยินยอมไว้เท่านั้น
          ตลอดจนป้องกันมิให้มีการนำข้อมูลส่วนบุคคลของท่านไปใช้โดยมิได้รับการอนุญาตจากท่านก่อน
        </div>
        <br />
        <p className="Topic" style={{alignSelf: 'flex-start'}}>
          การเก็บรวบรวมข้อมูลส่วนบุคคล
        </p>
        <div style={{paddingLeft: '60px'}}>
          <div className="Topic">
            วัตถุประสงค์ และ
            การให้ข้อมูลส่วนบุคคลเพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือมีความจำเป็นต้องให้ข้อมูลส่วนบุคคลเพื่อเข้าทำสัญญา
          </div>
          <div className="Detail">
            <span className="Tab" />
            บริษัทฯ มีวัตถุประสงค์ขอเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อนำเสนอข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการ
            รวมทั้งเพื่อการปรับปรุง พัฒนา และเพื่อคงไว้ซึ่งคุณภาพของผลิตภัณฑ์และบริการ 
            ที่เจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญากับบริษัท และเพื่อการติดต่อสื่อสาร เช่น การส่งบริการแจ้งเตือน
            การตอบกลับเมื่อได้รับการติดต่อจากเจ้าของข้อมูล สำหรับข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน
            เช่น ชื่อ นามสกุล ที่อยู่ เบอร์โทรศัพท์
            วัน เดือน ปีเกิด หมายเลขบัตรประจำตัวประชาชน 
            สถานะการสมรส เป็นต้น
            เพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือมีความจำเป็นต้องให้ข้อมูลส่วนบุคคลเพื่อการเข้าทำสัญญาตามความจำเป็นเท่านั้น
            <br />
            <span className="Tab" />
            บริษัทฯ
            จะดำเนินการใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบุไว้อย่างระมัดระวัง
            และใช้มาตรการกำกับดูแลการปฏิบัติตามเงื่อนไขและข้อกำหนด นโยบาย
            และจรรยาบรรณของบริษัทฯ ในการดำเนินการปฏิบัติ ตรวจสอบ ป้องกัน
            และดำเนินคดีตามกฎหมาย
            รวมถึงการใช้ข้อมูลส่วนบุคคลเพื่อแก้ปัญหาด้านการสนับสนุนลูกค้า
            <br />
            <span className="Tab" />
            สำหรับวัตถุประสงค์ทางกฎหมาย บริษัทฯ อาจใช้ข้อมูลส่วนบุคคลที่บริษัทฯ
            เก็บรวบรวมเพื่อสอบสวนและยุติคำร้องเรียน หรือระงับข้อพิพาท
            หรือตามที่ได้รับอนุญาตหรือต้องกระทำตามกฎหมายที่บังคับใช้ บริษัทฯ
            อาจใช้ข้อมูลส่วนบุคคลของท่านเมื่อบริษัทฯ ต้องได้รับคำปรึกษา
            ได้รับคำแนะนำ หรือถูกร้องขอให้กระทำเช่นนั้น โดยที่ปรึกษาทางกฎหมาย
            หรือโดยหน่วยงานทางกฎหมาย รัฐบาล
            หรือหน่วยงานราชการอื่นทั้งในประเทศและต่างประเทศ
          </div>
          <br />
          <div className="Topic">ข้อมูลที่ขอจัดเก็บ</div>
          <div className="Detail">
            <span className="Tab" />
            บริษัทฯ จะดำเนินการจัดเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลโดยมี วัตถุประสงค์ 
            ขอบเขต และใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรม 
            โดยในการจัดเก็บรวบรวมนั้นจะทำเพียงเท่าที่จำเป็นแก่การดำเนินงานภายใต้วัตถุประสงค์ของบริษัทเท่านั้น
            ทั้งนี้ บริษัทจะดำเนินการแจ้งให้เจ้าของข้อมูลทราบรายละเอียด หรือให้ความยินยอมก่อน
            ในเว็บไซต์การขายประกันภัยประเภทต่าง ๆ (แล้วแต่กรณี) ตามแบบวิธีการของบริษัท
            และตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 หรือกฎหมายอื่นกำหนดไว้
          </div>
          <br />
          <div className="Topic">
            ระยะเวลาในการเก็บ รวบรวม ใช้ และ เปิดเผยข้อมูล
          </div>
          <div className="Detail">
            <span className="Tab" />
            เป็นเวลา 10 ปี นับจากวันที่ได้รับความยินยอม หรือได้มีการเก็บรวบรวมข้อมูลโดยชอบด้วยกฎหมาย
            หลังจากนั้นบริษัทจะทำลายข้อมูลดังกล่าวตามวิธีการที่ได้มาตรฐาน เว้นแต่เป็นกรณีที่บริษัทฯ
            มีหน้าที่ตามกฎหมายในการเก็บรักษาข้อมูลนั้น ๆ ไว้ต่อไป หรือเพื่อเป็นการคุ้มครองสิทธิของบริษัทฯ
            ตามระยะเวลาที่กฎหมายกำหนด
          </div>
          <br />
        </div>
        <p className="Topic" style={{alignSelf: 'flex-start'}}>
          ความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านที่เก็บรวบรวมไว้ให้แก่บุคคลหรือหน่วยงานอื่น
        </p>
        <div style={{paddingLeft: '60px'}}>
          <div className="Detail">
            <span className="Tab" />
            ภายใต้วัตถุประสงค์ที่ได้แจ้งให้ท่านทราบ หรือที่ท่านได้ให้ความยินยอมแก่บริษัทฯ แล้ว บริษัทฯ
            อาจมีความจำเป็นต้องดำเนินการเปิดเผยข้อมูลส่วนบุคคลของท่านที่เก็บรวบรวมไว้ให้แก่บุคคลหรือหน่วยงานอื่นเฉพาะกลุ่มบริษัทในเครือ
            บริษัทลูก บริษัทที่เกี่ยวข้องกัน และ
            บริษัทที่อยู่ภายใต้การควบคุมเดียวกัน รวมถึงองค์กร หน่วยงานของรัฐ หรือบุคคลภายนอก
            ซึ่งรวมถึงตัวแทน ผู้ค้า ผู้จัดหา พาร์ทเนอร์ ผู้รับจ้าง ผู้ให้บริการภายนอก
            <br />
            <span className="Tab" />
            ทั้งนี้ บริษัทฯ จะดำเนินการตามขั้นตอนที่เหมาะสมเพื่อปกป้องและป้องกันมิให้เกิดการสูญหาย 
            การเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต การใช้ การดัดแแปลง หรือการเปิดเผยที่ไม่ชอบด้วยกฎหมาย โดยบุคคลที่บริษัทฯ
            เปิดเผยข้อมูลส่วนบุคคลให้ทราบดังกล่าวข้างต้น
            <br />
            <span className="Tab" />
            อนึ่ง ในกรณีที่บริษัทฯ ต้องเปิดเผยข้อมูลส่วนบุคคลต่อผู้รับข้อมูลในต่างประเทศ บริษัทฯ 
            จะดำเนินการเปิดเผยข้อมูลส่วนบุคคลเฉพาะในกรณีที่ท่านได้ให้ความยินยอม 
            หรือที่กฎหมายคุ้มครองข้อมูลส่วนบุคคล และกฎหมายอื่นที่เกี่ยวข้องกำหนดให้ทำได้เท่านั้น
          </div>
          <br />
          <div className="Topic">สิทธิที่เกี่ยวกับข้อมูลของท่าน</div>
          <div className="Detail">
            <span className="Tab" />
            ท่านสามารถใช้สิทธิที่เกี่ยวกับข้อมูลของท่านโดยใช้ความเป็นของเจ้าของข้อมูลส่วนบุคคลของท่านตามที่บริษัทฯ
            แจ้งท่านไว้ในหนังสือฉบับนี้
          </div>
          <ol
            className="Detail"
            style={{paddingLeft: '80px', marginBottom: '0'}}>
            <li>
              สิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ได้ให้ความยินยอมไว้
              และสิทธิในการได้รับแจ้งในกรณีที่บริษัทฯ ทำการเก็บรวบรวมข้อมูลส่วนบุคคล
            </li>
            <li>
              สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลของท่าน 
              รวมถึงการขอให้เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ไม่ได้ให้ความยินยอม
            </li>
            <li>
              สิทธิในการขอให้บริษัทฯ ส่งข้อมูลของท่านไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น
            </li>
            <li>
              สิทธิในการขอคัดค้าน การเก็บ รวบรวม ใช้ หรือเปิดเผยข้อมูลใด ๆ ที่เคยอนุญาต
              ไว้ก่อนหน้านั้นแล้ว เจ้าของข้อมูลมีสิทธิขอคัดค้านภายหลังได้
            </li>
            <li>
              สิทธิขอให้บริษัทฯ ดำเนินการลบหรือทำลายข้อมูลของท่าน เว้นแต่กรณีที่บริษัทฯ 
              ต้องปฏิบัติตามกฎหมายที่เกี่ยวข้องในการเก็บรักษาข้อมูลดังกล่าว
            </li>
            <li>
              สิทธิขอให้บริษัทฯ ดำเนินการแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์ 
              และทำให้ข้อมูลเป็นปัจจุบัน
            </li>
            <li>
              สิทธิการขอระงับใช้ข้อมูล เพื่อไม่ให้บริษัทฯ
              นำข้อมูลของท่านไปประมวลผล เว้นแต่กรณีที่บริษัทฯ
              มีสิทธิประมวลผลข้อมูลของท่านต่อไปเพื่อปฏิบัติหน้าที่ตามสัญญาระหว่างบริษัทและท่าน
              หรือโดยอาศัยสิทธิอันชอบด้วยกฎหมายอื่น
            </li>
          </ol>
          <br />
          <div className="Topic">สิทธิเพื่อการถอนความยินยอม</div>
          <div className="Detail">
            <span className="Tab" />
            ท่านสามารถใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลในการถอนความยินยอมเมื่อใดก็ได้
            โดยท่านสามารถแจ้งมายังบริษัทฯ ตามสถานที่และวิธีการติดต่อที่บริษัทฯ
            แจ้งให้ท่านทราบไว้ในหนังสือฉบับนี้
          </div>
          <br />
          <div className="Topic">สถานที่ติดต่อ และวิธีการติดต่อ</div>
          <div className="Detail">
            <span className="Tab" />
            ในกรณีที่ท่านต้องการความช่วยเหลือจากบริษัทฯ
            ไม่ว่าในกรณีเกี่ยวกับข้อมูลเพิ่มเติม ความผิดพลาด ข้อสงสัยใด ๆ
            ที่เกิดขึ้นโดยไม่ได้รับอนุญาตจากท่าน ท่านสามารถติดต่อบริษัทฯ ได้ดังนี้&nbsp;
            <a href="https://www.tqm.co.th/" target="_blank">
              https://www.tqm.co.th/
            </a>
            &nbsp;หรือทางอีเมล
            <a
              href="mailto:dpo@tqm.co.th"
              style={{textDecoration: 'none', color: 'black'}}>
              : dpo@tqm.co.th
            </a>
          </div>
        </div>
        <br />
        <p className="Topic" style={{alignSelf: 'flex-start'}}>
          การใช้ข้อมูลส่วนบุคคล
        </p>
        <div className="Detail">
          <span className="Tab" />
          บริษัทฯ
          จะใช้ข้อมูลของท่านในการประกอบธุรกิจของบริษัทฯ ในด้านการส่งเสริมการขาย
          หรือการประชาสัมพันธ์ผลิตภัณฑ์ การบริการต่าง ๆ รวมทั้งสิทธิประโยชน์
          ข้อเสนอพิเศษต่าง ๆ ของผลิตภัณฑ์หรือบริการต่าง ๆ โดยเป็นไปเพื่อประโยชน์ของท่าน
        </div>
        <br />
        <p className="Topic" style={{alignSelf: 'flex-start'}}>
          การขอความยินยอมเพื่อดำเนินการเก็บรวมรวม ใช้
          ประมวลผลและเปิดเผยข้อมูลส่วนบุคคลของท่าน
        </p>
        <div className="Detail">
          <span className="Tab" />
          การขอความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
          บริษัทฯ
          ขอทราบคุณสมบัติของผู้ให้ข้อมูลเพื่อให้มั่นใจว่าท่านสามารถให้ข้อมูลส่วนบุคคลได้ตามที่กฎหมายกำหนด
          <br />
          <span className="Tab" />
          ข้าพเจ้าเป็นเจ้าของข้อมูลส่วนบุคคลสามารถให้ข้อมูลของตนได้โดยมิต้องขอความยินยอมจากบุคคลใด
          และมิได้เป็นผู้เยาว์ที่มีอายุไม่เกินสิบปี 
          หรือเป็นคนไร้ความสามารถ หรือเป็นคนเสมือนไร้ความสามารถ
          <br />
          <span className="Tab" />
          ในกรณีถ้าเจ้าของข้อมูลส่วนบุคคลเป็นผู้เยาว์มีอายุไม่เกินสิบปี
          บริษัทฯ ได้ขอความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์ หรือเป็นคนไร้ความสามารถ
          บริษัทฯ ได้ขอความยินยอมจากผู้อนุบาลที่มีอำนาจกระทำการแทนคนไร้ความสามารถ
          หรือเป็นคนเสมือนไร้ความสามารถ บริษัทฯ 
          ได้ขอความยินยอมจากผู้พิทักษ์ที่มีอำนาจกระทำการแทนคนเสมือนไร้ความสามารถแล้ว
        </div>
        <br />
        <div className="Consent" style={{width: '100%', textAlign: 'left'}}>
          CS-MK-011164-006
        </div>
        <br />
        <p className="Topic" style={{alignSelf: 'flex-start'}}>
          นโยบายความเป็นส่วนตัว
        </p>
        <div>
          <span className="Tab" style={{paddingRight: '30px'}} />
          <input
            type="checkbox"
            checked={consent && 'checked'}
            style={{marginRight: '16px', cursor: 'pointer'}}
            onClick={() => confirmConsent()}
          />
          <span
            className="Detail"
            style={{color: '#66b8fd', verticalAlign: 'top', cursor: 'pointer'}}
            onClick={() => confirmConsent()}>
            ข้าพเจ้าผู้สมัคร/ผู้ใช้บริการได้อ่าน
            และเข้าใจข้อกำหนดและเงื่อนไขดังกล่าวข้างต้นโดยตลอดแล้ว
            และขอตกลงยินยอมให้บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด
            บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท แคสแมท จำกัด
            บริษัท ทีเจเอ็น อินชัวร์รันส์ โบรกเกอร์ จำกัด และ บริษัท อีซี่ เลนดิ้ง จำกัด 
            เก็บรวบรวม ใช้ 
            และ/หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
            ภายใต้ข้อกำหนดและเงื่อนไขดังกล่าวข้างต้น
          </span>
        </div>
      </Container>
      <Footer>
        <button onClick={() => setIsShowModal()}>ตกลง</button>
      </Footer>
    </CustomModal>
  )
}

export default windowSize(ModalConsent)
