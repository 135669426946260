import React from 'react'
import styled, {css} from 'react-emotion'
import {Switch as AntdSwitch} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CustomSwitch = styled(AntdSwitch)``

const GenderButtonContainer = styled.div`
  display: flex;
`

const GenderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  color: #66b8fd;
  border: 1px solid #66b8fd;
  /* background-color: #ffb933; */

  font-size: 16px;
  opacity: ${props => (props.isActive ? '1' : '0.5')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    opacity: ${props => (props.disabled ? 'none' : '1')};
  }

  ${props =>
    props.isActive
      ? css`
          background-color: #008afc;
          color: white;
          > svg {
            color: white;
          }
        `
      : ''}

  &.male {
    border-radius: 4px 0 0 4px;
    :hover {
      background-color: #008afc;
      color: white;
      > svg {
        color: white;
      }
    }
  }

  &.female {
    border-radius: 0px 4px 4px 0px;
    :hover {
      background-color: #008afc;
      color: white;
      > svg {
        color: white;
      }
    }
  }
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #66b8fd;
  cursor: pointer;
  margin-left: 12px;
`

const Switch = ({value, onChange, disabled, lang}) => (
  <GenderButtonContainer>
    <GenderButton
      disabled={disabled}
      isActive={value === 'male'}
      className="male"
      onClick={() => (disabled ? '' : onChange('male'))}>
      {lang === 'th' ? 'ชาย' : 'Male'}
      <CustomIcon icon={['fa', 'male']} />
    </GenderButton>
    <GenderButton
      disabled={disabled}
      isActive={value === 'female'}
      className="female"
      onClick={() => (disabled ? '' : onChange('female'))}>
      {lang === 'th' ? 'หญิง' : 'Female'}
      <CustomIcon icon={['fa', 'female']} />
    </GenderButton>
  </GenderButtonContainer>
)

export default Switch
