import {compose, withState, lifecycle} from 'recompose'

const withValue = () =>
  compose(
    withState('value', 'onChange', props => props.value),
    lifecycle({
      componentWillReceivedProps(nextProps) {
        if (props.value !== nextProps.value) {
          nextProps.onChange(nextProps.value)
        }
      },
    }),
  )

export default withValue
