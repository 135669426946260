import { change } from 'redux-form'
import objectPath from 'object-path'
import moment from 'moment'
import { httpPost } from '../../../../core/httpClient'
import stores from '../../../../ducks/index'
import humps from 'humps'

export const jsonPrettyPrint = json => {
  try {
    return JSON.stringify(JSON.parse(json), undefined, 4)
  } catch (e) {
    return json
  }
}
class SpidusStore {
  STORE_NAME = '@@SPIDUS@@'

  getSessionId() {
    const token = window.localStorage.getItem('Tqld-Auth-Token')
    const sessionId = token.substring(token.length - 50, token.length)
    return sessionId
  }

  getState(path, safeValue) {
    const state = stores.getState().form[this.STORE_NAME]
    return path ? objectPath.get(state, path, safeValue) : state
  }

  getValue(path, safeValue) {
    path = path ? `values.${path}` : `values`
    return objectPath.get(this.getState(), path, safeValue)
  }

  getRegistedNames() {
    return Object.keys(this.getState('registeredFields', {})).map(
      key => key.split('.')[2],
    )
  }

  change(name, value) {
    try {
      stores.dispatch(change(this.STORE_NAME, name, value))
    } catch (e) { }
  }

  changeAnswerValue(configName, answerName, value) {
    this.change(`answers.${configName}.${answerName}.value`, value)
  }

  async execute(formula = '') {
    try {
      if (!formula) {
        formula = ''
      }

      this.change('executioning', true)
      this.change('lastExecutionTime', null)
      this.change('lastExecutionFormula', formula)
      const sessionId = this.getSessionId()
      const t0 = performance.now()
      const response = await httpPost('spidus/execute', { sessionId, formula })
      const t1 = performance.now()
      const result = response.data.data.result
      this.change('lastExecutionResult', jsonPrettyPrint(result))
      this.change('lastExecutionTime', t1 - t0)
      this.change('executioning', false)
      return result
    } catch (e) {
      this.change('executioning', false)
      throw e
    }
  }

  async objectExecute(formula, safeValue) {
    let result = 'Internal server error.'
    try {
      this.change('lastExecutionError', false)
      result = await this.execute(formula)
      return JSON.parse(result)
    } catch (e) {
      this.change('lastExecutionError', result)
      if (safeValue !== undefined) {
        return safeValue
      } else {
        throw e
      }
    }
  }

  formula(name, ...params) {
    return `[@${name}(${params
      .map(param => {
        if (param === null || param === undefined) {
          return ''
        } else {
          switch (typeof param) {
            case 'string':
              return JSON.stringify(param)
            default:
              return JSON.stringify(JSON.stringify(param))
          }
        }
      })
      .join(',')})]`
  }

  async answerConfig(configName, ...params) {
    return await this.objectExecute(
      this.formula('config_manager', configName, ...params),
      {},
    )
  }

  setAnswers(configName, answers) {
    Object.keys(answers).map(name => {
      const answer = answers[name]
      answer.value = this.answerToValue(answer.value, answer.type)
      answers[name] = humps.camelizeKeys(answer)
    })

    this.change(`answers.${configName}`, {
      ...this.getValue(`answers.${configName}`),
      ...answers,
    })
  }

  valueToAnswer(value, type) {
    switch (type) {
      case 'date':
        if (value) {
          return moment(value).format('DD/MM/YYYY')
        } else {
          return value
        }
      case 'hash':
        return humps.decamelizeKeys(value)
      default:
        return value
    }
  }

  answerToValue(answer, type) {
    switch (type) {
      case 'date':
        if (answer) {
          return moment(answer, 'DD/MM/YYYY').toDate()
        } else {
          return answer
        }
      case 'hash':
        return humps.camelizeKeys(answer)
      default:
        return answer
    }
  }

  setLoading(loading) {
    const answers = this.getValue('answers', {})
    Object.keys(answers).forEach(configName => {
      const configs = answers[configName]
      Object.keys(configs).forEach(answerName => {
        configs[answerName].loading = loading
      })
      this.setAnswers(configName, configs)
    })
  }

  async loadAnswers(configName, ...names) {
    this.setLoading(true)
    const answers = await this.answerConfig(configName, ...names)
    this.setAnswers(configName, answers)
    this.setLoading(false)
  }

  async updateAnswers(configName, ...values) {
    if (values[0] instanceof Object) {
      values = values[0]
    } else {
      const answers = this.getValue(`answers.${configName}`)
      values = values.reduce((memo, name) => {
        const answer = answers[name]
        const value = this.valueToAnswer(answer.value, answer.type)
        return { ...memo, [name]: value }
      }, {})
    }

    this.setLoading(true)
    const answers = await this.answerConfig(configName, '<<update>>', values)
    this.setAnswers(configName, answers)
    this.setLoading(false)
  }

  async updateAnswer(configName, name, value) {
    this.updateAnswers(configName, { [name]: value })
  }
}

export default new SpidusStore()
