import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import styled from 'react-emotion'
import DetailTopLife from './Life/DetailTop'
import NoonInSightLife from './Life/NoonInSight'
import TableLife from './Life/TableLife'
import RecommendProduct from './RecommendProduct'

import DetailTopHealth from './Health/DetailTop'
import DetailBodyHealth from './Health/DetailBody'

import DetailTopMotor from './Motor/DetailTop'
import DetailBodyMotor from './Motor/DetailBody'

import DetailTopPackage from './Package/DetailTop'
import NoonInSightPackage from './Package/NoonInSight'
import TablePackage from './Package/TablePackage'

import { fetchProductDetail, getFullBenefit } from '../../../../ducks/product/'
import { url2Obj } from '../../../../core/helper'
import Loading from '../../../share/Loading'
import FooterDetail from './FooterDetail'
import RiderPackage from './Package/RiderPackage'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const TopicContainer = styled.div`
  background-color: rgba(255,185,51,0.2);
  width: 100%;
  height: 40px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 30px;
`
const TextTopic = styled.div`
  height: 100%;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
`
const RiderContainer = styled.div`
  min-height: 50px;
`
const NoContent = styled.div`
  display: flex;
  justify-content: center;
`
const ProductDetail = ({
  productLifeDetail,
  productHealthDetail,
  productMotorDetail,
  recommendProductsDetail,
  score,
  category,
  isLoading,
  fullBenefit,
  params,
  id,
  paramsEncode,
  productList,
  initializeProduct,
  productPackageDetail
}) => (
    <Loading isLoading={isLoading}>
      {initializeProduct && (
        <Container>
          {category === 'life' && (
            <React.Fragment>
              <DetailTopLife
                isLoading={isLoading}
                paramsEncode={url2Obj(params).paramsEncode}
                id={id}
                item={productLifeDetail}
                category={category}
                params={url2Obj(params)}
              />
              <TableLife
                fullBenefit={fullBenefit}
                insuranceType={productLifeDetail.insuranceType}
              />
              <NoonInSightLife item={productLifeDetail} type={category} />

              {recommendProductsDetail.length !== 0 && (
                <RecommendProduct
                  item={recommendProductsDetail}
                  params={url2Obj(params)}
                  category={category}
                />
              )}
            </React.Fragment>
          )}
          {category === 'health' && (
            <React.Fragment>
              <DetailTopHealth
                isLoading={isLoading}
                paramsEncode={url2Obj(params).paramsEncode}
                id={id}
                item={productHealthDetail}
                params={url2Obj(params)}
                category={category}
              />
              <DetailBodyHealth
                item={productHealthDetail}
                params={url2Obj(params)}
                category={category}
              />
              {recommendProductsDetail.length !== 0 && (
                <RecommendProduct
                  item={recommendProductsDetail}
                  params={url2Obj(params)}
                  category={category}
                />
              )}
            </React.Fragment>
          )}
          {category === 'motor' && (
            <React.Fragment>
              <DetailTopMotor
                isLoading={isLoading}
                paramsEncode={url2Obj(params).paramsEncode}
                id={id}
                item={productMotorDetail}
                params={url2Obj(params)}
                category={category}
              />
              <DetailBodyMotor item={productMotorDetail} category={category} />
              {recommendProductsDetail.length !== 0 && (
                <RecommendProduct
                  item={recommendProductsDetail}
                  params={url2Obj(params)}
                  category={category}
                />
              )}
            </React.Fragment>
          )}
          {category === 'package' && (
            <React.Fragment>
              <DetailTopPackage
                isLoading={isLoading}
                paramsEncode={url2Obj(params).paramsEncode}
                id={id}
                item={productPackageDetail.policy}
                category={category}
                params={url2Obj(params)}
                riderPolicies={productPackageDetail.riderPolicies}
              />
              <TablePackage
                fullBenefit={fullBenefit}
                insuranceType={productPackageDetail.insuranceType}
              />
              <NoonInSightPackage item={productPackageDetail} type={category} />
              <TopicContainer>
                <TextTopic>สัญญาเพิ่มเติม</TextTopic>
              </TopicContainer>
              <RiderContainer>
                {productPackageDetail.riderPolicies &&
                  productPackageDetail.riderPolicies.length > 0 ? (
                    productPackageDetail.riderPolicies.map(rider => {
                      return <RiderPackage item={rider} params={params} />
                    })
                  ) : (
                    <NoContent>ไม่มีสัญญาเพิ่มเติม</NoContent>
                  )}
              </RiderContainer>
              {/* {recommendProductsDetail.length !== 0 && (
                <RecommendProduct
                  item={recommendProductsDetail}
                  params={url2Obj(params)}
                  category={category}
                />
              )} */}
            </React.Fragment>
          )}
        </Container>
      )}
      <FooterDetail category={category} />
    </Loading>
  )

const enhancer = compose(
  connect(
    (state, props) => ({
      productList: state.product.productList,
      isLoading: state.product.isLoading,
      productLifeDetail: state.product.productLifeDetail,
      nearlyLifeDetail: state.product.nearlyLifeDetail,
      productHealthDetail: state.product.productHealthDetail,
      productMotorDetail: state.product.productMotorDetail,
      recommendProductsDetail: state.product.recommendProductsDetail || [],
      productPackageDetail: state.product.productPackageDetail,
      score: state.product.score,
      params: props.location.search,
      fullBenefit: state.product.fullBenefit,
      initializeProduct: state.product.initializeProduct,
    }),
    { fetchProductDetail },
  ),
  lifecycle({
    componentDidMount() {
      const {
        fetchProductDetail,
        // getFullBenefit,
        id,
        category,
        params,
      } = this.props

      fetchProductDetail(id, category, url2Obj(params))
      // if (category === 'life') getFullBenefit(id, url2Obj(params))
    },
  }),
)
export default enhancer(ProductDetail)
