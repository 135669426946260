import React from 'react'
import styled from 'react-emotion'

const Layout = styled.div`
  display: flex;
  width: 300px;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const Right = styled.div`
  flex: 1;
`

const Caption = styled.div`
  color: #333;
  font-size: 1em;
  line-height: 1.1em;
`

const Info = styled.div`
  color: #333;
  opacity: 0.9;
  font-size: 0.9em;
`

const withCaption = () => Component => ({
  className,
  noCaption,
  caption,
  info,
  ...restProps
}) => {
  const parentProps = {}
  const childrenProps = {}

  Object.entries(restProps).forEach(([key, value]) => {
    if (key.startsWith('data-')) {
      parentProps[key] = value
    } else {
      childrenProps[key] = value
    }
  })

  return (
    <Layout className={className} {...parentProps}>
      {!noCaption && (caption || info) && (
        <Left>
          {caption && <Caption>{caption}</Caption>}
          {info && <Info>{info}</Info>}
        </Left>
      )}
      <Right>{Component && <Component {...childrenProps} />}</Right>
    </Layout>
  )
}

export default withCaption
