import {takeLatest, put, call, all, select} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {removefilterObj} from '../../core/helper'
import {message} from 'antd'
import {navigate} from '@reach/router'
import {
  httpGet,
  httpPut,
  httpPost,
  httpDelete,
  API_HOST,
} from '../../core/httpClient'
import {setDataProduct, deleteProductCompareSaga} from '../product'
import {yellow} from 'ansi-colors'
import {getUserInfoSaga, setData as setAuthData} from '../auth'
import humps from 'humps'

const SET_LOADING = 'SET_LOADING_PROFILE'
const SET_DATA_PROFILE = 'SET_DATA_PROFILE'
const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
const EDIT_PROFILE = 'EDIT_PROFILE'
const GET_FAVORITE = 'GET_FAVORITE'
const GET_ARTICLES = 'GET_ARTICLES_PROFILE'
const GET_POLICIES = 'GET_POLICIES_PROFILE'
const GET_AVATAR = 'GET_AVATAR'
const SEND_LEAD_AGAIN = 'SEND_LEAD_AGAIN'
const GET_LEAD = 'GET_LEAD'
const GET_COMPARE_LIST = 'GET_COMPARE_LIST'
const GET_OPTIONS_PORT = 'GET_OPTIONS_PORT'
const GET_PORT_OPTIONS = 'GET_PORT_OPTIONS'
const GET_AGENT_DATA = 'GET_AGENT_DATA'
const SET_AGENT_DATA = 'SET_AGENT_DATA'
const SET_EVALUATE_AGENT = 'SET_EVALUATE_AGENT'
const GET_PORT = 'GET_PORT'
const SET_PORT = 'SET_PORT'
const GET_EVALUATE_DATA = 'GET_EVALUATE_DATA'
const SET_OPTIONS = 'SET_OPTIONS'
const SEND_EVALUATE_DATA = 'SEND_EVALUATE_DATA'
const DELETE_COMPARE = 'DELETE_COMPARE'
const SET_PORT_DETAIL_DATA = 'SET_PORT_DETAIL_DATA'
const GET_COVERAGE_TABLE = 'GET_COVERAGE_TABLE'
const GET_INSURANCE_SEARCH = 'GET_INSURANCE_SEARCH'
const GET_TOTAL_DETAIL = 'GET_TOTAL_DETAIL'
const DELETE_PORT = 'DELETE_PORT'
const UPDATE_PORT_DETAIL_DATA = 'UPDATE_PORT_DETAIL_DATA'

export const setLoading = Creator(SET_LOADING, 'isLoading')
export const setDataProfile = Creator(SET_DATA_PROFILE, 'data')
export const changePassword = Creator(CHANGE_PASSWORD, 'data', 'callback')
export const editProfile = Creator(EDIT_PROFILE, 'data', 'callback')
export const getFavorite = Creator(GET_FAVORITE)
export const getArticles = Creator(GET_ARTICLES)
export const getPolicies = Creator(GET_POLICIES)
export const getAvatar = Creator(GET_AVATAR)
export const sendLeadAgain = Creator(SEND_LEAD_AGAIN, 'data')
export const getLead = Creator(GET_LEAD)
export const getCompareList = Creator(GET_COMPARE_LIST)
export const getPortOptions = Creator(GET_PORT_OPTIONS)
export const getOptionsPort = Creator(GET_OPTIONS_PORT)
export const getAgentData = Creator(GET_AGENT_DATA, 'portId')
export const setAgentData = Creator(SET_AGENT_DATA, 'data')
export const setEvaluateAgent = Creator(SET_EVALUATE_AGENT, 'data')
export const getPort = Creator(GET_PORT)
export const setPort = Creator(SET_PORT, 'data')
export const getEvaluateData = Creator(GET_EVALUATE_DATA, 'portId')
export const setOptions = Creator(SET_OPTIONS, 'data')
export const sendEvaluateData = Creator(SEND_EVALUATE_DATA, 'data')
export const deleteCompare = Creator(DELETE_COMPARE, 'id', 'type')
export const setPortDetailData = Creator(SET_PORT_DETAIL_DATA, 'data', 'status')
export const getCoverageTable = Creator(
  GET_COVERAGE_TABLE,
  'id',
  'age',
  'gender',
  'sumInsured',
)

export const getInsuranceSearch = Creator(
  GET_INSURANCE_SEARCH,
  'insuranceSearchKeyWord',
)

export const getTotalDetail = Creator(GET_TOTAL_DETAIL)

export const deletePort = Creator(DELETE_PORT)

export const updatePortDetailData = Creator(UPDATE_PORT_DETAIL_DATA)

export function* reset() {
  yield put(setAuthData({isLogin: false, user: {}}))
  navigate('/')
  yield call(getUserInfoSaga)
}

export function* changePasswordSaga({payload: {data, callback}}) {
  yield put(setDataProfile({isLoading: true}))
  const url = `${API_HOST}auth/change_password`
  try {
    yield httpPut(url, data)
    yield put(setDataProfile({errorMessage: ''}))
    callback()

    message.success('เปลี่ยนรหัสผ่านสำเร็จแล้ว', 3)
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      case 422:
        yield put(setDataProfile({errorMessage: 'รหัสผ่านเดิมไม่ถูกต้อง'}))
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setDataProfile({isLoading: false}))
}

export function* editProfileSaga({payload: {data, callback = () => {}}}) {
  yield put(setDataProfile({isLoading: true}))

  if (!data.avatarName) {
    data = removefilterObj(data, ['avatarName', 'avatarImage'])
  }

  const url = `${API_HOST}users`
  try {
    yield httpPut(url, data)
    yield put(setDataProfile({errorMessage: ''}))
    yield call(getUserInfoSaga)
    message.success('บันทึกข้อมูลเรียบร้อยแล้ว', 3)
    callback()
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  } finally {
    yield put(setDataProfile({isLoading: false}))
  }
}

export function* getFavoriteSaga() {
  yield put(setLoading(true))
  try {
    yield all([call(getArticlesSaga), call(getPoliciesSaga)])
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  } finally {
    yield put(setLoading(false))
  }
}

export function* getArticlesSaga() {
  try {
    const url = `${API_HOST}favorites/articles`
    const response = yield httpGet(url)
    yield put(setDataProfile(response.data.data))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* getPoliciesSaga() {
  try {
    const url = `${API_HOST}favorites/insurance_policies`
    const response = yield httpGet(url)
    yield put(setDataProfile(response.data.data))
    yield put(
      setDataProduct({
        favoriteProduct: response.data.data.insurancePolicies.reduce(
          (mem, cur) => {
            mem[cur.id] = cur.favorite
            return mem
          },
          {},
        ),
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* getAvatarSaga() {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}users/avatars`
    const response = yield httpGet(url)
    yield put(setDataProfile({avartars: response.data.data.avatars}))
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  } finally {
    yield put(setLoading(false))
  }
}

export function* sendLeadAgainSaga({payload: {data}}) {
  yield put(setDataProfile({isLoading: true}))

  try {
    const url = `${API_HOST}/insurance_leads/${data.quotationId}/send_email_lead_again`
    yield httpPut(url, {
      personalInfoContactTime: data.personalInfoContactTime,
    })
    message.success('ส่งเรื่องให้ตัวแทนติดต่อกลับแล้ว', 5)
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setDataProfile({isLoading: false}))
}

export function* getLeadSaga() {
  yield put(setDataProfile({isLoading: true}))

  const urlLeadMotor = `${API_HOST}insurance_leads/auto`
  const urlLeadLife = `${API_HOST}insurance_leads/life`
  const urlLeadHealth = `${API_HOST}insurance_leads/health`
  try {
    const {motor, life, health} = yield all({
      motor: call(httpGet, urlLeadMotor),
      life: call(httpGet, urlLeadLife),
      health: call(httpGet, urlLeadHealth),
    })

    yield put(
      setDataProfile({
        leadMotor: motor.data.data.autoInsuranceQuotations,
        leadLife: life.data.data.lifeInsuranceQuotations,
        leadHealth: health.data.data.healthInsuranceQuotations,
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield call(reset)
        break
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setDataProfile({isLoading: false}))
}

export function* getCompareListSaga() {
  try {
    yield put(setDataProfile({isLoading: true}))
    const urlMotorCompareList = `${API_HOST}auto_insurance_policies/compare/list`
    const urlLifeCompareList = `${API_HOST}life_insurance_policies/compare/list`
    const {motorList, lifeList} = yield all({
      motorList: call(httpGet, urlMotorCompareList),
      lifeList: call(httpGet, urlLifeCompareList),
    })
    yield put(
      setDataProfile({
        compareMotor: motorList.data.data.compareList || [],
        compareLife: lifeList.data.data.compareList || [],
      }),
    )
  } catch (error) {
    console.error(error.response)
  } finally {
    yield put(setDataProfile({isLoading: false}))
  }
}

const obj2ArrOption = object =>
  Object.entries(object).map(obj => ({
    label: obj[1].label,
    value: obj[0],
  }))

const hospitals2Options = hospitals =>
  hospitals.map(hospital => ({value: hospital.id, label: hospital.name}))

export function* getPortOptionsSaga() {
  try {
    const url = `${API_HOST}user_answers/options`
    const response = yield httpGet(url)
    yield put(
      setDataProfile({
        portOptions: {
          hospitals: hospitals2Options(response.data.data.hospitals),
          investmentRisk: obj2ArrOption(response.data.data.investmentRisk),
        },
      }),
    )
  } catch (error) {
    console.error(error.response || error)
    if (error.response) {
      switch (error.response.status) {
        case 401:
          yield call(reset)
          break
        default:
          yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
          break
      }
    }
  }
}

export function* getOptionsPortSaga() {
  try {
    const url = `${API_HOST}ports/info/`
    const response = yield httpGet(url)
    const obj2arrInsuranceType = Object.entries(
      response.data.data.insuranceType,
    )
    const obj2arrPayPeriodType = Object.entries(
      response.data.data.payPeriodType,
    )
    const obj2arrPaymentType = Object.entries(response.data.data.paymentType)
    yield put(
      setDataProfile({
        policyType: [
          {label: 'life', value: 'life'},
          // {label: 'non-life', value: 'non-life'},
        ],

        insuranceCompany: response.data.data.insuranceCompanies.map(item => ({
          label: item.name,
          value: item.id,
        })),

        insuranceType: obj2arrInsuranceType.map((item, index) => ({
          label: obj2arrInsuranceType[index][1],
          value: humps.decamelize(obj2arrInsuranceType[index][0]),
        })),

        payPeriodType: obj2arrPayPeriodType.map((item, index) => ({
          label: obj2arrPayPeriodType[index][1],
          value: obj2arrPayPeriodType[index][0],
        })),

        paymentType: obj2arrPaymentType.map((item, index) => ({
          label: obj2arrPaymentType[index][1],
          value: obj2arrPaymentType[index][0],
        })),
      }),
    )
  } catch (error) {
    console.error(error.response)
  }
  yield put(setDataProfile({isLoading: false}))
}

export function* getAgentDataSaga(payload) {
  const url = `${API_HOST}ports/${payload.payload.portId}/agent_reviews/agents`

  try {
    yield put(setDataProfile({isLoading: true}))
    const data = yield call(httpGet, url)

    yield put(
      setOptions({
        [payload.payload.portId]: data.data.data.vipMembers.map(
          (value, index) => ({
            label: value.name,
            value: index + 1,
          }),
        ),
      }),
    )

    yield put(
      setAgentData({
        [payload.payload.portId]: data.data.data.vipMembers,
      }),
    )
  } catch (error) {
  } finally {
  }
}

export function* setEvaluateAgentSaga(payload) {
  const vipMemberId = payload.payload.data.vipMemberId
  const userInsuranceQuotationId = 4301
  const star = payload.payload.data.star
  const activeness = payload.payload.data.Activeness
  const afterSalesService = payload.payload.data.AfterSales
  const convenience = payload.payload.data.Convenience
  const impression = payload.payload.data.Impression
  const information = payload.payload.data.Informative
  const profession = payload.payload.data.Professions
  const reviewRemark = payload.payload.data.Remark

  const url = `${API_HOST}ports/${payload.payload.data.id}/agent_reviews`

  try {
    const status = yield httpPost(url, {
      vipMemberId,
      userInsuranceQuotationId,
      star,
      activeness,
      afterSalesService,
      convenience,
      impression,
      information,
      profession,
      reviewRemark,
      vipMemberId,
    })

    yield put(getEvaluateData(payload.payload.data.id))
  } catch (error) {}
}

export function* getPortSaga() {
  const url = `${API_HOST}ports`

  try {
    const status = yield httpGet(url)

    yield put(setDataProfile({port: status.data.data.ports}))
  } catch (error) {}
}

export function* getEvaluateDataSaga(payload) {
  const url = `${API_HOST}ports/${payload.payload.portId}/agent_reviews`

  yield put(setDataProfile({isLoading: true}))
  try {
    const response = yield httpGet(url)

    if (response.data.data.agentReview !== null) {
      yield put(
        setPort({[payload.payload.portId]: response.data.data.agentReview}),
      )
    } else {
      yield put(setPort({[payload.payload.portId]: {}}))
    }
  } catch (error) {
  } finally {
    yield put(setDataProfile({isLoading: false}))
  }
}

export function* sendEvaluateDataSaga({payload: {data}}) {
  try {
    yield put(setDataProfile({isLoading: true}))
    const modifiedData = {
      ...data,
      age: parseInt(data.age),
      retireAge: parseInt(data.retireAge),
    }
    const url = `${API_HOST}user_answers`
    yield httpPost(url, modifiedData)
    yield call(getUserInfoSaga)
  } catch (error) {
    console.error(error.response || error)
  } finally {
    yield put(setDataProfile({isLoading: false}))
  }
}

export function* deleteCompareSaga(payload) {
  const compareHistoryId = payload.payload.id

  try {
    yield put(setDataProfile({isLoading: true}))
    if (payload.payload.type === 'motor') {
      const url = `${API_HOST}auto_insurance_policies/compare`
      const response = yield httpDelete(url, {compareHistoryId})
    } else {
      const url = `${API_HOST}life_insurance_policies/compare`
      const response = yield httpDelete(url, {compareHistoryId})
    }

    yield put(getCompareList())
  } catch (error) {
    console.log(error)
  }
}

export function* setPortDetailDataSaga(payload) {
  try {
    const userData = yield select(s => s.auth.user)
    const url = `${API_HOST}ports`

    const first_name = userData.firstNameTh
    const last_name = userData.lastNameTh
    const gender = userData.gender
    const age = payload.payload.data.values.age
    const policy_type = payload.payload.data.values.policyType
    const insurance_type = payload.payload.data.values.insuranceType
    const insurance_company_id =
      payload.payload.data.values.insuranceCompanyName
    const insurance_policy_id = payload.payload.data.values.policiesSearch
    const name = payload.payload.data.policyName
    const policy_no = payload.payload.data.values.policyNo
    const start_policy_at = payload.payload.data.values.startPolicyAt
    const end_policy_at = payload.payload.data.values.endPolicyAt
    const remark = payload.payload.data.values.remark
    const sum_insured = payload.payload.data.values.sumInsured
    const premium = payload.payload.data.values.premium
    const payment_type = payload.payload.data.values.paymentType
    const yield_to_maturity_rate =
      payload.payload.data.values.yieldToMaturityRate
    const pay_period_type = payload.payload.data.values.payPeriodType
    const pay_period_value = payload.payload.data.values.payPeriodValue

    yield httpPost(url, {
      first_name,
      last_name,
      gender,
      age,
      policy_type,
      insurance_type,
      insurance_company_id,
      insurance_policy_id,
      name,
      policy_no,
      start_policy_at,
      end_policy_at,
      remark,
      sum_insured,
      premium,
      payment_type,
      yield_to_maturity_rate,
      pay_period_type,
      pay_period_value,
    })

    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

export function* updatePortDetailDataSaga(payload) {
  const PORT_ID = payload.payload.values.id
  try {
    const url = `${API_HOST}ports/${PORT_ID}`

    const first_name = payload.payload.values.firstName // ชื่อจริง
    const last_name = payload.payload.values.lastName // นามสกุล
    const gender = payload.payload.values.gender // เพศ
    const age = payload.payload.values.age // อายุ
    const policy_type = payload.payload.values.policyType // ประเภทประกัน
    const insurance_type = payload.payload.values.insuranceType // ประเภทกรมธรรม์
    const insurance_company_id = payload.payload.values.insuranceCompanyName // บริษัทประกัน
    const insurance_policy_id = payload.payload.values.insurancePolicyId // ไอดี แบบประกัน
    const name = payload.payload.policyName // ชื่อ แบบประกัน
    const policy_no = payload.payload.values.policyNo // หมายเลขกรมธรรม์
    const start_policy_at = // วันที่เริ่มสัญญา
      payload.payload.values.startPolicyAt.substring(0, 2) +
      '/' +
      payload.payload.values.startPolicyAt.substring(2, 4) +
      '/' +
      payload.payload.values.startPolicyAt.substring(4, 8)
    const end_policy_at = // วันที่ครบกำหนดสัญญา (คุ้มครองถึง)
      payload.payload.values.endPolicyAt.substring(0, 2) +
      '/' +
      payload.payload.values.endPolicyAt.substring(2, 4) +
      '/' +
      payload.payload.values.endPolicyAt.substring(4, 8)
    const remark = payload.payload.values.remark // รายละเอียดเพิ่มเติม
    const sum_insured = payload.payload.values.sumInsured // ทุนประกัน (บาท)
    const premium = payload.payload.values.premium // เบี้ยประกัน (ต่อปี)
    const payment_type = payload.payload.values.paymentType // รูปแบบการจ่าย
    const yield_to_maturity_rate = payload.payload.values.yieldToMaturityRate // เงินคืนเมื่อครบสัญญา (%)
    const pay_period_type = payload.payload.values.payPeriodType // วิธีนับระยะเวลาจ่ายเบี้ย
    const pay_period_value = payload.payload.values.payPeriodValue // จำนวนปีที่จ่ายเบี้ย

    yield httpPut(url, {
      first_name,
      last_name,
      gender,
      age,
      policy_type,
      insurance_type,
      insurance_company_id,
      insurance_policy_id,
      name,
      policy_no,
      start_policy_at,
      end_policy_at,
      remark,
      sum_insured,
      premium,
      payment_type,
      yield_to_maturity_rate,
      pay_period_type,
      pay_period_value,
    })

    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

export function* getCoverageTableSaga({
  payload: {id, age, gender, sumInsured},
}) {
  const url = `${API_HOST}life_insurance_policies/${id}/full_benefit`
  const newData = {age: age, gender: gender, sumInsured: sumInsured}

  try {
    const response = yield httpGet(url, newData)
    yield put(setDataProfile({fullBenefit: response.data.data}))
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setDataProfile({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
}

export function* getInsuranceSearchSaga(payload) {
  const url = `${API_HOST}ports/insurance_policy_names`

  const insurance_company_id =
    payload.payload.insuranceSearchKeyWord.insuranceCompanyId
  const insurance_type = payload.payload.insuranceSearchKeyWord.insuranceType
  const query = payload.payload.insuranceSearchKeyWord.userQuery

  try {
    const response = yield httpGet(url, {
      insurance_company_id,
      insurance_type,
      query,
    })

    const insuranceSearch = response.data.data.map(item => ({
      label: item.name,
      value: item.id,
    }))

    yield put(
      setDataProfile({
        insuranceSearchList: insuranceSearch,
      }),
    )
  } catch (error) {
    console.log(error)
  }
}

export function* getTotalDetailSaga() {
  const url = `${API_HOST}ports/total_details`
  try {
    const response = yield httpGet(url)
    yield put(
      setDataProfile({
        totalDetailLife: response.data.data.totalDetails.life,
      }),
    )
  } catch (error) {
    console.log(error)
  }
}

export function* deletePortSaga(payload) {
  const PORT_ID = payload.payload
  try {
    const url = `${API_HOST}ports/${PORT_ID}`
    yield httpDelete(url)
  } catch (error) {
    console.log(error)
  }
}

export function* watchProfileSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga)
  yield takeLatest(EDIT_PROFILE, editProfileSaga)
  yield takeLatest(GET_FAVORITE, getFavoriteSaga)
  yield takeLatest(GET_ARTICLES, getArticlesSaga)
  yield takeLatest(GET_POLICIES, getPoliciesSaga)
  yield takeLatest(GET_AVATAR, getAvatarSaga)
  yield takeLatest(SEND_LEAD_AGAIN, sendLeadAgainSaga)
  yield takeLatest(GET_LEAD, getLeadSaga)
  yield takeLatest(GET_COMPARE_LIST, getCompareListSaga)
  yield takeLatest(GET_OPTIONS_PORT, getOptionsPortSaga)
  yield takeLatest(GET_PORT_OPTIONS, getPortOptionsSaga)
  yield takeLatest(GET_AGENT_DATA, getAgentDataSaga)
  yield takeLatest(SET_EVALUATE_AGENT, setEvaluateAgentSaga)
  yield takeLatest(GET_PORT, getPortSaga)
  yield takeLatest(GET_EVALUATE_DATA, getEvaluateDataSaga)
  yield takeLatest(SEND_EVALUATE_DATA, sendEvaluateDataSaga)
  yield takeLatest(DELETE_COMPARE, deleteCompareSaga)
  yield takeLatest(SET_PORT_DETAIL_DATA, setPortDetailDataSaga)
  yield takeLatest(GET_COVERAGE_TABLE, getCoverageTableSaga)
  yield takeLatest(GET_INSURANCE_SEARCH, getInsuranceSearchSaga)
  yield takeLatest(GET_TOTAL_DETAIL, getTotalDetailSaga)
  yield takeLatest(DELETE_PORT, deletePortSaga)
  yield takeLatest(UPDATE_PORT_DETAIL_DATA, updatePortDetailDataSaga)
}

const initial = {
  isLoading: false,
  errorMessage: '',
  articles: [],
  insurancePolicies: [],
  avartars: [],
  leadMotor: [],
  leadLife: [],
  leadHealth: [],
  compareLife: [],
  compareMotor: [],
  portOptions: {},
  agentData: {},
  agentOption: [{label: '5555+', value: ''}],
  evaluateAgent: {},
  port: [],
  insuranceSearchList: [],
}

export default createReducer(initial, state => ({
  [SET_LOADING]: ({isLoading}) => ({...state, isLoading: isLoading}),
  [SET_DATA_PROFILE]: ({data}) => ({...state, ...data}),
  [SET_AGENT_DATA]: ({data}) => ({
    ...state,
    agentData: {...state.data, ...data},
  }),
  [SET_PORT]: ({data}) => ({
    ...state,
    evaluateAgent: {...state.evaluateAgent, ...data},
  }),
  [SET_OPTIONS]: ({data}) => ({
    ...state,
    agentOption: {...state.agentOption, ...data},
  }),
}))
