import React, {Fragment} from 'react'
import {compose, withProps, withState} from 'recompose'

import {displayNumber, obj2Url} from '../../../../core/helper'
import ModalFavorite from '../../../share/ModalFavorite'
import ModalCatalogue from '../ModalCatalogue'
import KendoIcon from '../../../share/Icon'
import {
  Layout,
  ActionWrapper,
  HeartIcon,
  DescriptionWrapper,
  Body,
  Title,
  Logo,
  CompanyLogo,
  WrapperTitle,
  ProductName,
  CompanyName,
  RowInfo,
  Content,
  ContentRegular,
  IconCheckCircle,
  IconTimesCircle,
  Premium,
  Footer,
  CustomTagA,
  DetailButton,
  InterestButton,
} from './Life'

const Hs = props => (
  <Layout>
    {props.tag ? <Fragment>{props.tag}</Fragment> : null}

    <ActionWrapper>
      {props.SpecialActionComponent ? (
        <props.SpecialActionComponent {...props} />
      ) : (
        <div />
      )}

      <HeartIcon
        onClick={() => {
          props.isLogin
            ? props.onFavorite(
                !props.localFavorite,
                props.data.id,
                'life',
                props.data.paramsEncode,
              )
            : props.setIsShowModalFavorite(!props.isShowModalFavorite)
        }}
        icon={props.localFavorite ? ['fas', 'heart'] : ['far', 'heart']}
      />
    </ActionWrapper>

    <DescriptionWrapper>
      <Body>
        <Title>
          <Logo>
            <CompanyLogo src={props.data.companyOfficialLogoUrl} />
          </Logo>
          <WrapperTitle>
            <ProductName>{props.data.policies[0].name}</ProductName>
            <CompanyName>{props.data.companyName}</CompanyName>
          </WrapperTitle>
        </Title>

        <RowInfo>
          <Content>เบี้ยประกัน ณ ปีปัจจุบัน</Content>
          <Premium>
            <span>
              {displayNumber(parseInt(props.data.policies[0].premium))}
            </span>
            &nbsp;&nbsp;บาท/ปี
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>ค่ารักษาพยาบาลสูงสุด</Content>
          <ContentRegular>
            {`${displayNumber(props.totalAnnualBenefits)} บาท/ปี`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ค่ารักษาพยาบาลต่อครั้ง</Content>
          <ContentRegular>
            {`${displayNumber(props.totalBenefits)} บาท`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ค่าห้อง</Content>
          <ContentRegular>
            {`${displayNumber(props.room)} บาท/คืน`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ค่าแพทย์</Content>
          <ContentRegular>
            {`${displayNumber(props.doctorVisit)} บาท/คืน`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ค่าผ่าตัด</Content>
          <ContentRegular>
            {`${displayNumber(props.surgeon)} บาท/คืน`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>คุ้มครองกรณีผู้ป่วยนอก</Content>
          <ContentRegular>
            {props.otherOpdBenefit ? (
              <IconCheckCircle icon={['fas', 'check-circle']} />
            ) : (
              <IconTimesCircle icon={['fas', 'times-circle']} />
            )}
          </ContentRegular>
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA href={props.pathUrl} target="_blank">
          <DetailButton type="border">
            รายละเอียด
            <KendoIcon icon="table-align-middle-left" />
          </DetailButton>
        </CustomTagA>
        <InterestButton
          onClick={() => {
            props.setIsShowModal(true)
          }}>
          สนใจซื้อ
          <KendoIcon icon="cart" />
        </InterestButton>
      </Footer>
    </DescriptionWrapper>

    <ModalFavorite
      isShowModal={props.isShowModalFavorite}
      setIsShowModal={props.setIsShowModalFavorite}
      isReload
    />
    <ModalCatalogue
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      category="health"
      params={props.params}
      premium={props.data.policies[0].premium}
      id={props.data.id}
      packageId={props.data.packageId}
      paramsEncode={props.data.paramsEncode}
      productName={props.data.policies[0].name}
    />
  </Layout>
)

export const pathUrlHealth = props => {
  return `/smart/health/${
    props.data.level === undefined ? 'allLevel' : props.data.level
  }/product/${props.data.policies[0].id}?${obj2Url({
    ...props.params,
    premium: parseInt(props.data.policies[0].premium),
    sumInsured: parseInt(props.data.policies[0].sumInsured),
    packageId: props.data.packageId,
    id: props.data.id,
    encodeFlag: props.fromFavoriteProfile ? 'true' : 'false',
    paramsEncode: props.data.paramsEncode,
  })}`
}

const enhance = compose(
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withState('isShowModal', 'setIsShowModal', false),
  withProps(props => {
    const totalAnnualBenefits = props.data.policies[0].protectionDetail.general.find(
      item => item.key === 'total_annual_benefits',
    ).displayValue[0]
    const totalBenefits = props.data.policies[0].protectionDetail.general.find(
      item => item.key === 'total_benefits',
    ).displayValue[0]
    const room = props.data.policies[0].protectionDetail.ipd.find(
      item => item.key === 'room',
    ).displayValue[0]

    const doctorVisit = props.data.policies[0].protectionDetail.ipd.find(
      item => item.key === 'doctor_visit',
    ).displayValue[0]

    const surgeon = props.data.policies[0].protectionDetail.ipd.find(
      item => item.key === 'surgeon',
    ).displayValue[0]

    const otherOpdBenefit = props.data.policies[0].protectionDetail.opd.find(
      item => item.key === 'other_opd_benefit',
    ).displayValue[0]

    return {
      pathUrl: `${pathUrlHealth(props)}`,
      totalAnnualBenefits: totalAnnualBenefits,
      totalBenefits: totalBenefits,
      room: room,
      doctorVisit: doctorVisit,
      surgeon: surgeon,
      otherOpdBenefit: otherOpdBenefit,
    }
  }),
)
export default enhance(Hs)
