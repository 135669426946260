import React from 'react'
import {Link, Location} from '@reach/router'
import {compose, lifecycle, withState} from 'recompose'
import styled from 'react-emotion'
import {Steps} from 'antd'

const {Step} = Steps

const CustomSteps = styled(Steps)`
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot,
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot,
  .ant-steps-item-finish > .ant-steps-item-tail:after {
    background: #ffb933;
  }
  @media (max-width: 425px) {
    &,
    .ant-steps {
      width: unset !important;
    }
  }
`

export const CustomStep = props => {
  const {step} = props
  return (
    <CustomSteps progressDot size="small" current={step - 1}>
      <Step />
      <Step />
      <Step />
    </CustomSteps>
  )
}

const CustomsLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  flex: 1;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  padding: 0 15px;
  font-size: 14px;
  height: 32px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 20px;
  color: #333333;
  background: #ffb933;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  border: none;
  text-decoration: none;
  :hover,
  :active,
  :focus {
    color: white;
    text-decoration: none;
  }
`

export const PrevButton = props => {
  return (
    <CustomsLink to={props.path} disabled={props.disabled}>
      ก่อนหน้า
    </CustomsLink>
  )
}

export const NextButton = props => {
  return (
    <CustomsLink to={props.path} disabled={props.disabled}>
      ถัดไป
    </CustomsLink>
  )
}

const TabNoonQuestion = styled.div`
  height: 80px;
  background: #ffb933;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  @media (max-width: 425px) {
    height: 146px;
  }
`
const WrapContent = styled.div`
  max-width: 965px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 965px;
  justify-content: space-around;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const TextDetails = styled.div`
  color: #ffffff;
  font-size: 28px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 425px) {
    text-align: center;
    margin-top: 10px;
  }
`
const ButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 20px;
  border: unset;
  color: black;
  background: #265fa7;
  position: relative;
  width: 220px !important;
  height: 40px;
  font-size: 24px;
  border-radius: 20px;
  z-index: 998;
  color: #ffffff;
  :hover,
  :active,
  :focus {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    z-index: 99;
    width: 150px !important;
    font-size: 20px;
  }
  @media (max-width: 425px) {
    width: 220px !important;
    margin-bottom: 20px;
  }
`
const ButtonHide = styled(ButtonLink)`
  background: #ffffff;
  color: #ffb933;
  :hover,
  :active,
  :focus {
    color: #ffb933;
  }
`
const Tritangle = styled.div`
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 23px solid #ffffff;
  bottom: 0;
  position: absolute;
`
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`
const Br = styled.br`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`
const SignNew = styled.div`
  position: absolute;
  right: 0px;
  top: -15px;
  font-size: 10px;
  background: red;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = ({title}) => (
  <TabNoonQuestion>
    <WrapContent>
      <TextDetails>
        {title || (
          <>
            ลองดูไหมว่าคุณจะต้องมี
            <Br />
            ประกันชีวิตและสุขภาพอะไรบ้าง?
          </>
        )}
      </TextDetails>
      <Location>
        {({location}) => {
          if (location.pathname.includes('/noon-question')) {
            return (
              <Wrap>
                <ButtonHide to={'/'}>ซ่อน</ButtonHide>
                <Tritangle />
              </Wrap>
            )
          } else {
            return (
              <ButtonLink to={'/noon-question'}>
                <SignNew>New</SignNew>ไปเลย!
              </ButtonLink>
            )
          }
        }}
      </Location>
    </WrapContent>
  </TabNoonQuestion>
)

const enhancer = compose(
  withState('oldAutoHeaderDisplayValue', 'setOldAutoHeaderDisplayValue', null),
  lifecycle({
    componentDidMount() {
      const $autoHeader = document.getElementById('auto-header')
      if ($autoHeader) {
        this.props.setOldAutoHeaderDisplayValue($autoHeader.style.display)
        $autoHeader.style.display = 'none'
      }
    },
    componentWillUnmount() {
      const $autoHeader = document.getElementById('auto-header')
      if ($autoHeader) {
        $autoHeader.style.display = this.props.oldAutoHeaderDisplayValue
      }
    },
  }),
)

export default enhancer(Header)
