import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import {isEqual} from 'lodash'
import spidusStore from '../../core/spidus/spidusStore'

import {compose, withProps, lifecycle, withHandlers, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import ProductCard from '../../../NoonSmart/Product/Card/index'
import TagContaniner from '../../../NoonSmart/Product/Card/TagContaniner'

import NoProductCard from './NoProductCard'
import {omitProps} from '../../enhances/index'
import {httpGet} from '../../../../core/httpClient'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
`
const Caption = styled.span`
  font-size: 22px;
  color: #333;
  margin: 30px 0px;
`

const TryThisPremium = styled.div`
  /* position: absolute;
  left: 32px;
  top: 20px;
  z-index: 1; */
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    margin-right: 4px;
    color: #333333;
  }
  > div {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }

  ${props =>
    props.isTryThis &&
    css`
      > svg {
        color: #9fd134;
      }
      > div {
        color: #9fd134;
      }
    `};
`

const TryThisPremiumActionComponent = props => (
  <TryThisPremium
    isTryThis={props.isTryThis}
    onClick={() => props.togglePremiumTrial(props.value)}>
    <FontAwesomeIcon
      icon={props.isTryThis ? ['fas', 'check-circle'] : ['far', 'circle']}
    />
    <div>ลองใช้แบบนี้ลดภาษี</div>
  </TryThisPremium>
)

const Products = props => (
  <Layout className={props.className}>
    {props.caption && <Caption>{props.caption}</Caption>}
    {props.loading ? (
      <NoProductCard loading={props.loading} />
    ) : (
      <Fragment>
        {props.productList.length > 0 ? (
          <ProductCardContainer>
            {props.productList.map(value => (
              <Fragment key={value.id}>
                <ProductCard
                  key={value.id}
                  data={value}
                  noRider
                  params={props.productParams}
                  specialAction={() => (
                    <TryThisPremiumActionComponent
                      togglePremiumTrial={props.togglePremiumTrial}
                      isTryThis={props.isTryThis}
                      value={value}
                    />
                  )}
                  tag={
                    <TagContaniner caption="แบบประกันแนะนำ" bgColor="#FFB933" />
                  }
                />
              </Fragment>
            ))}
          </ProductCardContainer>
        ) : (
          <NoProductCard />
        )}
      </Fragment>
    )}
  </Layout>
)

const enhance = compose(
  withState('productList', 'setProductList', []),
  withState('loading', 'setLoading', false),
  withState('fetchTask', 'setFetchTask', null),
  withState('backupAmount', 'setBackupAmount', null),
  withProps(props => {
    switch (props.type) {
      case 'wholelife':
        return {
          category: 'life',
          productApi: 'life_insurance_policies/search_life_products',
          productParams: {
            age: props.age || 0,
            gender: 'male',
            search_by: 'premium',
            search_value: props.amount || 0,
            sort_by: 'max_noon_score',
            weight_type: '5',
            is_tax_saving: true,
          },
        }
      case 'annuity':
        return {
          category: 'life',
          productApi: 'life_insurance_policies/search_life_products',
          productParams: {
            age: props.age || 0,
            gender: 'male',
            insurance_type: 'annuity',
            search_by: 'premium',
            search_value: props.amount || 0,
            sort_by: 'max_noon_score',
            weight_type: '1',
            is_tax_saving: true,
          },
        }
      case 'health':
        return {
          category: 'health',
          productApi: 'health_insurance_policies/search_health_products',
          productParams: {
            age: props.age || 0,
            gender: 'male',
            premium: props.amount || 0,
            package_type: 'single',
            is_tax_saving: true,
          },
        }
    }
  }),
  withHandlers({
    fetchProductList: props => () => {
      if (props.fetchTask) {
        clearTimeout(props.fetchTask)
      }

      if (props.isTryThis) {
        return
      }

      props.setFetchTask(
        setTimeout(async () => {
          props.setLoading(true)
          let response = await httpGet(props.productApi, props.productParams)
          let productList = response.data.data[`${props.category}Products`]

          if (productList.length === 0 && props.limit > 0) {
            switch (props.type) {
              case 'wholelife':
                response = await httpGet(props.productApi, {
                  age: 35,
                  gender: 'male',
                  search_by: 'premium',
                  search_value: Math.min(15000, props.maxCap || 0),
                  sort_by: 'max_noon_score',
                  weight_type: '5',
                  is_tax_saving: true,
                })
                break
              case 'annuity':
                response = await httpGet(props.productApi, {
                  age: 35,
                  gender: 'male',
                  insurance_type: 'annuity',
                  search_by: 'premium',
                  search_value: Math.min(15000, props.maxCap || 0),
                  sort_by: 'max_noon_score',
                  weight_type: '1',
                  is_tax_saving: true,
                })
                break
              case 'health':
                response = await httpGet(props.productApi, {
                  age: 35,
                  gender: 'male',
                  premium: Math.min(15000, props.maxCap || 0),
                  package_type: 'single',
                  is_tax_saving: true,
                })
                break
            }
            productList = response.data.data[`${props.category}Products`]
          }

          if (props.limit) {
            productList = productList.slice(0, props.limit)
          }

          props.setProductList(productList)
          props.setLoading(false)
        }, props.delay * 1000),
      )
    },
    togglePremiumTrial: props => value => {
      if (props.isTryThis) {
        props.onTryThisPremium({
          toggleValue: false,
          value: props.backupAmount,
        })
      } else {
        props.setBackupAmount(props.amount)
        props.onTryThisPremium({
          toggleValue: true,
          value: value.premium || value.totalPremium,
        })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchProductList()
    },
    componentWillReceiveProps(nextProps) {
      if (
        (!isEqual(this.props.productParams, nextProps.productParams) ||
          this.props.maxCap !== nextProps.maxCap) &&
        nextProps.backupAmount !== nextProps.amount
      ) {
        nextProps.fetchProductList()
      }
    },
  }),
  omitProps(
    'productApi',
    'fetchProductList',
    'setProductList',
    'setLoading',
    'fetchTask',
    'setFetchTask',
  ),
)

export default enhance(Products)
