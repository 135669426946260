import CompanyLogo from '../../static/images/company-logo.jpg'
import {
  type,
  others,
  InsuranceCompany,
  noneStar,
  carType,
  firstDamage,
  otherDamage,
  fixing,
} from '../../components/NoonSmart/options'

export const motorCardDetails = [
  {
    id: 1,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 2,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 3,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 4,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 5,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 6,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 7,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 8,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
  {
    id: 9,
    iconList: [
      {
        icon: 'hand-holding-usd',
        details: 'ทุนประกัน 100,000 บาท',
      },
      {
        icon: 'user-shield',
        details: 'ความคุ้มครองบุคคลภายนอก 1,000,000 บาท',
      },
      {
        icon: 'car-crash',
        details: 'ค่าเสียหายส่วนแรก 2,000 บาท',
      },
      {
        icon: 'wrench',
        details: 'ซ่อม อู่',
      },
      {
        icon: 'user-slash',
        details: 'ไม่ต้องระบุคู่กรณี',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: '',
    companyName: 'MTL1',
    productName: 'เมืองไทย ประกันรถยนต์ 24/7',
    popularRate: 5,
    premium: 3000,
    level: 'ประกันชั้น 3+',
    sumInsured: 8000000,
    hashtag: '#ประกันรถยนต์ชั้น3+ #ป้ายแดง',
    isStar: false,
  },
]

export const healthCardDetails = [
  {
    id: 1,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL1',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 2,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL2',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 3,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL3',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 4,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL4',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 5,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL5',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 6,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL6',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 7,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL7',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 8,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL8',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
  {
    id: 9,
    iconList: [
      {
        icon: 'shield-alt',
        details: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
      },
      {
        icon: 'bed',
        details: 'วงเงินค่าห้อง 3,000 บาท/ คืน',
      },
    ],
    companyLogo: CompanyLogo,
    orderNumber: 10,
    companyName: 'MTL9',
    productName: 'เมืองไทย รักสุขภาพ',
    popularRate: 5,
    premium: 9000,
    healthInsured: 'พ่วงประกันชีวิต',
    protectionPeriod: 'วงเงินประกันสุขภาพสูงสุด 2,000,000 บาท/ ปี',
    sumInsured: 8000000,
    hashtag: '#ค่ารักษาพยาบาล #ประกันสุขภาพ',
    isStar: true,
  },
]

export const LifeCheckboxesHeaderName = [
  // {
  //   id: 1,
  //   checkboxesHeadName: 'TQM Star',
  //   classname: 'noonStar',
  //   name: 'noonStar',
  //   options: noneStar,
  //   type: 'star',
  // },
  {
    id: 2,
    checkboxesHeadName: 'ประเภท',
    classname: 'insuranceType',
    name: 'insuranceType',
    options: type,
    type: 'checkboxes',
  },
  {
    id: 3,
    checkboxesHeadName: 'ความต้องการ',
    classname: 'weightType',
    name: 'weightType',
    options: '',
    type: 'slider',
  },
  {
    id: 4,
    checkboxesHeadName: 'อื่นๆ',
    classname: 'others',
    name: 'others',
    options: others,
    type: 'checkboxes',
  },
  {
    id: 5,
    checkboxesHeadName: 'บริษัทประกัน',
    classname: 'companies',
    name: 'companyIds',
    type: 'company',
    checkAll: true,
  },
]

export const motorCheckboxesHeaderName = [
  {
    id: 1,
    checkboxesHeadName: 'ประเภท',
    classname: 'carType',
    name: 'insuranceLevel',
    options: carType,
    type: 'checkboxes',
  },
  {
    id: 2,
    checkboxesHeadName: 'ความเสียหายส่วนแรก',
    classname: 'firstDamage',
    name: 'deduct',
    options: firstDamage,
    type: 'radios',
  },
  {
    id: 3,
    checkboxesHeadName: 'ความคุ้มครองเพิ่มเติม',
    classname: 'otherDamage',
    name: 'otherDamage',
    options: otherDamage,
    type: 'checkboxes',
  },
  {
    id: 4,
    checkboxesHeadName: 'การจัดซ่อม',
    classname: 'fixing',
    name: 'repairServiceType',
    options: fixing,
    type: 'checkboxes',
  },
  {
    id: 5,
    checkboxesHeadName: 'บริษัทประกัน',
    classname: 'insurerCompany',
    name: 'companyIds',
    type: 'company',
    checkAll: true,
  },
]

export const healthCheckboxesHeaderName = [
  {
    id: 1,
    checkboxesHeadName: 'บริษัทประกัน',
    classname: 'companies',
    name: 'companyIds',
    type: 'company',
    checkAll: true,
  },
]
