import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled, { css } from 'react-emotion'
import windowSize from '../../core/react-window-size'

import { formValueSelector } from 'redux-form'
import CustomField, { ErrorContainer } from '../share/Field'
import { inRange, displayNumber, numberParser } from '../../core/helper'
import { responsive, breakpoints } from '../../core/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import manToddler from '../../static/images/man_set_1toddler.svg'
import manPreSchool from '../../static/images/man_set_2preschool.svg'
import manGradeSchooler from '../../static/images/man_set_3gradeschooler.svg'
import manTeen from '../../static/images/man_set_4teen.svg'
import manYoungAdult from '../../static/images/man_set_5youngadult.svg'
import manAdult from '../../static/images/man_set_6adult.svg'
import manSenior from '../../static/images/man_set_7senior.svg'
import manElder from '../../static/images/man_set_8elder.svg'

import womanToddler from '../../static/images/woman_set_1toddler.svg'
import womanPreSchool from '../../static/images/woman_set_2preschool.svg'
import womanGradeSchooler from '../../static/images/woman_set_3gradeschooler.svg'
import womanTeen from '../../static/images/woman_set_4teen.svg'
import womanYoungAdult from '../../static/images/woman_set_5youngadult.svg'
import womanAdult from '../../static/images/woman_set_6adult.svg'
import womanSenior from '../../static/images/woman_set_7senior.svg'
import womanElder from '../../static/images/woman_set_8elder.svg'

import Car from '../../static/images/house_car.svg'
import Coin from '../../static/images/house_coin.svg'
import House from '../../static/images/house_house.svg'
import moneyBag from '../../static/images/house_moneybag.svg'
import Piggy from '../../static/images/house_piggy.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  margin-top: 43px;

  ${props =>
    responsive.tablet(css`
      justify-content: center;
      width: 100%;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin-bottom: 0;
      margin-top: 60px;
    `)};
`

const PropertyContainer = styled.div`
  position: relative;
  min-width: 364px;
  height: 195px;
  border: 1px solid #ffd482;
  border-radius: 5px;
  background-color: white;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      min-width: 280px;
    `)};
`

const GenderAndAgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 85px;
  width: 100%;

  ${props =>
    responsive.mobile(css`
      padding: unset;
      align-items: center;
    `)};
`

const GenderButton = styled(CustomField)`
  display: flex;
  justify-content: space-between;
  width: 480px;
  margin-bottom: 30px;
  > div {
    width: fit-content;
  }

  .label {
    min-width: 100px;
    justify-content: flex-start;
  }
  ${ErrorContainer} {
    left: 0px;
  }

  .input-switch-container {
    width: 160px;
    justify-content: center;
    .male {
      width: 80px;
    }
    .female {
      width: 80px;
    }
  }

  ${props =>
    responsive.tablet(css`
      display: flex;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      align-items: center;
      width: 100%;
      .label {
        width: 240px;
        justify-content: flex-start;
        margin: 20px 0 15px;
      }
      .input-switch-container {
        width: 240px;
        justify-content: center;
        .male {
          width: 120px;
        }
        .female {
          width: 120px;
        }
      }
      ${ErrorContainer} {
        left: 85px;
      }
    `)};
`

const Age = styled(CustomField)`
  width: 480px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  > div {
    width: fit-content;
    > input {
      width: 160px;
    }
  }
  .label {
    min-width: unset;
  }
  input {
    width: fit-content;
    text-align: center;
  }
  ${ErrorContainer} {
    left: 0px;
  }
  ${props =>
    responsive.tablet(css`
      width: unset;
    `)};

  ${props =>
    responsive.mobile(css`
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .label {
        width: 240px;
        justify-content: flex-start;
        margin-bottom: 15px;
        margin-right: 0px;
      }

      > div {
        width: fit-content;
        > input {
          width: 240px;
        }
      }
      ${ErrorContainer} {
        left: 85px;
      }
    `)};
`

const Unit = styled.span`
  font-size: 16px;
  padding-top: 8px;
  margin-left: 18px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const AgeContainer = styled.div`
  display: flex;
  width: 100%;
`
const GenderImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 6;
  height: 100%;
`

const ChildImage = styled(GenderImage)`
  left: calc(50% + 28px);
  display: ${props => (props.isChildActive ? 'flex' : 'none')};
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 14px;
  margin-bottom: 11px;
`

const PropertyImage = styled.img`
  position: absolute;
  width: ${props => props.setPositionWidth};
  bottom: ${props => props.setPositionBottom};
  left: ${props => props.setPositionLeft};
  z-index: ${props => props.setPositionIndex};
  opacity: ${props => (props.isActive ? '1' : '0.2')};

  ${props =>
    responsive.mobile(css`
      left: ${props.setPositionLeftMobile};
    `)};
`
const propertyLevel = [
  {
    propertyObject: Coin,
    richLevel: 100000,
    setPositionLeft: '104px',
    setPositionLeftMobile: '64px',
    setPositionBottom: '9px',
    setPositionWidth: '100px',
    setPositionIndex: '5',
  },
  {
    propertyObject: Piggy,
    richLevel: 100000,
    setPositionLeft: '90px',
    setPositionLeftMobile: '50px',
    setPositionBottom: '7px',
    setPositionWidth: '90px',
    setPositionIndex: '4',
  },
  {
    propertyObject: moneyBag,
    richLevel: 100000,
    setPositionLeft: '51px',
    setPositionLeftMobile: '21px',
    setPositionBottom: '31px',
    setPositionWidth: '90px',
    setPositionIndex: '3',
  },
  {
    propertyObject: Car,
    richLevel: 1000000,
    setPositionLeft: '195px',
    setPositionLeftMobile: '145px',
    setPositionBottom: '25px',
    setPositionWidth: '125px',
    setPositionIndex: '2',
  },
  {
    propertyObject: House,
    richLevel: 5000000,
    setPositionLeft: '135px',
    setPositionLeftMobile: '95px',
    setPositionBottom: '35px',
    setPositionWidth: '160px',
    setPositionIndex: '1',
  },
]

const genderInfo = [
  { maleType: manToddler, femaleType: womanToddler, ageStart: 1, ageEnd: 2 },
  { maleType: manPreSchool, femaleType: womanPreSchool, ageStart: 3, ageEnd: 6 },
  {
    maleType: manGradeSchooler,
    femaleType: womanGradeSchooler,
    ageStart: 7,
    ageEnd: 12,
  },
  { maleType: manTeen, femaleType: womanTeen, ageStart: 13, ageEnd: 19 },
  {
    maleType: manYoungAdult,
    femaleType: womanYoungAdult,
    ageStart: 20,
    ageEnd: 39,
  },
  { maleType: manAdult, femaleType: womanAdult, ageStart: 40, ageEnd: 59 },
  { maleType: manSenior, femaleType: womanSenior, ageStart: 60, ageEnd: 70 },
  { maleType: manElder, femaleType: womanElder, ageStart: 71, ageEnd: 100 },
]

const PersonalForm = ({
  gender,
  age,
  level,
  className,
  isChildActive,
  hideAvatar = false,
  windowWidth,
}) => (
    <Container className={className}>
      <GenderAndAgeContainer>
        <GenderButton
          className="gender"
          name="gender"
          label="เพศ"
          type="switch"
          validate={[value => (value ? undefined : 'กรุณาเลือกเพศ')]}
        />
        <AgeContainer className="age">
          <Age
            name="age"
            type="text"
            label={`${windowWidth <= breakpoints.mobile ? `อายุ (ปี)` : `อายุ`}`}
            normalize={inRange(0, 100)}
            format={displayNumber}
            parse={numberParser}
            validate={[value => (value ? undefined : 'กรุณากรอกอายุ')]}
          />

          <Unit>ปี </Unit>
        </AgeContainer>
      </GenderAndAgeContainer>
    </Container>
  )

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    state => ({
      gender: selector(state, 'gender'),
      age: selector(state, 'age'),
    }),
    {},
  ),
)

export default windowSize(enhancer(PersonalForm))
