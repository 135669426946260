import React from 'react'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../../../core/style'
import {displayNumber} from '../../../../../core/helper'

const MoreBenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-size: 12px;
  width: fit-content;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const BenefitHeader = styled.div`
  position: relative;
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
`

const MyTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-top: 3px solid rgb(238, 238, 242, 0.7);
  /* border-top: 3px solid rgba(255, 197, 88, 0.7); */
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.05), */
  /*  0px 20px 20px rgba(0, 0, 0, 0.05), 0px 30px 20px rgba(0, 0, 0, 0.05); */
`

const TableHeader = styled.thead``

const HeaderRow = styled.tr`
  border: 1px solid #ddd;
  border-collapse: collapse;
`

const HeaderColumn = styled.th`
  /* border: 1px solid ${props => props.theme.EXTRALIGHTORANGE}; */
  border : 1px solid #eeeef2;
  border-bottom: 3px solid rgb(238, 238, 242, 0.7);
  /* border-bottom: 3px solid rgba(255, 197, 88, 0.7); */
  text-align: center;
  padding: 10px 10px;
  border-collapse: collapse;
  /* background-color: rgba(255, 197, 88, 0.3); */
  background-color: rgba(78, 173, 252, 0.3);
`

const HeaderColumnHalf = styled(HeaderColumn)`
  /* border-bottom: 1px solid ${props => props.theme.EXTRALIGHTORANGE}; */
  border-bottom : 1px solid #eeeef2;
`

const TableBody = styled.tbody`
  tr:nth-child(even) {
    /* background-color: rgba(255, 212, 130, 0.1); */
    background-color: rgba(102, 184, 253, 0.1);
  }
`

const DataRow = styled.tr`
  border-collapse: collapse;
`

const DataColumn = styled.td`
  /* border: 1px solid ${props => props.theme.EXTRALIGHTORANGE}; */
  border: 1px solid ${props => '#EEEEF2'};
  text-align: center;
  padding: 15px 10px;
  border-collapse: collapse;
`

const TableFooter = styled.tfoot`
  tr:nth-child(even) {
    /* background-color: rgba(255, 212, 130, 0.1); */
    background-color: rgba(102, 184, 253, 0.1);
  }
`

const FooterRow = styled.tr`
  border-collapse: collapse;
`

const FooterColumn = styled.th`
  /* border: 1px solid ${props => props.theme.EXTRALIGHTORANGE}; */
  border : 1px solid #EEEEF2;
  text-align: center;
  font-weight: normal;
  padding: 15px 10px;
  border-collapse: collapse;
`

const LifeTable = ({fullBenefit = {}, insuranceType}) => {
  const obj2arr = []
  if (fullBenefit.fullBenefit) {
    for (const [key, value] of Object.entries(fullBenefit.fullBenefit)) {
      obj2arr.push({...value, age: key})
    }
  }
  return (
    <MoreBenefitContainer>
      <BenefitHeader>ตารางความคุ้มครอง</BenefitHeader>
      <MyTable>
        <TableHeader>
          <HeaderRow>
            <HeaderColumn rowSpan={2}>อายุ</HeaderColumn>
            <HeaderColumn rowSpan={2}>
              สิ้นปีกรมธรรม์
              <br />
              ประกันภัยที่
            </HeaderColumn>
            <HeaderColumn rowSpan={2}>เบี้ยประกันรายปี</HeaderColumn>
            <HeaderColumn rowSpan={2}>เบี้ยประกันสะสม</HeaderColumn>
            <HeaderColumnHalf colSpan={3}>เงินคืนรายงวด</HeaderColumnHalf>
            <HeaderColumnHalf colSpan={2}>ความคุ้มครองชีวิต</HeaderColumnHalf>
            {insuranceType === 'annuity' ? (
              <HeaderColumn rowSpan={2}>
                มูลค่าปัจจุบันของจำนวนเงินบำนาญที่บริษัทฯรับรอง
              </HeaderColumn>
            ) : null}
          </HeaderRow>
          <HeaderRow>
            <HeaderColumn>{'อัตรา (%)'}</HeaderColumn>
            <HeaderColumn>จำนวนเงิน</HeaderColumn>
            <HeaderColumn>จำนวนเงินสะสม</HeaderColumn>
            <HeaderColumn>{'อัตรา (%)'}</HeaderColumn>
            <HeaderColumn>จำนวนเงินประกันภัย</HeaderColumn>
          </HeaderRow>
        </TableHeader>
        <TableBody>
          {fullBenefit &&
            obj2arr.map((data, index) => (
              <DataRow key={index}>
                <DataColumn>{data.age}</DataColumn>
                <DataColumn>{data.year}</DataColumn>
                <DataColumn>{displayNumber(parseInt(data.premium))}</DataColumn>
                <DataColumn>
                  {displayNumber(parseInt(data.cumulativePremium))}
                </DataColumn>
                <DataColumn>{displayNumber(data.cashBackPercent)}</DataColumn>
                <DataColumn>{displayNumber(data.cashBackAmount)}</DataColumn>
                <DataColumn>
                  {displayNumber(data.cumulativeCashBack)}
                </DataColumn>
                <DataColumn>{displayNumber(data.protectionPercent)}</DataColumn>
                <DataColumn>
                  {data.protectionAmount <= 0
                    ? data.protectionDescription
                    : displayNumber(parseInt(data.protectionAmount))}
                </DataColumn>
                {insuranceType === 'annuity' ? (
                  <DataColumn>
                    {displayNumber(data.guaranteeAnnuity)}
                  </DataColumn>
                ) : null}
              </DataRow>
            ))}
        </TableBody>
        <TableFooter>
          <FooterRow>
            <FooterColumn colSpan={4}>รวม</FooterColumn>
            <FooterColumn>{displayNumber(fullBenefit.sumPercent)}</FooterColumn>
            <FooterColumn>{displayNumber(fullBenefit.sumAmount)}</FooterColumn>
          </FooterRow>
          <FooterRow>
            <FooterColumn colSpan={4}>ผลประโยชน์เมื่อครบสัญญา</FooterColumn>
            <FooterColumn>
              {displayNumber(parseInt(fullBenefit.yieldToMaturityPercent))}
            </FooterColumn>
            <FooterColumn>
              {displayNumber(parseInt(fullBenefit.yieldToMaturityAmount))}
            </FooterColumn>
          </FooterRow>
          <FooterRow>
            <FooterColumn colSpan={4}>สรุปผลประโยชน์ตลอดสัญญา</FooterColumn>
            <FooterColumn>
              {displayNumber(fullBenefit.sumYieldAllPercent)}
            </FooterColumn>
            <FooterColumn>
              {displayNumber(fullBenefit.sumYieldAllAmount)}
            </FooterColumn>
          </FooterRow>
          <FooterRow>
            <FooterColumn colSpan={4}>IRR</FooterColumn>
            <FooterColumn colSpan={2}>{fullBenefit.irr}</FooterColumn>
          </FooterRow>
        </TableFooter>
      </MyTable>
    </MoreBenefitContainer>
  )
}

export default LifeTable
