import React from 'react'
import {Checkbox} from 'antd'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, branch, lifecycle, withProps} from 'recompose'
import {withError, withSpidus, withValue} from '../enhances/index'
import classnames from 'classnames'

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      ::after {
        top: 2.5px;
        left: 8px;
        width: 7px;
        height: 17px;
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: #9fd134;
      background-color: #9fd134;
    }
    :after {
      width: 25px;
      height: 25px;
    }
  }
`
const Layout = styled.div`
  display: flex;
  flex-direction: row;
`
const Caption = styled.span``

const validateErrorStyles = css`
  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: red;
    }
  }
`
const CustomCheckboxWithCaption = ({caption, ...checkboxProps}) => (
  <Layout>
    <CustomCheckbox {...checkboxProps} />
    <Caption>{caption}</Caption>
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withError(validateErrorStyles),
  withHandlers({
    onChange: props => e => {
      props.onChange(e.target.checked)
      props.onSelect && props.onSelect(e.target.checked)
    },
  }),
  withProps(props => {
    const className = classnames({
      [props.customStyle]: !!props.customStyle,
    })
    return {...props, ...className, className}
  }),
  withProps(props => ({checked: props.value})),
  lifecycle({
    componentWillReceivedProps(nextProps) {
      if (props.value !== nextProps.value) {
        nextProps.onChange(nextProps.value)
      }
    },
  }),
)

export default enhance(CustomCheckboxWithCaption)
