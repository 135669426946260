import React, {Fragment} from 'react'
import {compose, withProps, withState} from 'recompose'

import {displayNumber, obj2Url} from '../../../../core/helper'
import ModalFavorite from '../../../share/ModalFavorite'
import ModalCatalogue from '../ModalCatalogue'
import KendoIcon from '../../../share/Icon'
import {
  Layout,
  ActionWrapper,
  HeartIcon,
  DescriptionWrapper,
  Body,
  Title,
  Logo,
  CompanyLogo,
  WrapperTitle,
  ProductName,
  CompanyName,
  RowInfo,
  Content,
  ContentRegular,
  IconCheckCircle,
  IconTimesCircle,
  Premium,
  Footer,
  CustomTagA,
  DetailButton,
  InterestButton,
} from './Life'

const Auto = props => (
  <Layout>
    {props.tag ? <Fragment>{props.tag}</Fragment> : null}

    <ActionWrapper>
      {props.SpecialActionComponent ? (
        <props.SpecialActionComponent {...props} />
      ) : (
        <div />
      )}

      <HeartIcon
        onClick={() => {
          props.isLogin
            ? props.onFavorite(
                !props.localFavorite,
                props.data.id,
                'motor',
                props.data.paramsEncode,
              )
            : props.setIsShowModalFavorite(!props.isShowModalFavorite)
        }}
        icon={props.localFavorite ? ['fas', 'heart'] : ['far', 'heart']}
      />
    </ActionWrapper>

    <DescriptionWrapper>
      <Body>
        <Title>
          <Logo>
            <CompanyLogo src={props.data.companyOfficialLogoUrl} />
          </Logo>
          <WrapperTitle>
            <ProductName>{props.data.companyName}</ProductName>
            <CompanyName>
              {`ประกันชั้น ${
                props.data.insureLevel === '20'
                  ? '2+'
                  : props.data.insureLevel === '30'
                  ? '3+'
                  : props.data.insureLevel
              }`}
            </CompanyName>
          </WrapperTitle>
        </Title>

        <RowInfo>
          <Content>เบี้ยประกัน</Content>
          <Premium>
            <span>{displayNumber(parseInt(props.data.amountNet))}</span>
            &nbsp;&nbsp;บาท/ปี
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>คุ้มครองรถยนต์</Content>
          <ContentRegular>
            {`${displayNumber(props.data.cover)} บาท/ครั้ง`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>คุ้มครองชีวิตบุคคลภายนอก</Content>
          <ContentRegular>
            {`${displayNumber(props.data.cover3rdTime)} บาท/ครั้ง`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>คุ้มครองทรัพย์สินบุคคลภายนอก</Content>
          <ContentRegular>
            {`${displayNumber(props.data.cover3rdAsset)} บาท/ครั้ง`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ค่าเสียหายส่วนแรก</Content>
          <ContentRegular>
            {`${displayNumber(props.data.deduct)} บาท/ครั้ง`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ซ่อม</Content>
          <ContentRegular>
            {props.data.typeGarage === '0'
              ? 'อู่'
              : props.data.typeGarage === '1'
              ? 'ศูนย์'
              : ''}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>คุ้มครองสูญหายไฟไหม้</Content>
          <ContentRegular>
            {props.data.coverLostFire > 0 ? (
              <IconCheckCircle icon={['fas', 'check-circle']} />
            ) : (
              <IconTimesCircle icon={['fas', 'times-circle']} />
            )}
          </ContentRegular>
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA href={props.pathUrl} target="_blank">
          <DetailButton id="noon-motor-interest" type="border">
            รายละเอียด
            <KendoIcon icon="table-align-middle-left" />
          </DetailButton>
        </CustomTagA>
        <InterestButton
          id="noon-motor-buy"
          onClick={() => {
            props.setIsShowModal(true)
          }}>
          สนใจซื้อ
          <KendoIcon icon="cart" />
        </InterestButton>
      </Footer>
    </DescriptionWrapper>

    <ModalFavorite
      isShowModal={props.isShowModalFavorite}
      setIsShowModal={props.setIsShowModalFavorite}
      isReload
    />
    <ModalCatalogue
      cover={props.data.cover}
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      category="motor"
      params={props.params}
      id={props.data.id}
      promotionCode={props.data.promotionCode}
      vat={props.data.vat}
      amount={props.data.amount}
      amountNet={props.data.amountNet}
      insureLevel={props.data.insureLevel}
      companyId={props.data.companyId}
      paramsEncode={props.data.paramsEncode}
    />
  </Layout>
)

export const pathUrlAuto = props => {
  return `/smart/motor/${
    props.data.level === undefined ? 'allLevel' : props.data.level
  }/product/${props.data.id}?${obj2Url({
    ...props.params,
    promotionCode: props.data.promotionCode,
    id: props.data.id,
    encodeFlag: props.fromFavoriteProfile ? 'true' : 'false',
    paramsEncode: props.data.paramsEncode,
    vat: props.data.vat,
    amount: props.data.amount,
    amountNet: props.data.amountNet,
    insureLevel: props.data.insureLevel,
    companyId: props.data.companyId,
    cover: props.data.cover,
  })}`
}

const enhance = compose(
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withState('isShowModal', 'setIsShowModal', false),
  withProps(props => {
    return {
      pathUrl: `${pathUrlAuto(props)}`,
    }
  }),
)
export default enhance(Auto)
