import React from 'react'
import PropTypes from 'prop-types'
import {reduxForm} from 'redux-form'
import {compose, lifecycle, withContext} from 'recompose'
import spidusStore from './spidusStore'
import styled from 'react-emotion'
import SpidusConsole from './SpidusConsole'

const MainLayout = styled.div``

const SpidusManager = ({change, handleSubmit, children, ...rest}) => (
  <MainLayout {...rest}>
    {children}
    <SpidusConsole {...{change, handleSubmit}} />
  </MainLayout>
)

const enhance = compose(
  reduxForm({form: spidusStore.STORE_NAME, enableReinitialize: true}),
  withContext(
    {
      configName: PropTypes.string,
    },
    props => ({
      configName: props.configName,
    }),
  ),
  lifecycle({
    componentDidMount() {
      spidusStore.loadAnswers(
        this.props.configName,
        ...spidusStore.getRegistedNames(),
      )
    },
  }),
)

export default enhance(SpidusManager)
