import React from 'react'
import TextInput from '../../share/Field/TextInput'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, branch, withState, withProps} from 'recompose'
import {
  omitProps,
  withDisabled,
  withSpidus,
  withValue,
  withCaption,
  withError,
} from '../enhances/index'
import {Icon} from 'antd'
import classnames from 'classnames'

const validateErrorStyles = css`
  border-color: red;
`
const disabledStyled = css`
  ${'' /* opacity: 0.8; */}
  background-color: #e5e5e5;
  > div {
    > input {
      background-color: #e5e5e5;
    }
  }
`
const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ffd482;
  border-right: none;
`
const SearchIcon = styled(Icon)`
  font-size: 30px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`

const enhanceIcon = compose(
  withProps(props => {
    const className = classnames({
      [props.customIconStyle]: !!props.customIconStyle,
    })
    return {...props, ...className, className}
  }),
)

const EnhanceSearchIcon = enhanceIcon(({icon, onSearchIconClick, ...rest}) => (
  <IconContainer onClick={onSearchIconClick} {...rest}>
    <SearchIcon type={icon} />
  </IconContainer>
))

const TextInputWithIcon = ({
  icon,
  onSearchIconClick,
  customIconStyle,
  ...rest
}) => (
  <Layout>
    <TextInput {...rest} />
    {icon && (
      <EnhanceSearchIcon
        onClick={onSearchIconClick}
        customIconStyle={customIconStyle}
        icon={icon}
      />
    )}
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withState('searchValue', 'setSearchValue', ''),
  withCaption(),
  withDisabled(disabledStyled),
  withError(validateErrorStyles),
  withProps(props => {
    const className = classnames({
      [props.customStyle]: !!props.customStyle,
    })
    return {...props, ...className, className}
  }),
  withHandlers({
    onChange: props => e => {
      props.onChange(e.target.value)
      props.setSearchValue(e.target.value)
    },
    onBlur: props => e => {
      props.onBlur && props.onBlur(props.value)
    },
    onSearchIconClick: props => () => {
      const {value, onSearchIconClicked} = props
      console.log('Search Clicked!!', value)
    },
  }),
  omitProps('onValueChange', 'values', 'setSearchValue'),
)

export default enhance(TextInputWithIcon)
