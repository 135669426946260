import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import styled, { css } from 'react-emotion'
import { navigate } from '@reach/router'
import windowSize from '../../../../core/react-window-size'
import withSEO from '../../../share/withSEO'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { reduxForm, Field as CustomField, formValueSelector } from 'redux-form'

import Button from '../../../share/Button'
import { fadeIn, responsive, breakpoints } from '../../../../core/style'
import Field from '../../../share/Field'
import {
  displayNumber,
  numberParser,
  obj2Url,
  url2Obj,
} from '../../../../core/helper'
import PersonalForm from '../../../share/PersonalFormTQM'
import { savePlanning } from '../../../../ducks/product'
import {
  // ContainerBody,
  ContainerFooter,
  CloseButton,
  SubmitButton,
} from '../../Product/ModalCatalogue'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const HeaderText = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const Body = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  border: 1px solid #66b8fd;
  border-radius: 5px;
  opacity: 1;

  width: 700px;
  margin-top: 30px;
  margin-bottom: 56px;

  ${props =>
    responsive.mobile(css`
      width: 90%;
    `)};
`
const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #66b8fd;
  border-radius: 5px;
  opacity: 1;
  width: 700px;
  margin-top: 30px;
  margin-bottom: 56px;

  ${props =>
    responsive.mobile(css`
      width: 90%;
    `)}; */
`
const ContainerSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
      flex-direction: column;
      min-width: 320px;
      padding: 0 20px;
      margin-top: 15px;
    `)};
`
const ContainerSliderInfo = styled.div`
  width: 200px;

  ${props =>
    responsive.mobile(css`
      margin-top: 15px;

      > div {
        margin-bottom: 0;
        width: 100%;
        padding-left: 10px;
      }
    `)};
`

const HeaderSlider = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderSmall = styled.div`
  font-size: 12px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const ContainerBox = styled.div`
  display: flex;
  width: 785px;
  justify-content: space-between;
  margin-top: 30px;

  ${props =>
    responsive.tablet(css`
      max-width: 420px;
      flex-wrap: wrap;
      justify-content: center;
    `)};

  ${props =>
    responsive.mobile(css`
      max-width: 320px;
      flex-wrap: wrap;
      justify-content: center;
    `)};
`

const BgIcon = styled(FontAwesomeIcon)`
  position: absolute;
  color: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  right: -35px;
  top: 20px;
  font-size: 100px;
`
const Label = styled.div`
  font-size: 16px;
  margin-right: 25px;
  margin: 17px 0;

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      width: 200px;
      margin-bottom: 15px;
    `)};
`

const MobileLabel = styled.div`
  font-size: 16px;
  margin-right: 25px;
  margin: 17px 0;
  display: none;

  ${props =>
    responsive.mobile(css`
      display: flex;
      margin-left: -10px;
      margin-right: 0;
      width: 100%;
      margin-bottom: 15px;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;
`
// const FieldContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 40px;
//   margin: ${props => (props.isSelected ? '0 77px 0 0' : '24px 77px 0 0')};

//   ${props =>
//     responsive.mobile(css`
//       margin: 0;
//       max-width: 320px;
//       padding: 0 20px;
//       margin-top: 15px;
//       margin-bottom: 65px;
//     `)};
// `
const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      margin: 0;
      max-width: 320px;
      padding: 0 20px;
      margin-top: 15px;
      margin-bottom: 65px;
    `)};
`
const Selector = styled(Field)`
  display: flex;
  align-items: center;
  margin: 0;
  width: unset;

  .label {
    display: none;
  }

  .Select-control {
    text-align: center;
  }
  .input-selector-container {
    width: 180px;
    height: 40px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 100%;

      .label {
        display: flex;
        width: 100%;
        margin-right: 0;
        justify-content: flex-start;
        margin-bottom: 15px;
      }

      .input-selector-container {
        width: 200px;
      }
    `)};
`
const Slider = styled(Field)`
  .ant-slider {
    margin: 14px 0 0;
    padding: 0;
    width: 180px;
  }
  .ant-slider-step {
    background-color: #cce7fe !important;
  }
  .ant-slider-handle {
    background-color: #008afc !important;
  }
`

const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: unset;

  margin: 0;
  margin-right: 20px;

  .label {
    display: none;
    margin: 0;
    margin-right: 25px;
    min-width: 0;
  }

  .input-text-container {
    width: unset;
  }

  input {
    width: 180px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      margin: 0;
      flex-direction: column;
      width: 100%;

      > div {
        width: 200px !important;
      }

      .label {
        display: flex;
        width: 100%;
        margin-right: 0;
        justify-content: flex-start;
        margin-bottom: 15px;
      }

      .input-text-container {
        width: 200px;
        input {
          width: 200px;
        }
      }
    `)};
`

const BoxOnce = styled.div`
  display: flex;
  position: relative;
  width: 190px;
  height: 140px;
  border-radius: 5px;
  background-color: ${props =>
    props.isSelected ? props.theme.ORANGE : props.theme.BLUE};
  color: ${props => (props.isSelected ? 'black' : 'white')};
  overflow: hidden;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.ORANGE};
    color: black;
    .umbrella,
    .coins,
    .clock,
    .blind {
      color: rgba(178, 117, 0, 0.3);
    }
  }
  .umbrella {
    left: 120px;
  }
  .man-with-cane {
    left: 120px;
  }

  ${props =>
    responsive.tablet(css`
      margin: 7.5px;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 140px;
      margin: 7.5px;
      :hover {
        background-color: ${props.isSelected
        ? props.theme.ORANGE
        : props.theme.BLUE};
        color: ${props.isSelected ? 'black' : 'white'};
      }
    `)};
`
const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  animation: ${fadeIn} 2s;
`
const CustomContainerPersonalForm = styled(PersonalForm)`
  flex-direction: column;
  margin-top: 32px;

  > div {
    padding-left: 59px;

    .css-c5t3ze--PersonalFormTQM--AgeContainer {
      margin-bottom: 12px;
    }

    .css-1ma7f72--index--InputContainer--index--InputContainer--style--responsive {
      margin-left: 200px;
    }

    > div {
      margin-bottom: 18px;
      :first-child {
        > div {
          margin-bottom: unset;
          > div {
            width: 180px;
            > div {
              width: 90px !important;
            }
          }
        }
      }

      :nth-child(2) {
        margin-bottom: unset;
        > div {
          margin-bottom: 10px;
          width: 505px !important ;
          > div {
            > input {
              width: 180px;
            }
          }
        }
      }
    }
  }

  .property {
    margin-bottom: 35px;
  }

  ${props =>
    responsive.mobile(css`
      margin-top: 30px;

      > div {
        > div {
          :first-child {
            > span {
              margin-left: -25px;
            }
            > div {
              margin-left: -60px;
              width: 200px;
              > div {
                > div {
                  width: 100px !important;
                }

                width: 200px;
              }
            }
          }

          :nth-child(2) {
            > div {
              width: 100% !important;
              > span {
                margin-left: -25px;
              }
              > div {
                margin-left: -60px;
                width: 200px;

                > input {
                  width: 200px;
                }
              }
            }
          }
        }
      }

      .property {
        margin-bottom: 0;
      }

      .gender {
        margin-bottom: 20px;
      }
    `)};
`
const Info = styled.div`
  font-size: 12px;
`
const Unit = styled.span`
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const CustomButton = styled(Button)`
  width: 170px;
  height: 38px;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 28px;
  color: #ffffff;
  background-color: #66b8fd;

  :hover {
    color: #ffffff;
    background-color: #008afc;

    .icon {
      color: #ffffff;
    }
  }

  ${props =>
    responsive.mobile(css`
      margin-top: 0;
    `)};
`
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 60px;

  ${props =>
    responsive.mobile(css`
      .css-xao367--index--Label--index--Label--style--responsive {
        padding-left: 5px;
        margin-bottom: 0;
        margin-top: 10px;
      }
    `)}
`
const RowLayout = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    min-width: 323px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      > div {
        margin-bottom: 20px;
      }

      > div:first-child {
        min-width: unset;
      }
    `)}
`

const ContainerBody = styled.div`
  width: 100%;

  ${props =>
    props.fromModal &&
    css`
      padding: 0px 60px;
    `}
`
const WLoptions = [
  { label: 'เบี้ยประกันที่สนใจ', value: 'premium' },
  { label: 'ทุนประกันที่สนใจ', value: 'sum_insured' },
]

const TMoptions = [
  { label: 'เบี้ยประกันที่สนใจ', value: 'premium' },
  { label: 'ทุนประกันที่สนใจ', value: 'sum_insured' },
]

const ANoptions = [
  { label: 'เบี้ยประกันที่สนใจ', value: 'premium' },
  { label: 'บำนาญที่สนใจ', value: 'annuity_refund' },
]
const SVoptions = [
  { label: 'เบี้ยประกันที่สนใจ', value: 'premium' },
  { label: 'เงินคืนทั้งหมดที่สนใจ', value: 'annuity_refund_total' },
  { label: 'ทุนประกันที่สนใจ', value: 'sum_insured' },
]

const LayoutIntermediate = props => (
  <Layout>
    <HeaderText>กรุณากรอกรายละเอียดเพื่อค้นหาแบบประกัน</HeaderText>
    <Body>
      <LifeIntermediate {...props} />
    </Body>
  </Layout>
)

export const LifeIntermediate = ({
  isSelected,
  change,
  handleSubmit,
  closeModal = () => { },
  savePlanning,
  companyIds,
  selectedSearchBy,
  windowWidth,
  onSubmit,
  formType,
  weightType,
  className,
  isEdit,
  setIsShowModal,
  fromModal,
}) => (
    // <Layout className={className}>
    <Container
      onSubmit={
        onSubmit ||
        handleSubmit(value => {
          closeModal()
          savePlanning(
            {
              age: value.age,
              gender: value.gender,
              user_level: 'intermediate',
              sub_types: value.insuranceType,
              focus_value: value.weightType,
              coverage_type: value.searchBy,
              sum_insurance: value.searchValue,
            },
            'intermediate',
            'life',
          )
          if (formType === 'package') {
            window.open(
              `/smart/package?${obj2Url({
                ...value,
                sortBy: 'min_premium',
                noonStar: 1,
                companyIds: companyIds,
              })}`,
              '_self',
            )
          } else {
            window.open(
              `/smart/life/intermediate/product?${obj2Url({
                ...value,
                sortBy: 'min_premium',
                noonStar: 1,
                companyIds: companyIds,
              })}`,
              '_self',
            )
          }
        })
      }>
      <ContainerBody fromModal={fromModal}>
        <CustomContainerPersonalForm />
        {formType === 'package' && (
          <SearchContainer>
            <RowLayout>
              <Label>
                {windowWidth < breakpoints.mobile
                  ? `ทุนประกันที่สนใจ (บาท)`
                  : `ทุนประกันชีวิตสัญญาหลัก`}
              </Label>

              <FieldContainer>
                <TextInput
                  name="searchValue"
                  type="text"
                  placeholder="0"
                  format={displayNumber}
                  parse={numberParser}
                  selectedSearchBy={selectedSearchBy}
                />
                <Unit style={{ margin: '0px', whiteSpace: 'nowrap' }}>บาท</Unit>
              </FieldContainer>
            </RowLayout>
          </SearchContainer>
        )}
        {formType !== 'package' && (
          <SearchContainer>
            <RowLayout>
              {formType !== 'term' && <Label>เน้นด้าน</Label>}
              {formType !== 'term' && (
                <ContainerSliderInfo>
                  <HeaderSlider>
                    <HeaderSmall>ความคุ้มค่า</HeaderSmall>
                    <HeaderSmall>ความคุ้มครอง</HeaderSmall>
                  </HeaderSlider>
                  <Slider type="slider" name="weightType" min={1} max={5} />
                </ContainerSliderInfo>
              )}
            </RowLayout>

            <RowLayout>
              <Label>เลือกความคุ้มครองจาก</Label>
              <FieldContainer isSelected={formType === 'term'}>
                <Selector
                  name="searchBy"
                  type="selector"
                  options={
                    formType === 'whole_life'
                      ? WLoptions
                      : formType === 'saving'
                        ? SVoptions
                        : formType === 'term'
                          ? TMoptions
                          : ANoptions
                  }
                  themeColor="orange"
                />
              </FieldContainer>
            </RowLayout>

            <RowLayout>
              <Label>
                {windowWidth < breakpoints.mobile
                  ? selectedSearchBy === 'premium' ||
                    selectedSearchBy === 'annuity_refund'
                    ? `จำนวนเงิน (บาท/ปี)`
                    : `จำนวนเงิน (บาท)`
                  : `จำนวนเงิน`}
              </Label>

              <FieldContainer>
                <TextInput
                  name="searchValue"
                  type="text"
                  placeholder="0"
                  format={displayNumber}
                  parse={numberParser}
                  selectedSearchBy={selectedSearchBy}
                />
                <Unit style={{ margin: '0px', whiteSpace: 'nowrap' }}>
                  {selectedSearchBy === 'premium' ||
                    selectedSearchBy === 'annuity_refund'
                    ? `บาท / ปี`
                    : `บาท`}
                </Unit>
              </FieldContainer>
            </RowLayout>
          </SearchContainer>
        )}
      </ContainerBody>
      <ContainerFooter>
        {isEdit ? (
          <Fragment>
            <CloseButton htmlType="submit" onClick={() => closeModal()}>
              ย้อนกลับ
          </CloseButton>
            <SubmitButton htmlType="submit" icons={['fa', 'arrow-right']}>
              เลือกแบบประกัน
          </SubmitButton>
          </Fragment>
        ) : (
            <CustomButton htmlType="submit" icons={['fa', 'arrow-right']}>
              เลือกแบบประกัน
        </CustomButton>
          )}
      </ContainerFooter>
    </Container>
    // </Layout>
  )

// export const LifeIntermediate = ({
//   isSelected,
//   change,
//   handleSubmit,
//   closeModal = () => {},
//   savePlanning,
//   companyIds,
//   selectedSearchBy,
//   windowWidth,
//   onSubmit,
//   formType,
//   weightType,
//   className,
// }) => (
//   <Layout className={className}>
//     <HeaderText>กรุณากรอกรายละเอียดเพื่อค้นหาแบบประกัน</HeaderText>
//     <Container
//       onSubmit={
//         onSubmit ||
//         handleSubmit(value => {
//           closeModal()
//           savePlanning(
//             {
//               age: value.age,
//               gender: value.gender,
//               user_level: 'intermediate',
//               sub_types: value.insuranceType,
//               focus_value: value.weightType,
//               coverage_type: value.searchBy,
//               sum_insurance: value.searchValue,
//             },
//             'intermediate',
//             'life',
//           )
//           if (formType === 'package') {
//             window.open(
//               `/smart/package?${obj2Url({
//                 ...value,
//                 sortBy: 'min_premium',
//                 noonStar: 3,
//                 companyIds: companyIds,
//               })}`,
//               '_self',
//             )
//           } else {
//             window.open(
//               `/smart/life/intermediate/product?${obj2Url({
//                 ...value,
//                 sortBy: 'min_premium',
//                 noonStar: 3,
//                 companyIds: companyIds,
//               })}`,
//               '_self',
//             )
//           }
//         })
//       }>
//       <CustomContainerPersonalForm />
//       {formType === 'package' && (
//         <SearchContainer>
//           <RowLayout>
//             <Label>
//               {windowWidth < breakpoints.mobile
//                 ? `ทุนประกันที่สนใจ (บาท)`
//                 : `ทุนประกันชีวิตสัญญาหลัก`}
//             </Label>

//             <FieldContainer>
//               <TextInput
//                 name="searchValue"
//                 type="text"
//                 placeholder="0"
//                 format={displayNumber}
//                 parse={numberParser}
//                 selectedSearchBy={selectedSearchBy}
//               />
//               <Unit style={{margin: '0px', whiteSpace: 'nowrap'}}>บาท</Unit>
//             </FieldContainer>
//           </RowLayout>
//         </SearchContainer>
//       )}

//       {/* <SearchContainer>
//           <LabelsContainer>
//             <Label>
//               {windowWidth < breakpoints.mobile
//                 ? `ทุนประกันที่สนใจ (บาท)`
//                 : `ทุนประกันชีวิตสัญญาหลัก`}
//             </Label>
//           </LabelsContainer>

//           <FieldsContainer>
//             <FieldContainer>
//               <TextInput
//                 name="searchValue"
//                 label={
//                   windowWidth < breakpoints.mobile
//                     ? `ทุนประกันที่สนใจ (บาท)`
//                     : `ทุนประกันชีวิตสัญญาหลัก`
//                 }
//                 type="text"
//                 placeholder="0"
//                 format={displayNumber}
//                 parse={numberParser}
//                 selectedSearchBy={selectedSearchBy}
//                 disable
//               />
//               <Unit>บาท</Unit>
//             </FieldContainer>
//           </FieldsContainer>
//         </SearchContainer> */}

//       {formType !== 'package' && (
//         <SearchContainer>
//           <RowLayout>
//             {formType !== 'term' && <Label>เน้นด้าน</Label>}
//             {formType !== 'term' && (
//               <ContainerSliderInfo>
//                 <HeaderSlider>
//                   <HeaderSmall>ความคุ้มค่า</HeaderSmall>
//                   <HeaderSmall>ความคุ้มครอง</HeaderSmall>
//                 </HeaderSlider>
//                 <Slider type="slider" name="weightType" min={1} max={5} />
//               </ContainerSliderInfo>
//             )}
//           </RowLayout>

//           <RowLayout>
//             <Label>เลือกความคุ้มครองจาก</Label>
//             <FieldContainer isSelected={formType === 'term'}>
//               <Selector
//                 name="searchBy"
//                 type="selector"
//                 options={
//                   formType === 'whole_life'
//                     ? WLoptions
//                     : formType === 'saving'
//                     ? SVoptions
//                     : formType === 'term'
//                     ? TMoptions
//                     : ANoptions
//                 }
//                 themeColor="orange"
//               />
//             </FieldContainer>
//           </RowLayout>

//           <RowLayout>
//             <Label>
//               {windowWidth < breakpoints.mobile
//                 ? selectedSearchBy === 'premium' ||
//                   selectedSearchBy === 'annuity_refund'
//                   ? `ทุนประกันที่สนใจ (บาท/ปี)`
//                   : `ทุนประกันที่สนใจ (บาท)`
//                 : `ทุนประกันที่สนใจ`}
//             </Label>

//             <FieldContainer>
//               <TextInput
//                 name="searchValue"
//                 type="text"
//                 placeholder="0"
//                 format={displayNumber}
//                 parse={numberParser}
//                 selectedSearchBy={selectedSearchBy}
//               />
//               <Unit style={{margin: '0px', whiteSpace: 'nowrap'}}>
//                 {selectedSearchBy === 'premium' ||
//                 selectedSearchBy === 'annuity_refund'
//                   ? `บาท / ปี`
//                   : `บาท`}
//               </Unit>
//             </FieldContainer>
//           </RowLayout>

//           {/* <LabelsContainer>
//             {formType !== 'term' && <Label>เน้นด้าน</Label>}
//             <Label>เลือกความคุ้มครองจาก</Label>
//             <Label>
//               {windowWidth < breakpoints.mobile
//                 ? selectedSearchBy === 'premium' ||
//                   selectedSearchBy === 'annuity_refund'
//                   ? `ทุนประกันที่สนใจ (บาท/ปี)`
//                   : `ทุนประกันที่สนใจ (บาท)`
//                 : `ทุนประกันที่สนใจ`}
//             </Label>
//           </LabelsContainer>

//           <FieldsContainer>
//             {formType !== 'term' && (
//               <ContainerSliderInfo>
//                 <MobileLabel>เน้นด้าน</MobileLabel>
//                 <HeaderSlider>
//                   <HeaderSmall>ความคุ้มค่า</HeaderSmall>
//                   <HeaderSmall>ความคุ้มครอง</HeaderSmall>
//                 </HeaderSlider>
//                 <Slider type="slider" name="weightType" min={1} max={5} />
//               </ContainerSliderInfo>
//             )}

//             <FieldContainer isSelected={formType === 'term'}>
//               <Selector
//                 name="searchBy"
//                 label="เลือกความคุ้มครองจาก"
//                 type="selector"
//                 options={
//                   formType === 'whole_life'
//                     ? WLoptions
//                     : formType === 'saving'
//                     ? SVoptions
//                     : formType === 'term'
//                     ? TMoptions
//                     : ANoptions
//                 }
//                 themeColor="orange"
//               />
//             </FieldContainer>
//             <FieldContainer>
//               <TextInput
//                 name="searchValue"
//                 label={
//                   windowWidth < breakpoints.mobile
//                     ? selectedSearchBy === 'premium' ||
//                       selectedSearchBy === 'annuity_refund'
//                       ? `ทุนประกันที่สนใจ (บาท/ปี)`
//                       : `ทุนประกันที่สนใจ (บาท)`
//                     : `ทุนประกันที่สนใจ`
//                 }
//                 type="text"
//                 placeholder="0"
//                 format={displayNumber}
//                 parse={numberParser}
//                 selectedSearchBy={selectedSearchBy}
//               />
//               <Unit>
//                 {selectedSearchBy === 'premium' ||
//                 selectedSearchBy === 'annuity_refund'
//                   ? `บาท / ปี`
//                   : `บาท`}
//               </Unit>
//             </FieldContainer>
//           </FieldsContainer> */}
//         </SearchContainer>
//       )}

//       {/* {formType !== 'term' && (
//         <ContainerSlider>
//           <Label>เน้นด้าน</Label>
//           <ContainerSliderInfo>
//             <HeaderSlider>
//               <HeaderSmall>ความคุ้มค่า</HeaderSmall>
//               <HeaderSmall>ความคุ้มครอง</HeaderSmall>
//             </HeaderSlider>
//             <Slider type="slider" name="weightType" min={1} max={5} />
//           </ContainerSliderInfo>
//         </ContainerSlider>
//       )} */}
//       {/* <FieldContainer isSelected={formType === 'term'}>
//         <Selector
//           // label="เลือกความคุ้มครองจาก"
//           name="searchBy"
//           type="selector"
//           options={
//             formType === 'whole_life'
//               ? WLoptions
//               : formType === 'saving'
//               ? SVoptions
//               : formType === 'term'
//               ? TMoptions
//               : ANoptions
//           }
//           themeColor="orange"
//         />
//       </FieldContainer>
//       <FieldContainer>
//         <TextInput
//           name="searchValue"
//           // label={
//           //   windowWidth < breakpoints.mobile
//           //     ? selectedSearchBy === 'premium' ||
//           //       selectedSearchBy === 'annuity_refund'
//           //       ? `ทุนประกันที่สนใจ (บาท/ปี)`
//           //       : `ทุนประกันที่สนใจ (บาท)`
//           //     : `ทุนประกันที่สนใจ`
//           // }
//           type="text"
//           placeholder="0"
//           format={displayNumber}
//           parse={numberParser}
//           selectedSearchBy={selectedSearchBy}
//         />
//         <Unit>
//           {selectedSearchBy === 'premium' ||
//           selectedSearchBy === 'annuity_refund'
//             ? `บาท / ปี`
//             : `บาท`}
//         </Unit>
//       </FieldContainer> */}
//       <CustomButton htmlType="submit" icons={['fa', 'arrow-right']}>
//         เลือกแบบประกัน
//       </CustomButton>
//     </Container>
//   </Layout>
// )

const selector = formValueSelector('Planning')

const switchWeigthType = type => {
  switch (type) {
    case 'whole_life':
      return 5
    case 'saving':
      return 1
    case 'term':
      return 5
    case 'annuity':
      return 3
  }
}

const enhancer = compose(
  withSEO(
    'noon Smart Life - ค้นหาประกันชีวิตที่เหมาะสมสำหรับคุณ',
    'noon Smart-Life ค้นหาแบบประกันชีวิตง่ายๆ รายละเอียดครบถ้วน ตามวัตถุประสงค์ที่คุณต้องการ หมดปัญหากับข้อมูลที่ไม่ครบถ้วน เรามีระบบที่ช่วยบอกรายละเอียดเพื่อใช้เป็นเครื่องมือในการหาข้อมูล ก่อนการตัดสินใจซื้อประกันชีวิต เพื่อความคุ้มค่าและความคุ้มครองที่สูงสุด',
    'เครื่องมือค้นหาประกันชีวิต วางแผนประกันชีวิต เลือกซื้อประกันชีวิต,ประกันชีวิต,สะสมทรัพย์,ตลอดชีพ,บำนาญ,ชั่วระยะเวลา,เปรียบเทียบประกันชีวิต',
  ),
  connect(
    (state, props) => ({
      initialValues: {
        searchBy: 'premium',
        // url2Obj(props.location.search).insuranceType === 'whole_life' ||
        // url2Obj(props.location.search).insuranceType === 'term'
        //   ? 'sum_insured'
        //   : url2Obj(props.location.search).insuranceType === 'annuity'
        //   ? 'annuity_refund'
        //   : url2Obj(props.location.search).insuranceType === 'saving'
        //   ? 'annuity_refund_total'
        //   : 'sum_insured',
        gender: url2Obj(props.location.search).gender,
        age: url2Obj(props.location.search).age,
        weightType:
          url2Obj(props.location.search).weightType ||
          switchWeigthType(url2Obj(props.location.search).insuranceType) ||
          props.weightType ||
          5,
        searchValue: url2Obj(props.location.search).searchValue || '',
        insuranceType:
          url2Obj(props.location.search).insuranceType || 'whole_life',
      },
      companyIds: url2Obj(props.location.search).companyIds,
      isSelected: selector(state, 'insuranceType'),
      selectedSearchBy: selector(state, 'searchBy'),
    }),
    { savePlanning },
  ),
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default windowSize(enhancer(LayoutIntermediate))
