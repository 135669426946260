import React from 'react'
import Button from '../../share/Button'
import {compose, withHandlers, withState, withProps} from 'recompose'
import {omitProps} from '../enhances'

export const enhancer = compose(
  withState('eventLoading', 'setEventLoading', false),
  withHandlers({
    onClick: ({onClick, setEventLoading}) => async e => {
      if (onClick) {
        setEventLoading(true)

        try {
          await onClick(e)
        } catch (e) {
          // console.log(e)
        }
        setEventLoading(false)
      }
    },
  }),
  withProps(({loading, eventLoading}) => ({
    loading: loading || eventLoading,
  })),
  omitProps('setEventLoading', 'eventLoading'),
)

export default enhancer(Button)
