import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {Link} from '@reach/router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '../share/Button'
import logo from '../../static/images/Noon-Logo-PNG-512.png'
import userIcon from '../../static/images/avatar-02.svg'
import {login, logout} from '../../ducks/auth'
import Notification from './Notifications'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  padding: 0 50px;
  @media (max-width: 950px) {
    display: none;
  }
`
const Logo = styled.img`
  width: 150px;
  height: 150px;
  transform: translateX(-24px);
  cursor: pointer;
`
const MenuContent = styled.div`
  font-size: 16px;
  margin: 0 8px;
  cursor: pointer;

  &.login {
    margin: 0;
  }
`
const LoginAndRegisterPart = styled(Button)`
  max-width: 150px;
  height: 30px;
  transition: 0.5s;
  font-size: 14px;
  :hover {
    max-width: 183px;
    background-color: ${props =>
      props.type === 'border' ? 'white' : props.theme.ORANGE};
  }
  .hover {
    display: none;
    opacity: 0;
    font-size: 14px;
  }
  :hover {
    .hover {
      font-size: 14px;
      display: block;
      opacity: 1;
      transition: all 0.5s;
    }
    .active {
      font-size: 14px;
      display: none;
      opacity: 0;
      transition: all 0.5s;
    }
  }
  .hover,
  &:hover {
    transition: all 0.5s;
  }
`
const Line = styled.div`
  margin: 0 5px;
  padding-bottom: 1px;
`
const Ul = styled.ul``
const Li = styled.li``
const InnerUl = styled.ul``
const InnerLi = styled.li``
const Div = styled.div``
const Nav = styled.nav``
const Span = styled.span``
const UserContainer = styled.div`
  .user-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .user-nav ul:before,
  .user-nav ul:after {
    content: '';
    display: table;
  }
  .user-nav ul:after {
    clear: both;
  }
  .user-nav ul {
    li.user-menu {
      float: left;
      position: relative;
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 50px;
      @media (max-width: 1024px) {
        padding: 0 5px;
      }
    }
  }
  .user-menu {
    justify-content: center;
  }
  .user-menu-link {
    overflow: hidden;
    div {
      display: flex;
    }
  }
  .user-menu-list {
    position: relative;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    max-width: 200px;
    width: 100px;
    height: 30px;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    :before,
    :after {
      position: absolute;
      max-width: 200px;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
    }
    :before {
      bottom: 100%;
      display: block;
      height: 3px;
      width: 100%;
      content: '';
      background-color: #ffb933;
    }
    :after {
      padding: 0.3em 0;
      position: absolute;
      bottom: 100%;
      left: 0;
      font-size: 14px;
      content: attr(data-hover);
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      width: 100px;
    }
  }
  .user-nav a {
    display: block;
    line-height: 1.2em;
    color: black;
  }
  .user-nav a:hover,
  .user-nav a:focus {
    text-decoration: none;
  }
  .user-nav li ul {
    background: #ffffff;
    box-shadow: 1px 1px 1px #00000029;
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;
  }
  .user-nav li ul li.user-sub-list-li a,
  .user-nav li ul li.user-sub-list-li .user-link-list {
    width: 150px;
    padding: 0 20px;
  }
  .user-nav li ul li.user-sub-list-li a,
  .user-nav li ul li.user-sub-list-li .user-link-list {
    border: none;
  }
  .user-nav3 ul > li:hover {
    transition: all 0.35s ease;
    ul li.user-sub-list-li a,
    ul li.user-sub-list-li .user-link-list {
      transition: all 0.35s ease;
      opacity: 1;
      overflow: visible;
      height: 50px;
      align-items: center;
      display: flex;
      width: 150px;
      float: left;
      position: relative;
      align-items: center;
      padding: 0 20px;
    }
    .user-menu-list {
      transform: translateY(100%);
    }
  }
  .user-nav3 ul > li:hover ul li.user-sub-list-li {
    .user-link-list:hover {
      background: #ffd900;
    }
    .user-last-child {
      border-radius: 0px 0px 5px 5px;
    }
  }
  .user-nav3 li.user-menu ul {
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 80;
  }
  .user-nav3 li.user-menu ul li.user-sub-list-li a,
  .user-nav3 li.user-menu ul li.user-sub-list-li .user-link-list {
    overflow: hidden;
    height: 0;
    opacity: 0;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
`
const Username = styled.div`
  margin-left: 10px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`
const UserIcon = styled.img`
  margin-left: 12px;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  cursor: pointer;
`
const CustomLink = styled(Link)`
  font-size: 16px;
  color: black;
  font-weight: 400;
  :active,
  :hover {
    color: black;
    font-weight: 600;
  }
  :focus {
    color: black;
    text-decoration: none;
  }
`
const WrapperMenu = styled.div`
  display: flex;
  font-size: 16px;
  margin: 0 8px;
  cursor: pointer;
  align-items: center;
  &.login {
    margin: 0;
  }
  .nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .nav ul:before,
  .nav ul:after {
    content: '';
    display: table;
  }
  .nav ul:after {
    clear: both;
  }
  .nav ul {
    li.menu {
      float: left;
      position: relative;
      height: 80px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      @media (max-width: 1200px) {
        padding: 0 10px;
      }

      @media (max-width: 1020px) {
        padding: 0 0;
      }
    }
  }
  .menu {
    justify-content: center;
  }
  .menu-link {
    overflow: hidden;
  }
  .menu-list {
    position: relative;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    max-width: 200px;
    width: 100px;
    height: 30px;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    :before,
    :after {
      position: absolute;
      max-width: 200px;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
    }
    :before {
      bottom: 100%;
      display: block;
      height: 3px;
      width: 100%;
      content: '';
      background-color: #ffb933;
    }
    :after {
      padding: 0.3em 0;
      position: absolute;
      bottom: 100%;
      left: 0;
      font-size: 14px;
      content: attr(data-hover);
      white-space: nowrap;
      width: 100px;
    }
  }
  .nav a {
    display: block;
    line-height: 1.2em;
    color: black;
  }
  .nav a:hover,
  .nav a:focus {
    text-decoration: none;
  }
  .nav li ul {
    background: #ffffff;
    box-shadow: 1px 1px 1px #00000029;
    border-radius: 0px 0px 5px 5px;
  }
  .nav li ul li.sub-list-li a {
    width: 150px;
    padding: 0 20px;
  }
  .nav li ul li.sub-list-li a {
    border: none;
  }
  .nav3 ul > li:hover {
    transition: all 0.35s ease;
    ul li.sub-list-li a {
      transition: all 0.35s ease;
      opacity: 1;
      overflow: visible;
      height: 50px;
      align-items: center;
      display: flex;
      width: 150px;
      float: left;
      position: relative;
      align-items: center;
      padding: 0 20px;
    }
    .menu-list {
      transform: translateY(100%);
    }
  }
  .nav3 ul > li:hover ul li.sub-list-li {
    a:hover {
      background: #ffd900;
    }
    a:hover :last-child {
      border-radius: 0px 0px 5px 5px;
    }
  }
  .nav3 li.menu ul {
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 80;
  }
  .nav3 li.menu ul li.sub-list-li a {
    overflow: hidden;
    height: 0;
    opacity: 0;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
`
const FavoriteIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: black;
`
const LinkFav = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  margin-right: 0;
`
const NonLogged = () => (
  <LoginAndRegisterPart icons={['fa', 'user-circle']}>
    <CustomLink id="menu-login" to="/authorize/login">
      <MenuContent className="login">
        <Span className="active">เข้าสู่ระบบ</Span>
        <Span className="hover">LOG IN</Span>
      </MenuContent>
    </CustomLink>
    <Line>|</Line>
    <CustomLink id="menu-register" to="/authorize/register">
      <MenuContent className="login">
        <Span className="active">สมัคร</Span>
        <Span className="hover">REGISTER</Span>
      </MenuContent>
    </CustomLink>
  </LoginAndRegisterPart>
)
const Logged = ({logout, userName, user, fullName}) => (
  <>
    <UserContainer>
      <Nav className="user-nav user-nav3">
        <Ul className="user-nav-slide">
          <Li key="profile" className="user-menu">
            <Link to="/profile" className="user-menu-link">
              <Div data-hover={userName || fullName}>
                <UserIcon src={user.avatar || userIcon} />
                <Username>{userName || fullName}</Username>
              </Div>
            </Link>
            <InnerUl>
              <InnerLi className="user-sub-list-li" key={`sub-profile`}>
                <Link to="/profile/user-profile" className="user-link-list">
                  ข้อมูลส่วนตัว
                </Link>
              </InnerLi>
              <InnerLi className="user-sub-list-li" key={`sub-logout`}>
                <Div
                  className="user-link-list user-last-child"
                  onClick={() => logout()}>
                  <Span>ออกจากระบบ</Span>
                </Div>
              </InnerLi>
            </InnerUl>
          </Li>
        </Ul>
      </Nav>
    </UserContainer>
    <Notification />
    <LinkFav to="/profile/favorite">
      <FavoriteIcon icon={['far', 'heart']} />
    </LinkFav>
  </>
)

const Desktop = props => {
  const {menus, isLogin, logout, userName, user, fullName} = props
  return (
    <Wrapper>
      <Link to="/">
        <Logo src={logo} />
      </Link>
      <WrapperMenu>
        <Nav className="nav nav3">
          <Ul className="nav-slide">
            {menus.map((menu, index) => (
              <Li key={index} className="menu">
                <Link
                  id={menu.id}
                  to={menu.link}
                  className="menu-link"
                  data-hover={menu.hover}>
                  <Div
                    className="menu-list"
                    data-hover={menu.hover}
                    data-after={menu.title}>
                    {menu.title}
                  </Div>
                </Link>
                {menu.submenu && (
                  <InnerUl>
                    {menu.submenu.map((sub, index) => (
                      <InnerLi className="sub-list-li" key={`sub-${index}`}>
                        <Link id={sub.id} to={sub.link}>
                          {sub.title}
                        </Link>
                      </InnerLi>
                    ))}
                  </InnerUl>
                )}
              </Li>
            ))}
          </Ul>
        </Nav>
        {!isLogin ? (
          <NonLogged />
        ) : (
          <Logged
            logout={logout}
            userName={userName}
            user={user}
            fullName={fullName}
          />
        )}
      </WrapperMenu>
    </Wrapper>
  )
}

const enhancer = compose(
  connect(
    state => ({
      isLogin: state.auth.isLogin,
      noOpacityHeader: state.product.noOpacityHeader,
      isNoHeader: state.notFound.isNoHeader,
      userName: state.auth.user.firstNameTh,
      fullName: state.auth.user.fullName,
      user: state.auth.user,
    }),
    {login, logout},
  ),
)

export default enhancer(Desktop)
