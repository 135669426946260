import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState, withHandlers} from 'recompose'
import NoonLogo from '../../../../../static/images/tqm-life/tqm_life_thai@2x.png'
import Circle from '../../../../share/Circle'
import AppearanceComponent from '../../../../share/AppearanceComponent'
import Button from '../../../../share/Button'
import Modal from '../../../../share/Modal'
import Tooltip from '../../../../share/Tooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Auth from '../../../../Auth'
import {responsive} from '../../../../../core/style'
// import FooterDetail from '../FooterDetail'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
const ContainerNoonInsight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`
const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${props => props.theme.BLUE};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    /* max-width: unset; */
    min-width: fit-content;
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }
`

const Logo = styled.img`
  margin-top: 30px;
  width: 130px;
`
const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`

const CustomBigCircle = styled(Circle)`
  .progress {
    width: 220px;
    height: 220px;
  }
  .score {
    font-size: 40px;
    .topic {
      font-size: 12px;
      font-weight: normal;
    }
  }
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: #7f7f7f;
  right: 0;
  margin-left: 10px;
`

const CustomSmallCircle = styled(Circle)`
  margin: 47px 24px;
  .progress {
    width: 160px;
    height: 160px;
    .progress__value {
      stroke: ${props => props.theme.EXTRALIGHTBLUE};
    }
  }
  .score {
    font-size: 30px;
    color: ${props => props.theme.EXTRALIGHTBLUE};
    .topic {
      font-size: 12px;
      font-weight: normal;
    }
  }
`

const ContainerNoonInsightNoLogin = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
`
const BlurNoonInSight = styled.div`
  position: absolute;
  width: 220px;
  height: 220px;
  top: 6px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.4) 10%,
    rgba(255, 255, 255, 0.6) 20.5%,
    rgba(255, 255, 255, 1) 48.5%,
    rgba(255, 255, 255, 1) 100%
  );
`

const CustomButton = styled(Button)`
  position: absolute;
  z-index: 2;
  top: 50%;

  min-width: 200px;
  font-size: 12px;
`

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: ${props =>
      props.category === 'login'
        ? '20px 0'
        : props.category === 'register'
        ? '0 0 60px'
        : '20px 0 60px'};
  }
`
const ContainerEdcr = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`
const EdcrGod = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${props =>
    responsive.mobile(css`
      width: 220px;
    `)};
`

const LineEdcr = styled.div`
  width: 470px;
  height: 1px;
  background-color: ${props => props.theme.EXTRALIGHTORANGE};
  margin: 5px 0 15px 0;
  ${props =>
    responsive.mobile(css`
      width: 270px;
    `)};
`

const TopicEdcr = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    responsive.mobile(css`
      font-size: 14px;
    `)};
`
const InfoEdcr = styled.div`
  ${props =>
    responsive.mobile(css`
      font-size: 14px;
    `)};
`

const NoonInSight = ({
  count1,
  count2,
  count3,
  count4,
  count5,
  runner,
  setRunner,
  setNewCount,
  state,
  setState,
  isLogin,
  isShowModal,
  setIsShowModal,
  category,
  setCategory,
  item,
  type,
}) => (
  <Container>
    {isLogin ? (
      <ContainerNoonInsight>
        <Logo src={NoonLogo} />
        <Header>INSIGHT</Header>
      </ContainerNoonInsight>
    ) : (
      <Fragment />
    )}
    {isLogin ? (
      <AppearanceComponent
        componentDidAppear={() => {
          if (state === 0) {
            setNewCount()
            setState(1)
          }
        }}
        componentDidDisappear={() => {
          clearInterval(runner)
          setRunner({id: ''})
        }}>
        <CustomBigCircle
          count={count1}
          radius={60 * 6.28}
          score={`${count1 === '10.0' ? Math.round(count1) : count1}/10`}
          topic="NOON SCORE"
        />
        {/* <ContainerRow>
          <CustomSmallCircle
            count={count2}
            radius={60 * 6.28}
            score={`${count2 === '10.0' ? Math.round(count2) : count2}/10`}
            topic="ความน่าเชื่อถือ<br/>ของบริษัทประกัน"
          />
          <CustomSmallCircle
            count={count3}
            radius={60 * 6.28}
            score={`${count3 === '10.0' ? Math.round(count3) : count3}/10`}
            topic="การบริการ<br/>ของตัวแทน"
          />
          <CustomSmallCircle
            count={count4 / 10}
            radius={60 * 6.28}
            score={`${count4 === '100.0' ? Math.round(count4) : count4}%`}
            topic="อัตราการเคลม<br/>สินไหม"
          />
          <CustomSmallCircle
            count={count5 / 10}
            radius={60 * 6.28}
            score={`${count5 === '100.0' ? Math.round(count5) : count5}%`}
            topic="อัตราการปฏิเสธ<br/>การรับประกัน"
          />
        </ContainerRow> */}
        <ContainerEdcr>
          <EdcrGod>
            <TopicEdcr>
              E[DCR]
              <CustomTooltip
                placement="right"
                title={'อัตราส่วนความคุ้มครองคาดหวังเทียบอัตรามรณะ'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.edcr || '-'}</InfoEdcr>
          </EdcrGod>
          <LineEdcr />
          <EdcrGod>
            <TopicEdcr>
              E[EDR] Percentile
              <CustomTooltip
                placement="right"
                title={'เทียบกับแบบประกันชนิดเดียวกัน'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.edcrPercentile || '-'}</InfoEdcr>
          </EdcrGod>
          <LineEdcr />
          <EdcrGod>
            <TopicEdcr>
              CR
              <CustomTooltip
                placement="right"
                title={'อัตราส่วนความคุ้มครองทั่วไป'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.cr || '-'}</InfoEdcr>
          </EdcrGod>
          <LineEdcr />
          <EdcrGod>
            <TopicEdcr>
              MBMIRR
              <CustomTooltip
                placement="right"
                title={'อัตราผลตอบแทนภายในคาดหวังเทียบอัตรามรณะ'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.mbmirr || '-'}%</InfoEdcr>
          </EdcrGod>
          <LineEdcr />
          <EdcrGod>
            <TopicEdcr>
              MBMIRR Percentile{' '}
              <CustomTooltip
                placement="right"
                title={'เทียบกับแบบประกันชนิดเดียวกัน'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.mbmirrPercentile || '-'}</InfoEdcr>
          </EdcrGod>
          <LineEdcr />
          <EdcrGod>
            <TopicEdcr>
              MIRR
              <CustomTooltip
                placement="right"
                title={'อัตราผลตอบแทนภายในที่ได้รับการแก้ไข'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.mirr || '-'}</InfoEdcr>
          </EdcrGod>
          <LineEdcr />
          <EdcrGod>
            <TopicEdcr>
              IRR
              <CustomTooltip
                placement="right"
                title={'อัตราผลตอบแทนภายในทั่วไป'}>
                <CustomIcon icon={['fas', 'info-circle']} />
              </CustomTooltip>
            </TopicEdcr>
            <InfoEdcr>{item.irr || '-'}%</InfoEdcr>
          </EdcrGod>
        </ContainerEdcr>
      </AppearanceComponent>
    ) : (
      <Fragment />
      //   <ContainerNoonInsightNoLogin>
      //   <CustomBigCircle
      //     count={6.8}
      //     radius={60 * 6.28}
      //     score={`6.8/10`}
      //     topic="NOON SCORE"
      //   />
      //   <BlurNoonInSight />
      //   <CustomButton
      //     onClick={() => setIsShowModal(!isShowModal)}
      //     icons={['fa', 'user-circle']}>
      //     เข้าสู่ระบบเพื่อดูข้อมูลแบบละเอียด
      //   </CustomButton>
      // </ContainerNoonInsightNoLogin>
    )}
    <CustomModal
      setIsShowModal={setIsShowModal}
      isShowModal={isShowModal}
      category={category}>
      <Auth
        modal
        setCategory={setCategory}
        category={category}
        onHeaderRegister={() => setCategory('register')}
        onHeaderLogin={() => setCategory('login')}
        onSetConfirmEmail={() => setCategory('confirmEmail')}
        onSetForgetPassword={() => setCategory('forgetPassword')}
        closeModal={() => setIsShowModal(false)}
      />
    </CustomModal>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLogin: state.auth.isLogin,
    }),
    {},
  ),
  withState('state', 'setState', 0),
  withState('isShowModal', 'setIsShowModal', false),
  withState('category', 'setCategory', 'login'),
  withState('count1', 'setCount1', 0),
  withState('count2', 'setCount2', 0),
  withState('count3', 'setCount3', 0),
  withState('count4', 'setCount4', 0),
  withState('count5', 'setCount5', 0),
  withState('runner', 'setRunner', {id: ''}),
  withHandlers({
    setNewCount: ({
      count1,
      count2,
      count3,
      count4,
      count5,
      setCount1,
      setCount2,
      setCount3,
      setCount4,
      setCount5,
      runner,
      item,
    }) => () => {
      if (runner.id === '') {
        runner.id = setInterval(() => {
          if (
            parseFloat(count1).toFixed(1) ===
            parseFloat(item.noonScore).toFixed(1)
          ) {
            clearInterval(runner.id)
          } else {
            const num1 = (count1 += item.noonScore / 50)
            // const num2 = (count2 += item.score.b / 50)
            // const num3 = (count3 += item.score.c / 50)
            // const num4 = (count4 += item.score.d / 50)
            // const num5 = (count5 += item.score.e / 50)
            setCount1(num1.toFixed(1))
            // setCount2(num2.toFixed(1))
            // setCount3(num3.toFixed(1))
            // setCount4(num4.toFixed(1))
            // setCount5(num5.toFixed(1))
          }
        }, 10)
      }
    },
  }),
  lifecycle({
    componentWillUnmount() {
      clearInterval(this.props.runner.id)
    },
  }),
)

export default enhancer(NoonInSight)
