import React from 'react'
import styled from 'react-emotion'
import {compose, withState, withHandlers} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {DatePicker} from '../../NoonTax/components'

const CustomDatePicker = styled(DatePicker)`
  :focus {
    outline: none;
    /* border: 1px solid ${props => props.theme.ORANGE}; */
    border : 1px solid #66B8FD;
  }

  &.zoom-label ~ div.container-label,
  &.rdtOpen ~ div.container-label {
    white-space: nowrap;
    left: 15px;
    top: 0;
    font-size: 14px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 47.5%,
      rgba(255, 255, 255, 1) 48%,
      rgba(255, 255, 255, 1) 100%
    );
    /* color: ${props => props.theme.ORANGE}; */
        color : #66B8FD;
    transform: translate(0, -50%);
  }
`

const Container = styled.div`
  width: fit-content;
  position: relative;
  margin: 0 auto;
`
const Label = styled.label`
  padding: 0 5px;
`

const ContainerErrorMessage = styled.div`
  position: absolute;
  color: ${props => props.theme.PINKRED};
  font-size: 12px;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => props.theme.PINKRED};
`
const EyePassword = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 20px;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 0;
  color: #7f7f7f;

  cursor: pointer;
`

const ContainerLabel = styled.div`
  width: fit-content;
  color: rgba(112, 112, 112, 0.5);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: 0.15s ease all;
  -moz-transition: 0.15s ease all;
  -webkit-transition: 0.15s ease all;
`

export default compose(
  withState('realMode', 'setRealMode', props => props.mode),
)(({placeholder, mode, realMode, setRealMode, errorMessage, ...props}) => (
  <Container>
    <CustomDatePicker
      className={!!props.value ? 'zoom-label' : ''}
      {...props}
      noCaption
    />
    {!props.hideLabelHeader && (
      <ContainerLabel className="container-label">
        <Label>{placeholder}</Label>
      </ContainerLabel>
    )}

    {mode === 'password' && (
      <EyePassword
        icon={realMode === 'password' ? ['far', 'eye'] : ['far', 'eye-slash']}
        onClick={() =>
          realMode === 'password'
            ? setRealMode('text')
            : setRealMode('password')
        }
      />
    )}
    {errorMessage && (
      <ContainerErrorMessage>
        <ExclamationCircle icon={['fas', 'exclamation-circle']} />{' '}
        {errorMessage}
      </ContainerErrorMessage>
    )}
  </Container>
))
