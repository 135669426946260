import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled, {css} from 'react-emotion'
import {reduxForm, Field, formValueSelector} from 'redux-form'
import withSEO from '../../../share/withSEO'
import Tooltip from '../../../share/Tooltip'
import {
  using,
  redLicense,
  carFixing,
  assignParties,
  fixing,
  firstDamage,
  otherDamage,
} from '../../options'
import {
  getVehicleYearOption,
  getVehicleBrandOption,
  getVehicleModelOption,
  getVehicleSubModelOption,
  getVehicleEngineSizeOption,
  setDataOptions,
} from '../../../../ducks/options'

import {
  displayNumber,
  numberParser,
  obj2Url,
  url2Obj,
} from '../../../../core/helper'
import Button from '../../../share/Button'
import Loading from '../../../share/Loading'
import CustomField from '../../../share/Field'
import {savePlanning} from '../../../../ducks/product'
import {responsive} from '../../../../core/style'

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }
`

const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const CarInfoFormHeader = styled.div`
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;

  ${props =>
    responsive.mobile(css`
      max-width: 150px;
      text-align: center;
    `)};
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 110px;

  ${props =>
    responsive.tablet(css`
      padding-left: 45px;
    `)};

  ${props =>
    responsive.mobile(css`
      min-width: 280px;
      padding-left: 0;
    `)};
`

const Selector = styled(CustomField)`
  width: 447px;
  .label {
    min-width: 240px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      flex-direction: column;

      .label {
        min-width: 120px;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
    `)};
`

const SmallSelector = styled(CustomField)`
  width: 367px;
  .label {
    min-width: 240px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      flex-direction: column;

      .label {
        min-width: 120px;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
    `)};
`

const LargeSelector = styled(CustomField)`
  width: 623px;
  .input-selector-container > div {
    height: 80px;
  }
  .label {
    min-width: 240px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      flex-direction: column;

      .label {
        min-width: 120px;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
    `)};
`

const TextInput = styled(CustomField)`
  width: 447px;
  .label {
    min-width: 240px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      flex-direction: column;

      .label {
        min-width: 120px;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
    `)};
`

const Radios = styled(CustomField)`
  width: fit-content;
  .label {
    min-width: 240px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      .label {
        justify-content: flex-start;
        margin-bottom: 15px;
      }
      .ant-radio-group {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        padding-left: 95px;
      }

      .ant-radio-wrapper {
        margin-bottom: 15px;
      }
    `)};
`

const CustomYearContainer = styled.div`
  display: flex;
`

const CarValueContainer = styled(CustomYearContainer)`
  display: flex;

  ${props =>
    responsive.mobile(css`
      position: relative;
    `)};
`

const Unit = styled.span`
  font-size: 16px;
  padding-top: 8px;
  margin-left: 18px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-top: 9px;
  margin-left: 25px;
  cursor: pointer;
  color: #7f7f7f;

  ${props =>
    responsive.mobile(css`
      position: absolute;
      right: 0;
      margin-top: 0;
    `)};
`
const Checkboxes = styled(CustomField)`
  width: fit-content;
  padding-top: 5px;
  margin: unset;
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 240px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      .label {
        min-width: unset;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
      .ant-checkbox-group {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        padding-left: 95px;
      }

      .ant-checkbox-group-item {
        margin-bottom: 15px;
        margin-right: unset;
      }
    `)};
`

const CustomButton = styled(Button)`
  width: 150px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 30px;
`

export const MotorIntermediateForm = ({
  handleSubmit,
  vehicleYearOption,
  getVehicleBrandOption,
  vehicleBrandOptions,
  getVehicleModelOption,
  vehicleModelOptions,
  getVehicleSubModelOption,
  vehicleSubModelOptions,
  getVehicleEngineSizeOption,
  vehicleEngineSizeOptions,
  optionsList,
  coverCarSelfRepair,
  change,
  isLoading,
  savePlanning,
  setDataOptions,
  onSubmit,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={
        onSubmit ||
        handleSubmit(value => {
          window.open(
            `/smart/motor/intermediate/product?${obj2Url({
              ...value,
              sortBy: 'min_premium',
            })}`,
            '_self',
          )
          savePlanning(
            {
              user_level: 'intermediate',
              vehicle_year: value.vehicleYear,
              vehicle_brand: value.vehicleBrandId,
              vehicle_model: value.vehicleModelId,
              vehicle_sub_brand: value.idKey,
              vehicle_size: value.engineSize,
              using: value.carUsage,
              red_license: value.redLabel,
              vehicle_value: value.cover,
              car_fixing_type: value.coverCarSelfRepair,
              assign_parties: value.specifyOtherDriver,
              fixing: value.repairServiceType,
              first_damage: value.deduct,
              other_damage: value.otherDamage,
              insurance_type: value.insuranceLevel,
            },
            'intermediate',
            'auto',
          )
        })
      }>
      <CarInfoFormHeader>เลือกแบบประกันที่คุณสนใจ</CarInfoFormHeader>
      <FormContainer>
        <CustomYearContainer>
          <SmallSelector
            name="vehicleYear"
            label="ปีรถยนต์"
            type="selector"
            placeholder="ปีรถยนต์"
            options={vehicleYearOption}
            themeColor="orange"
            didChange={value => {
              getVehicleBrandOption(value)
              change('vehicleBrandId', '')
              change('vehicleModelId', '')
              change('idKey', '')
              change('engineSize', '')
              setDataOptions({
                vehicleBrandOptions: [],
                vehicleModelOptions: [],
                vehicleSubModelOptions: [],
                vehicleEngineSizeOptions: [],
              })
            }}
          />
        </CustomYearContainer>
        <Selector
          name="vehicleBrandId"
          label="ยี่ห้อรถยนต์"
          type="selector"
          placeholder="ยี่ห้อรถยนต์"
          options={vehicleBrandOptions}
          themeColor="orange"
          didChange={value => {
            getVehicleModelOption(optionsList.vehicleYear, value)
            change('vehicleModelId', '')
            change('idKey', '')
            change('engineSize', '')
            setDataOptions({
              vehicleModelOptions: [],
              vehicleSubModelOptions: [],
              vehicleEngineSizeOptions: [],
            })
          }}
        />
        <Selector
          name="vehicleModelId"
          label="รุ่นรถยนต์"
          type="selector"
          placeholder="รุ่นรถยนต์"
          options={vehicleModelOptions}
          themeColor="orange"
          didChange={value => {
            getVehicleSubModelOption(
              optionsList.vehicleYear,
              optionsList.vehicleBrandId,
              value,
            )
            change('idKey', '')
            change('engineSize', '')
            setDataOptions({
              vehicleSubModelOptions: [],
              vehicleEngineSizeOptions: [],
            })
          }}
        />
        <LargeSelector
          name="idKey"
          label="รุ่นย่อยรถยนต์"
          type="selector"
          placeholder="รุ่นย่อยรถยนต์"
          options={vehicleSubModelOptions}
          themeColor="orange"
          didChange={value => {
            const findPrice = vehicleSubModelOptions.find(
              val => val.value === value,
            )
            getVehicleEngineSizeOption(
              optionsList.vehicleYear,
              optionsList.vehicleBrandId,
              optionsList.vehicleModelId,
            )
            change(
              'cover',
              findPrice.avgPrice ? findPrice.avgPrice : findPrice.price,
            )
            change('engineSize', '')
            setDataOptions({
              vehicleEngineSizeOptions: [],
            })
          }}
        />
        {/* <Selector
          name="engineSize"
          label="ขนาดเครื่องยนต์"
          type="selector"
          placeholder="ขนาดเครื่องยนต์"
          options={vehicleEngineSizeOptions}
          themeColor="orange"
        /> */}
        <Radios
          name="carUsage"
          label="การใช้งาน"
          type="radios"
          options={using}
        />
        {/* <Radios
          name="redLabel"
          label="ป้ายแดง"
          type="radios"
          options={redLicense}
        /> */}
        <Radios
          name="coverCarSelfRepair"
          label="คุ้มครองค่าซ่อมแซมรถเราเองด้วย"
          type="radios"
          options={carFixing}
        />
        {coverCarSelfRepair && (
          <Radios
            name="specifyOtherDriver"
            label="การระบุคู่กรณี"
            type="radios"
            options={assignParties}
          />
        )}
        <Checkboxes
          name="repairServiceType"
          label="การจัดซ่อม"
          type="checkboxes"
          options={fixing}
        />
        <Radios
          name="deduct"
          label="ความเสียหายส่วนแรก"
          type="radios"
          options={firstDamage}
        />
        <Checkboxes
          name="otherDamage"
          label="ความคุ้มครองเพิ่มเติม"
          type="checkboxes"
          options={otherDamage}
        />
        <CarValueContainer>
          <TextInput
            name="cover"
            label="มูลค่ารถยนต์"
            type="text"
            placeholder="มูลค่ารถยนต์"
            format={displayNumber}
            parse={numberParser}
          />
          <Unit>บาท</Unit>
          <CustomTooltip
            placement="right"
            title="คุณสามารถปรับเพิ่มลดมูลค่ารถยนต์เองได้">
            <CustomIcon icon={['fas', 'info-circle']} />
          </CustomTooltip>
        </CarValueContainer>
      </FormContainer>
      <CustomButton htmlType="submit" icons={['fa', 'search']}>
        เลือกแบบประกัน
      </CustomButton>
    </Container>
  </Loading>
)

const select = formValueSelector('Planning')

const enhancer = compose(
  withSEO(
    'noon Smart Motor',
    'noon Smart-Motor เช็คราคาประกันรถยนต์ เปรียบเทียบราคา ด้วยระบบที่สามารถตอบโจทย์ ความคุ้มค่าตามความต้องการของผู้บริโภคในยุคดิจิตัล',
    'เครื่องมือ,เช็คราคาประกันรถยนต์,ข้อมูลประกันรถยนต์,ชั้น1,ชั้น2,ชั้น2+,ชั้น3,ชั้น3+,รถยนต์ส่วนบุคคล,รถเก๋ง,รถกระบะ,ค่าเสียหายส่วนแรก,คุ้มครองผู้ขับขี่,คุ้มครองคู่กรณี',
  ),
  connect(
    (state, props) => ({
      vehicleYearOption: state.options.vehicleYearOption,
      vehicleBrandOptions: state.options.vehicleBrandOptions,
      vehicleModelOptions: state.options.vehicleModelOptions,
      vehicleSubModelOptions: state.options.vehicleSubModelOptions,
      vehicleEngineSizeOptions: state.options.vehicleEngineSizeOptions,
      isLoading: state.options.isLoading,

      initialValues: {
        carUsage: '110',
        redLabel: true,
        coverCarSelfRepair: true,
        specifyOtherDriver: true,
        insuranceLevel:
          url2Obj((props.location || {}).search || {}).insuranceLevel || '1',
        deduct: 'Y',
        ...url2Obj(props.location.search),
        vehicleYear: url2Obj(props.location.search).vehicleYear,
        vehicleBrandId: url2Obj(props.location.search).vehicleBrandId,
        vehicleModelId: url2Obj(props.location.search).vehicleModelId,
        idKey: url2Obj(props.location.search).idKey,
        engineSize: url2Obj(props.location.search).engineSize,
        driverBirthYear: url2Obj(props.location.search).driverBirthYear,
        cover: url2Obj(props.location.search).cover,
        coverCarSelfRepair:
          url2Obj(props.location.search).coverCarSelfRepair === 'true' ||
          url2Obj(props.location.search).coverCarSelfRepair === true,
        specifyOtherDriver:
          url2Obj(props.location.search).specifyOtherDriver === 'true' ||
          url2Obj(props.location.search).specifyOtherDriver === true,
      },
      optionsList: select(
        state,
        'vehicleYear',
        'vehicleBrandId',
        'vehicleModelId',
        'vehicleSubModel',
        'idKey',
      ),
      coverCarSelfRepair: select(state, 'coverCarSelfRepair'),
    }),
    {
      getVehicleYearOption,
      getVehicleBrandOption,
      getVehicleModelOption,
      getVehicleSubModelOption,
      getVehicleEngineSizeOption,
      savePlanning,
      setDataOptions,
    },
  ),

  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),

  lifecycle({
    componentDidMount() {
      const {
        getVehicleYearOption,
        getVehicleBrandOption,
        getVehicleModelOption,
        getVehicleSubModelOption,
        getVehicleEngineSizeOption,
        setDataOptions,
      } = this.props
      getVehicleYearOption()

      if (url2Obj(this.props.location.search).vehicleYear) {
        getVehicleBrandOption(url2Obj(this.props.location.search).vehicleYear)
      }

      if (url2Obj(this.props.location.search).vehicleBrandId) {
        getVehicleModelOption(
          url2Obj(this.props.location.search).vehicleYear,
          url2Obj(this.props.location.search).vehicleBrandId,
        )
      }
      if (url2Obj(this.props.location.search).vehicleModelId) {
        getVehicleSubModelOption(
          url2Obj(this.props.location.search).vehicleYear,
          url2Obj(this.props.location.search).vehicleBrandId,
          url2Obj(this.props.location.search).vehicleModelId,
        )
      }
      if (url2Obj(this.props.location.search).idKey) {
        getVehicleEngineSizeOption(
          url2Obj(this.props.location.search).vehicleYear,
          url2Obj(this.props.location.search).vehicleBrandId,
          url2Obj(this.props.location.search).vehicleModelId,
          url2Obj(this.props.location.search).idKey,
        )
      }
      setDataOptions({
        vehicleBrandOptions: [],
        vehicleModelOptions: [],
        vehicleSubModelOptions: [],
        vehicleEngineSizeOptions: [],
      })
    },
  }),
)

export default enhancer(MotorIntermediateForm)
