import React from 'react'
import styled, {css} from 'react-emotion'

import BLALogo from '../../static/images/tqm-life/Logo-2024-BLA.jpg'
import FwdLogo from '../../static/images/tqm-life/Logo-2024-FWD.png'
import MtlLogo from '../../static/images/tqm-life/MTL.png'
import AXALogo from '../../static/images/tqm-life/Logo-2024-axa.png'
import KWILogo from '../../static/images/tqm-life/Logo-2024-KWI.png'
import PhillipLifeLogo from '../../static/images/tqm-life/Logo-2024-PhillipLife.jpg'
import RabbitLogo from '../../static/images/tqm-life/Logo-2024-rabbit.png'
import SamsungLogo from '../../static/images/tqm-life/Logo-2024-Samsung.PNG'
import SELifeLogo from '../../static/images/tqm-life/Logo-2024-SE-LIFE.png'
import TIPLogo from '../../static/images/tqm-life/Logo-2024-TIP.png'
import ChubbLogo from '../../static/images/tqm-life/Logo-2024-chubb.png'

import {responsive} from '../../core/style'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 728px;
  ${props =>
    responsive.mobile(css`
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0 20px;
    `)};
`

const MTL = styled.img`
  height: 70px;
  margin: -30px -20px -20px;

  ${props =>
    responsive.mobile(css`
      height: 70px;
      margin: 0 0;
    `)};
`

const FWD = styled.img`
  height: 70px;
  margin-top: -8px;

  ${props =>
    responsive.mobile(css`
      height: 65px;
      margin: 0 0;
    `)};
`

const SELIC = styled.img`
  height: 42px;

  ${props =>
    responsive.mobile(css`
      height: 34px;
      margin: 0;
    `)};
`

const BLA = styled.img`
  height: 47px;
  margin: -15px 0 0 10px;

  ${props =>
    responsive.mobile(css`
      height: 40px;
      margin: 0;
    `)};
`

const AXA = styled.img`
  height: 50px;
  margin: 0 0 0 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
      margin: 0;
    `)};
`
const KWI = styled.img`
  height: 50px;
  margin: 0 0 0 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
      margin: 0;
    `)};
`
const PHILLIP = styled.img`
  height: 50px;
  margin: 0 0 0 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
    `)};
`
const RABBIT = styled.img`
  height: 50px;
  margin: 0 0 0 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
    `)};
`
const SAMSUNG = styled.img`
  height: 50px;
  margin: 0 0 0 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
      margin: 0;
    `)};
`
const TIP = styled.img`
  height: 50px;
  margin: 0 0 0 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
      margin: 0;
    `)};
`
const CHUBB = styled.img`
  height: 50px;
  margin: 0 0 5px 10PX;

  ${props =>
    responsive.mobile(css`
      height: 50px;
      margin: 0;
    `)};
`
const NEW_LINE = styled.br`
${props =>
  responsive.mobile(css`
    display: none; 
  `)};
`

const Partner = () => (
  <Container>
    <MTL src={MtlLogo} alt="mtl" />
    <FWD src={FwdLogo} alt="fwd" />
    <SELIC src={SELifeLogo} alt="selic" />
    <TIP src={TIPLogo} alt="tip" />
    <CHUBB src={ChubbLogo} alt="chubb" />
    <BLA src={BLALogo} alt="bla" />
    <NEW_LINE />
    <RABBIT src={RabbitLogo} alt="rabbit" />
    <KWI src={KWILogo} alt="kwi" />
    <PHILLIP src={PhillipLifeLogo} alt="phillip" />
    <SAMSUNG src={SamsungLogo} alt="samsung" />
    <AXA src={AXALogo} alt="axa" />
  </Container>
)

export default Partner
