import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {
  displayNumber,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import {breakpoints, responsive} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'
import PersonalForm from '../../../../../share/PersonalFormTQM'

import Panel from '../PanelForm'

import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
} from '../ShareFieldForm'

const CustomFieldContainer = styled(FieldContainer)`
  margin-left: 23px;
`
const CustomPersonalForm = styled(PersonalForm)`
  margin-left: -62px;
  ${props =>
    responsive.mobile(css`
      margin-left: 0px;
    `)};
`

const InfoUnit = styled.span`
  font-size: 12px;
`

const CustomTextFooter = styled(TextFooter)`
  width: 550px;
  height: 76px;
  font-size: 16px;
  color: white;
  text-align: center;
`

const CustomText = styled.b`
  font-size: 20px;
`

const Summary = ({summary}) => (
  <CustomText>{displayNumber(Math.round(summary))} บาท</CustomText>
)

const RetireForm = ({summary, onCalSummary, windowWidth}) => (
  <Panel>
    <div>
      <CustomPersonalForm />
      <CustomFieldContainer>
        <TextInput
          name="preparedExpense"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ค่าใช้จ่ายจำเป็นที่ควรเตรียมไว้ (บาท)'
              : 'ค่าใช้จ่ายจำเป็นที่ควรเตรียมไว้'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท/เดือน</Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="expectedMoney"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เงินได้ประจำที่คาดว่าจะได้รับ (บาท)'
              : 'เงินได้ประจำที่คาดว่าจะได้รับ'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>
          บาท/เดือน
          <br />
          <InfoUnit>ตั้งแต่เกษียณจนอายุคาดหมาย</InfoUnit>
        </Unit>
      </CustomFieldContainer>
    </div>
    <FooterFormContainer>
      <CustomTextFooter>
        <SearchFactor
          type="Retirement"
          windowWidth={windowWidth}
          searchValue={summary}
        />
        <CustomField name="searchValue" component={Summary} summary={summary} />
      </CustomTextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'arrow-right']}
        disabled={summary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(state, 'preparedExpense', 'expectedMoney'),
    }),
    {change},
  ),
  withState('summary', 'setSummary', 0),
  withHandlers({
    onCalSummary: ({totalList, setSummary, change}) => () => {
      const {preparedExpense = '0', expectedMoney = '0'} = totalList

      let first = convertNaNtoZero(preparedExpense)

      let second = convertNaNtoZero(expectedMoney)

      let summary = first - second
      setSummary(summary * 12)
      change('Planning', 'searchValue', summary * 12)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onCalSummary()
    },
  }),
)

export default enhancer(RetireForm)
