import React from 'react'
import {compose} from 'recompose'
import {withAnswers} from '../../enhances'
import styled from 'react-emotion'
import {Button, DatePicker, Dropdown, TextInput} from '../../components'
import {CustomTextInput, CustomDatePicker, CustomDropdown} from '..'

const MainSpidusManager = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`
const SendButton = styled(Button)`
  margin-top: 20px;
`
const Title = styled.div`
  font-size: 18px;
  margin-bottom: 20px !important;
`

const PrepareDataPanel = props => (
  <MainSpidusManager configName="noontax_config_web_calculator">
    <Title>กรุณากรอกข้อมูลให้ครบถ้วน</Title>
    <DatePicker
      className={CustomDatePicker}
      answerName="birthdate"
      maxDate={new Date()}
      placeholder="วว/ดด/ปปปป"
    />
    <Dropdown
      className={CustomDropdown}
      answerName="handicap_status"
      placeholder="ระบุ"
    />
    <Dropdown
      className={CustomDropdown}
      answerName="married_status"
      placeholder="ระบุ"
    />
    <TextInput
      className={CustomTextInput}
      answerName="pgd_personal_firstname"
    />
    <TextInput className={CustomTextInput} answerName="pgd_personal_lastname" />
    <TextInput className={CustomTextInput} answerName="pgd_personal_id" />
    <TextInput className={CustomTextInput} answerName="pgd_payer_id_40_1_2" />
    <TextInput className={CustomTextInput} answerName="pgd_address_house_no" />
    <TextInput
      className={CustomTextInput}
      answerName="pgd_address_sub_district"
    />
    <TextInput className={CustomTextInput} answerName="pgd_address_district" />
    <TextInput className={CustomTextInput} answerName="pgd_address_province" />
    <TextInput className={CustomTextInput} answerName="pgd_address_zip_code" />

    <SendButton onClick={props.onSubmit}>ยื่นกรมสรรพากร</SendButton>
  </MainSpidusManager>
)

const enhancer = compose(
  withAnswers('noontax_config_web_calculator', {
    age: 'age.value',
    additoinalInsuranceLife: 'planning_deduct_life_insurance',
    additoinalInsurancePension: 'planning_deduct_pension_insurance',
    additoinalInsuranceHealth: 'planning_deduct_health_insurance',
  }),
)

export default enhancer(PrepareDataPanel)
