import {compose, branch, withHandlers, defaultProps, withProps} from 'recompose'
import CustomSelect from '../../share/Field/Selector'
import {
  withCaption,
  withSpidus,
  withValue,
  withDisabled,
  omitProps,
} from '../enhances/index'
import {css} from 'react-emotion'

const disabledStyled = css`
  opacity: 1;
  background-color: #e5e5e5;
`

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption(),
  withDisabled(disabledStyled),
  defaultProps({
    searchable: false,
    themeColor: 'orange',
    multi: false,
  }),
  withHandlers({
    onChange: props => value => {
      props.onChange && props.onChange(value.value)
      props.onBlur && props.onBlur(value.value)
    },
  }),
  omitProps('onBlur'),
)

export default enhance(CustomSelect)
