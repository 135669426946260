import React from 'react'
import {compose} from 'recompose'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'
import Banner from '../../../../static/images/123.png'
import BannerMobile from '../../../../static/images/BannerMobile.jpg'
import {responsive, fadeIn} from '../../../../core/style'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {reduxForm, Field} from 'redux-form'

import BackgroundContent from '../../../../static/images/tqm-life/768_300@2x.png'
import BackgroundContentMobile from '../../../../static/images/tqm-life/mobile320_300@2x.png'

import Navigator from '../../Navigator'

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

// const Container = styled.div`
//   position: absolute;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin-top: 0;
//   min-height: 460px;
//   top: 80px;
//   right: calc(50% - 400px);

//   ${props =>
//     responsive.tablet(css`
//       right: calc(50% - 360px);
//     `)};

//   ${props =>
//     responsive.mobile(css`
//       right: calc(50% - 135px);
//       top: calc(50% - 270px);
//     `)};
// `

// const NoonSmartHeader = styled.div`
//   font-size: 57px;
//   font-weight: bold;
//   color: white;

//   ${props =>
//     responsive.mobile(css`
//       font-size: 27px;
//     `)};
// `

// const NoonSubSmartHeader = styled.div`
//   font-size: 37px;
//   font-weight: bold;
//   color: white;
//   margin-bottom: 24px;

//   ${props =>
//     responsive.mobile(css`
//       font-size: 18px;
//     `)};
// `

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  background-image: url(${BackgroundContent});
  width: 768px;
  height: 300px;
  background-size: cover;
  background-position-x: center;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BackgroundContentMobile});
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 300px;
      justify-content: center;
      align-items: end;
    `)};
`

const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  animation: ${fadeIn} 2s;
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;

  /* ${props =>
    responsive.mobile(css`
      font-size: 16px;
      margin-bottom: 0;
    `)}; */
`

const Info = styled.div`
  font-size: 16px;
  max-width: 130px;
  text-align: center;
`

const BoxOnce = styled.div`
  display: flex;
  position: relative;
  width: 200px;
  height: 162px;
  margin: 10px;
  border-radius: 5px;
  background-color: ${props => (props.isSelected ? '#008AFC' : '#66B8FD')};
  color: white;
  overflow: hidden;
  cursor: pointer;

  :hover {
    background-color: #008afc;
    color: white;
    .briefcase-medical,
    .procedures {
      color: rgba(178, 117, 0, 0.3);
    }
  }

  @media (max-width: 450px) {
    margin-top: 15px;
  }

  ${props =>
    responsive.mobile(css`
      width: 200px;
      height: 162px;
      background-color: #66b8fd;
      margin: 10px 0;
    `)};
`
const ContainerBox = styled.div`
  display: flex;
  max-width: 660px;
  width: 100%;
  justify-content: flex-start;
  margin: 30px;
  flex-wrap: wrap;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }

  ${props =>
    responsive.mobile(css`
      align-items: center;
      flex-direction: column;
      width: 100%;
    `)};
`
const BannerIconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008afc;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 12px;
`
const BannerIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 20px;
`
const BannerContainer = styled.div`
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 20px;
  ${props =>
    responsive.mobile(css`
      margin-right: 0px;
      max-width: 280px;
    `)};
`
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    responsive.mobile(css`
      margin-top: 24px;
    `)};
`

const BannerText = styled.div`
  font-size: 30px;
  color: #008afc;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
    `)};
`
const BannerSubText = styled.div`
  display: flex;
  justify-content: center;
  width: 372px;
  height: 50px;
  font-size: 16px;
  color: #666666;
  margin-top: 10px;
`

const BoxList = [
  {
    iconTag: 'fas',
    iconName: 'umbrella',
    info: 'แบบตลอดชีพ',
    id: 'whole_life',
  },
  {
    iconTag: 'fas',
    iconName: 'coins',
    info: 'แบบสะสมทรัพย์',
    id: 'saving',
  },
  {
    iconTag: 'fas',
    iconName: 'clock',
    info: 'แบบชั่วระยะเวลา',
    id: 'term',
  },
  {
    iconTag: 'fas',
    iconName: 'man-with-cane',
    info: 'แบบบำนาญ',
    id: 'annuity',
  },
  {
    iconTag: 'fas',
    iconName: 'box-open',
    info: 'แบบแพ็คเกจพิเศษ เฉพาะที่ TQM',
    id: 'package',
  },
]

const Box = ({item, isSelected, setIsSelected, change}) => (
  <BoxOnce
    onClick={() => {
      setIsSelected(item.id)
      change(`searchBy`, 'premium')
      if (item.id === 'whole_life') {
        change(`weightType`, 5)
        navigate(
          `/smart/InsuranceCategory/life/intermediate/Lifeform/${
            item.id
          }/${5}?insuranceType=whole_life`,
        )
      }
      if (item.id === 'saving') {
        change(`weightType`, 1)
        navigate(
          `/smart/InsuranceCategory/life/intermediate/Lifeform/${
            item.id
          }/${1}?insuranceType=saving`,
        )
      }
      if (item.id === 'term') {
        change(`weightType`, 5)
        navigate(
          `/smart/InsuranceCategory/life/intermediate/Lifeform/${
            item.id
          }/${5}?insuranceType=term`,
        )
      }
      if (item.id === 'annuity') {
        change(`weightType`, 3)
        navigate(
          `/smart/InsuranceCategory/life/intermediate/Lifeform/${
            item.id
          }/${3}?insuranceType=annuity`,
        )
      }
      if (item.id === 'package') {
        change(`weightType`, 3)
        navigate(
          `/smart/InsuranceCategory/life/intermediate/Lifeform/${
            item.id
          }/${3}?insuranceType=package`,
        )
      }
    }}>
    <InfoContainerBox>
      <CustomIcon icon={[item.iconTag, item.iconName]} />
      <Info>{item.info}</Info>
    </InfoContainerBox>
  </BoxOnce>
)

const CustomSelector = ({input, change}) => (
  <ContainerBox>
    {BoxList.map(item => (
      <Box
        key={item.iconName}
        item={item}
        isSelected={input.value}
        setIsSelected={input.onChange}
        change={change}
      />
    ))}
  </ContainerBox>
)

// const LifeBannerCover = ({change}) => (
//   <NoonSmartWrapper>
//     <NoonSmartBanner>
//     <Container>
//       <NoonSmartHeader>แบบประกันที่ใช่</NoonSmartHeader>
//       <NoonSubSmartHeader>ใครๆ ก็ชอบ</NoonSubSmartHeader>
//       <Navigator />
//       <Field name="insuranceType" change={change} component={CustomSelector} />
//     </Container>
//     </NoonSmartBanner>
//   </NoonSmartWrapper>
// )

const LifeBannerCover = props => (
  <NoonSmartWrapper>
    <NoonSmartBanner>
      <BannerContainer>
        <WrapperIcon>
          <BannerIconBackground>
            <BannerIcon icon={('fal', 'search')} />
          </BannerIconBackground>
          <BannerText>ค้นหาแบบประกันด้วยตัวเอง</BannerText>
        </WrapperIcon>

        {/* <BannerSubText>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor
        </BannerSubText> */}
      </BannerContainer>
    </NoonSmartBanner>
    <Field
      name="insuranceType"
      change={props.change}
      component={CustomSelector}
    />
  </NoonSmartWrapper>
)

const enhancer = compose(
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default enhancer(LifeBannerCover)
