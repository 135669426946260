import React, { Fragment } from 'react'
import styled, { css } from 'react-emotion'
import { compose, withProps, withState } from 'recompose'

import { displayNumber, obj2Url } from '../../../../core/helper'
import KendoIcon from '../../../share/Icon'
import ModalFavorite from '../../../share/ModalFavorite'
import ModalCataloguePackage from '../ModalCataloguePackage'
import { responsive } from '../../../../core/style'
import {
  Layout,
  ActionWrapper,
  HeartIcon,
  DescriptionWrapper,
  Body,
  Title,
  Logo,
  CompanyLogo,
  WrapperTitle,
  ProductName,
  CompanyName,
  Content,
  ContentRegular,
  StarContainer,
  IconCheckCircle,
  IconTimesCircle,
  Premium,
  Footer,
  CustomTagA,
  renderStar,
  DetailButton,
  InterestButton,
} from './Life'
import defaultLogo from '../../../../static/images/tqm-life/img-no-insurance.png'
export const RowInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  align-items: baseline;
`

const Rider = styled.div``

const RowInfoHorizontal = styled(RowInfo)`
  flex-direction: column;
  align-items: flex-start;

  ${ContentRegular} {
    text-align: left;
  }

  ${ContentRegular} {
    width: 100%;
  }

  ${Rider} {
    text-overflow: ellipsis;
  }
`
const PremiumContent = styled.div`
  text-align: left;
  font-size: 12px;
  color: #666666;
  font-family: Sarabun, sans-serif;
  display: flex;
  white-space: nowrap;
  align-items: center;
`
const PackageLayout = styled(Layout)`
  min-height: 620px;

  ${props =>
    responsive.mobile(css`
      max-width: 350px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    `)};
`
const SpecialPremium = styled.div`
  font-size: 16px;
  font-weight: 500;
`
const Life = props => (
  <Layout>
    {props.tag ? <Fragment>{props.tag}</Fragment> : null}

    <ActionWrapper>
      {props.SpecialActionComponent ? (
        <props.SpecialActionComponent {...props} />
      ) : (
          <div />
        )}

      {/* <HeartIcon
        onClick={() => {
          props.isLogin
            ? props.onFavorite(
                !props.localFavorite,
                props.data.id,
                'life',
                props.data.paramsEncode,
              )
            : props.setIsShowModalFavorite(!props.isShowModalFavorite)
        }}
        icon={props.localFavorite ? ['fas', 'heart'] : ['far', 'heart']}
      /> */}
    </ActionWrapper>

    <DescriptionWrapper>
      <Body>
        <Title>
          <Logo>
            <CompanyLogo src={props.data.companyOfficialLogoUrl || defaultLogo} />
          </Logo>
          <WrapperTitle>
            <ProductName>{props.data.packageName}</ProductName>
            <CompanyName>{props.data.companyName}</CompanyName>
          </WrapperTitle>
        </Title>

        <RowInfo>
          <Content>ชื่อประกันหลัก</Content>
          <Premium>
            <PremiumContent>{props.data.policyName}</PremiumContent>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>ทุนประกันหลัก</Content>
          <Premium>
            <PremiumContent>
              <SpecialPremium>
                {displayNumber(props.data.lifeSumInsured)}
              </SpecialPremium>
              &nbsp;&nbsp;บาท
            </PremiumContent>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>ทุนสัญญาเพิ่มเติม</Content>
          <Premium>
            <PremiumContent>
              {displayNumber(props.additionalSumInsured)}&nbsp;&nbsp;บาท
            </PremiumContent>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>เบี้ยประกันรวม</Content>
          <Premium>
            <PremiumContent>
              {displayNumber(props.data.totalSumInsured)}&nbsp;&nbsp;บาท
            </PremiumContent>
          </Premium>
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA href={props.pathUrl} target="_blank">
          <DetailButton type="border">
            รายละเอียด
            <KendoIcon icon="table-align-middle-left" />
          </DetailButton>
        </CustomTagA>
        <InterestButton
          onClick={() => {
            props.setIsShowModal(true)
          }}>
          สนใจซื้อ
          <KendoIcon icon="cart" />
        </InterestButton>
      </Footer>
    </DescriptionWrapper>

    <ModalCataloguePackage
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      category="package"
      params={props.params}
      premium={props.data.premium}
      id={props.data.id}
      companyId={props.data.companyId}
      paramsEncode={props.data.paramsEncode}
      productName={props.data.productName}
      packageReferenceCode={props.data.packageReferenceCode}
      insuranceCompanyName={props.data.insuranceCompanyName}
      packageInfo={props.data}
    />
  </Layout>
)

export const pathUrlLife = props => {
  return `/smart/package/${
    props.data.level === undefined ? 'allLevel' : props.data.level
    }/product/${props.data.id}?${obj2Url({
      ...props.params,
      premium: parseInt(props.data.premium),
      sumInsured: parseInt(props.data.sumInsured),
      id: props.data.id,
      encodeFlag: props.fromFavoriteProfile ? 'true' : 'false',
      paramsEncode: props.data.paramsEncode,
      companyId: props.data.companyId,
    })}`
}

const enhance = compose(
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withState('isShowModal', 'setIsShowModal', false),
  withProps(props => ({
    pathUrl: `${pathUrlLife(props)}`,
  })),
)

export default enhance(Life)
