import React from "react"
import styled from 'react-emotion'
import { compose, withState, withHandlers } from "recompose"
import {
  CustomTagA,
  DetailButton
} from '../../Card/Life'
import KendoIcon from '../../../../share/Icon'

const Layout = styled.div`
  display: ${props => (!props.isEdit || props.isSelected ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  width: 678px;
  height: auto;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #66b8fd;
  padding: 20px;
`
// const AvatarColumn = styled.div`
//   margin-bottom: 10px;
// `
// const DetailColumn = styled.div`
//   flex: 1;

//   ${Info} {
//     &:nth-of-type(1) {
//       margin-top: 0px;
//     }
//   }
// `

// const DetailButton = styled(Button)`
//   display: ${props => (props.readOnly ? "none" : "flex")};
//   background: #ffffff;
//   border-radius: 4px;
//   width: 139px;
//   height: 38px;
//   margin-top: 5px;
// `

// const RiderContainerLeft = styled.div`
//   flex: 2;
// `

const RiderContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  > .k-textbox-container {
    width: 139px;
  }
`

// const DetailField = styled(Field)`
//   > div:first-of-type {
//     min-width: 106px;
//     text-align: left;
//     color: #666666;
//   }
//   > div:last-of-type {
//     min-width: 50px;
//     margin-left: 24px;
//   }
// `

// const HeaderContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 678px;
//   height: 40px;
//   background: #0275d8;
//   border: 1px solid #eeeef2;
//   border-radius: 4px 4px 0px 0px;
//   padding: 0px 15px 0px 15px;
//   align-items: center;
// `

const BodyContainer = styled.div`
  display: flex;
  height: auto;
  padding: 17px 17px 20px 20px;
`
const DetailBtn = styled(DetailButton)`
  max-width: 200px;
`
const WrapBtn = styled(CustomTagA)`
  div {
    align-items: flex-end;
    padding-bottom: 0;
  }
`
const WrapRow = styled.div`
  min-width: 106px;
  text-align: left;
  color: #333333;
  font-size: 16px;
  display: flex;
`
const TextTitle = styled.div`
  max-width: 200px;
  width: 100%;
`
const Lable = styled.div`
  font-weight: 500;
  color: black;
`

const AdditionalContract = props => {
  const { item } = props
  return (
    <Layout>
      <WrapBtn href={props.pathUrl} target="_blank">
        <DetailBtn type="border">
          รายละเอียด <KendoIcon icon="table-align-middle-left" />
        </DetailBtn>
      </WrapBtn>
      <WrapRow>
        <TextTitle>ชื่อแบบประกัน</TextTitle>
        <Lable>{item.name}</Lable>
      </WrapRow>
      <WrapRow>
        <TextTitle>สถานะ</TextTitle>
        <Lable>{item.state}</Lable>
      </WrapRow>
      <WrapRow>
        <TextTitle>ชื่อบริษัทประกัน</TextTitle>
        <Lable>{item.companyName}</Lable>
      </WrapRow>
    </Layout >
  )
}

const enhancer = compose()

export default enhancer(AdditionalContract)
