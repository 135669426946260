import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withProps, withHandlers, defaultProps} from 'recompose'
import {displayNumber, numberParser} from '../../../core/helper'
import {withField, withCaption} from '../enhancers/index'
import TextInput from '../../share/Field/TextInput'

const Input = styled(TextInput)`
  text-align: center;
`

const disabledStyled = css`
  ${'' /* opacity: 0.8; */}
  background-color: #e5e5e5;
`

const ReadOnlyInput = styled.div`
  text-align: right;
`

const CustomTextInput = props => (
  <>
    {props.readOnly ? (
      <ReadOnlyInput>{props.value}</ReadOnlyInput>
    ) : (
      <Input {...props} />
    )}
  </>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withHandlers({
    onChange: props => e => {
      let value = e.target.value ? numberParser(e.target.value) : null

      if (value !== null && value !== undefined) {
        if (props.min) {
          value = Math.max(value, props.min)
        }
        if (props.max) {
          value = Math.min(value, props.max)
        }
      }

      props.onChange && props.onChange(value)
    },
    onBlur: props => e => {
      props.onBlur && props.onBlur(props.value)
    },
  }),
  withProps(props => ({
    value:
      props.value === undefined || props.value === null
        ? ''
        : displayNumber(props.value),
  })),
)

export default enhancer(CustomTextInput)
