import React from 'react'
import styled from 'react-emotion'

import {compose, withProps} from 'recompose'

const I = styled.span`
  font-size: ${props => (props.size ? `${props.size}px` : 'auto')};
`

const Icon = props => <I className={`${props.iconClass} ${props.className}`} />

const enhancer = compose(
  withProps(props => {
    if (props.awesomeIcon) {
      const awesomeIcon =
        typeof props.awesomeIcon === 'string'
          ? ['fas', props.awesomeIcon]
          : props.awesomeIcon
      const [awesomeIconStyle, awesomeIconName] = awesomeIcon

      return {
        iconClass: `k-button-icontext k-icon ${awesomeIconStyle} fa-${awesomeIconName}`,
      }
    } else if (props.icon) {
      return {
        iconClass: `k-icon k-i-${props.icon}`,
      }
    }
  }),
)

export default enhancer(Icon)
