import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withState, withProps} from 'recompose'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {displayNumber, obj2Url} from '../../../core/helper'
import {Link} from '@reach/router'
import ModalCatalogue from '../../NoonSmart/Product/ModalCatalogue'

import Button from '../../share/Button'

import CompanyLogo from '../../../static/images/company-logo.jpg'

const RowContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147.8px;
  min-height: 50px;
  color: black;
  background-color: #e5e5e5;
  margin: 0 0 2px 0;
  font-size: 12px;
  margin-right: 5.32px;
  text-align: center;
  white-space: pre-wrap;
  padding: 0 17px;
  cursor: ${props => (props.isCursor ? 'pointer' : 'unset')};

  &.customRow {
    flex-direction: column;
    padding: 0 5px;
  }
  &.lookForDetails {
    text-decoration: underline;
  }
`
const CustomRow = styled.div`
  display: flex;
`

const CustomColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.opacityActive ? 'unset' : '0.5')};

  > div:nth-child(odd) :not(:first-child, :last-child) {
    background-color: rgba(229, 229, 229, 0.7);
  }
`

const HeadColumnContainer = styled.div`
  display: flex;
`

const HeadColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 147.91px;
  min-height: 100px;
  background-color: #265fa7;
  opacity: ${props => (props.opacityActive ? '1' : '0.5')};
  margin-right: 4.89px;
  border-radius: 5px 5px 0 0;
`

const CompanyImage = styled.img`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  width: 20px;
  height: 20px;
  margin-bottom: 6.44px;
`

const HeadColumnText = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 0 27px;
`

const CloseIcon = styled(FontAwesomeIcon)`
  display: ${props => (props.closeActive ? 'block' : 'none')};
  position: absolute;
  right: 5px;
  top: -10px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(38, 95, 167, 0.5);
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;
`

const StarContainer = styled.div`
  display: flex;
`

const StarIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: #ffd900;
`

const CheckIcon = styled(FontAwesomeIcon)`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  font-size: 12px;
  color: #9fd134;
`

const UnCheckIcon = styled(FontAwesomeIcon)`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  font-size: 12px;
  color: #ff0034;
`

const Unit = styled.span`
  display: ${props => (props.unitActive ? 'flex' : 'none')};
  font-size: 12px;
  text-align: center;
  margin: ${props => (props.isMargin ? '0 5px' : '0')};
`
const CustomTagA = styled.a``

const CustomButton = styled(Button)`
  margin: 15px;
`

const starRate = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}]

const Star = ({popularRate}) => (
  <StarContainer>
    {starRate.map(value =>
      value.id <= popularRate ? (
        <StarIcon key={value.id} icon={['fas', 'star']} />
      ) : (
        ''
      ),
    )}
  </StarContainer>
)

const Check = ({checkStatus, isShow}) =>
  checkStatus ? (
    <CheckIcon icon={['fas', 'check-circle']} isShow={isShow} />
  ) : (
    <UnCheckIcon icon={['fas', 'times-circle']} isShow={isShow} />
  )

const LifeColumnContent = ({
  productDetailsCompareList = [undefined, undefined, undefined],
  category,
  level,
  params,
  showModalId,
  setShowModalId,
}) => (
  <HeadColumnContainer>
    {productDetailsCompareList.map((value = {}) => (
      <CustomColumnContent
        key={value.id}
        opacityActive={value.companyOfficialLogoUrl}>
        <HeadColumn opacityActive={value.companyShortName}>
          <CompanyImage
            src={value.companyOfficialLogoUrl}
            isShow={value.companyOfficialLogoUrl}
          />
          <HeadColumnText>{value.companyShortName}</HeadColumnText>
          <HeadColumnText>{value.companyName}</HeadColumnText>
        </HeadColumn>
        {/* <RowContent>
          <Star popularRate={value.noonStar} />
        </RowContent> */}
        <RowContent>{value.companyName}</RowContent>
        <RowContent>{value.productName}</RowContent>
        <RowContent>{value.insuranceType}</RowContent>
        <RowContent>
          {displayNumber(value.sumInsured)}
          <Unit unitActive={value.companyName} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.premium)}
          <Unit unitActive={value.companyName} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent className="customRow">
          <CustomRow>
            {value.payPeriod}
            <Unit unitActive={value.companyName} isMargin={true}>
              ปี
            </Unit>
          </CustomRow>
          {value.payPeriodText}
        </RowContent>
        <RowContent className="customRow">
          <CustomRow>
            {value.protectionPeriod}
            <Unit unitActive={value.companyName} isMargin={true}>
              ปี
            </Unit>
          </CustomRow>
          {((value || {}).protectionPeriodText || {}).protectPeriodAge}
        </RowContent>
        {value.id ? (
          <CustomTagA
            href={`/smart/${category}/${
              level === undefined ? 'allLevel' : level
            }/product/${value.id}?${obj2Url({
              age: params.age,
              gender: params.gender,
              sumInsured: parseInt(value.sumInsured),
              encodeFlag: 'true',
              paramsEncode: value.paramsEncode,
              premium: value.premium,
              id: value.id,
            })}`}
            target="_blank">
            <RowContent className="lookForDetails" isCursor={true}>
              (คลิกเพื่อดู)
            </RowContent>
          </CustomTagA>
        ) : (
          <RowContent />
        )}
        <RowContent>
          <Check
            checkStatus={value.hasRefund}
            isShow={value.companyOfficialLogoUrl}
          />
        </RowContent>
        <RowContent>
          <Check
            checkStatus={value.yieldToMaturityAmount}
            isShow={value.companyOfficialLogoUrl}
          />
        </RowContent>
        <RowContent>
          <Check
            checkStatus={value.hasDividend}
            isShow={value.companyOfficialLogoUrl}
          />
        </RowContent>
        <RowContent>
          <Check
            checkStatus={value.isTaxSaving}
            isShow={value.companyOfficialLogoUrl}
          />
        </RowContent>
        {/* <RowContent>
          {value.noonScore}
          <Unit unitActive={value.companyName}>/10</Unit>
        </RowContent>
        <RowContent>{value.edcr}</RowContent>
        <RowContent>
          {value.id ? value.edcrp || `-` : ''}
          <Unit unitActive={value.edcrp}>th Percentile</Unit>
        </RowContent>
        <RowContent>{value.id ? value.cr || `-` : ''}</RowContent>
        <RowContent>
          {value.mbmirr}
          <Unit unitActive={value.mbmirr}>%</Unit>
        </RowContent>
        <RowContent>
          {value.id ? value.MBMIRRP || `-` : ''}
          <Unit unitActive={value.MBMIRRP}>th Percentile</Unit>
        </RowContent>
        <RowContent>
          {value.id ? value.MIRR || `-` : ''}
          <Unit unitActive={value.MIRR}>%</Unit>
        </RowContent>
        <RowContent>
          {value.irr}
          <Unit unitActive={value.irr}>%</Unit>
        </RowContent>
        <RowContent>
          {value.id ? value.insurerScore || `-` : ''}
          <Unit unitActive={value.insurerScore}>/10</Unit>
        </RowContent>
        <RowContent>
          {value.id
            ? value.carScore === '5.0'
              ? '5'
              : value.carScore || `-`
            : ''}
          <Unit unitActive={value.carScore}>/5</Unit>
        </RowContent>
        <RowContent>
          {value.id
            ? value.ilrScore === '5.0'
              ? '5'
              : value.ilrScore || `-`
            : ''}
          <Unit unitActive={value.ilrScore}>/5</Unit>
        </RowContent>
        <RowContent>{value.id ? value.budgetYear || `-` : ''}</RowContent> */}
        <RowContent>{value.id ? value.budgetQuarter || `-` : ''}</RowContent>
        {value.id && (
          <React.Fragment>
            <CustomButton
              onClick={() => {
                setShowModalId(value.id)
              }}
              icons={['fa', 'shopping-cart']}>
              สนใจซื้อ
            </CustomButton>
            <ModalCatalogue
              isShowModal={showModalId === value.id}
              setIsShowModal={() => setShowModalId('')}
              {...params}
              params={params}
              category={category}
              {...value}
            />
          </React.Fragment>
        )}
      </CustomColumnContent>
    ))}
  </HeadColumnContainer>
)

const enhancer = compose(
  withState('showModalId', 'setShowModalId', ''),
  connect(
    (state, props) => ({
      compareList: state.product.compareList,
      productDetailsCompareList: state.product.productDetailsCompareList,
    }),
    {},
  ),
  withProps(props => {
    const slicedProductDetailsCompareList = props.productDetailsCompareList
      ? props.productDetailsCompareList.slice(0, 3)
      : props.productDetailsCompareList
    return {
      productDetailsCompareList: slicedProductDetailsCompareList,
    }
  }),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(LifeColumnContent)
