import styled, {css} from 'react-emotion'
import {responsive} from '../../../../../core/style'
import Field from '../../../../share/Field'
import Button from '../../../../share/Button'

export const FooterFormContainer = styled.div`
  padding-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  background-color: #66b8fd;
  ${props =>
    responsive.mobile(css`
      height: 310px;
    `)};
`
export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 85px;
  margin-bottom: 30px;

  ${props =>
    responsive.mobile(css`
      margin: 0;
      height: 100%;
    `)};
`

export const Unit = styled.span`
  font-size: 16px;
  margin-left: 22px;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
export const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  margin: 0;
  .label {
    line-height: 25px;
    margin: 0;
    min-width: 0;
  }
  .input-text-container {
    width: unset;
  }
  .input-percent-container {
    width: unset;
  }

  input {
    width: 160px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      .label {
        justify-content: flex-start;
        margin-bottom: 15px;
        width: 240px;
      }
      .input-percent-container {
        span {
          left: 130px;
        }
      }
      input {
        width: 240px;
        text-align: center;
        margin-bottom: 20px;
      }
    `)};
`

export const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;

  ${props =>
    responsive.mobile(css`
      width: 240px;
      flex-direction: column;
      margin: 0 auto 10px;
    `)};
`
export const Label = styled.div`
  font-size: 16px;
  padding: 1px 0 0;
  ${props =>
    responsive.mobile(css`
      width: 100%;
      margin-bottom: 15px;
    `)};
`

export const LabelSummary = styled(Label)`
  color: ${props => props.theme.EXTRALIGHTBLUE};
`

export const TextFooter = styled.div`
  width: 550px;
  height: 76px;
  font-size: 16px;
  color: white;
  text-align: center;
  ${props =>
    responsive.mobile(css`
      height: unset;
      width: 100%;
      padding: 0 20px;
    `)};
`
export const CustomButton = styled(Button)`
  width: 169px;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 30px;
  height: 38px;
  background-color: white;
  color: #66b8fd;

  :hover {
    color: ${props => (props.type === 'border' ? 'white' : '#66b8fd')};
    background-color: ${props => (props.type === 'border' ? 'white' : 'white')};
    border: ${props =>
      props.type === 'border' ? `${'white'} 1px solid` : 'unset'};
    .icon {
      width: 12px !important;
      margin-left: 10px;
      opacity: 1;
    }
  }
  :focus {
    color: ${props => (props.type === 'border' ? 'white' : '#66b8fd')};
    background-color: ${props => (props.type === 'border' ? 'white' : 'white')};
    border: ${props =>
      props.type === 'border' ? `${'white'} 1px solid` : 'unset'};
  }
  ${props =>
    responsive.mobile(css`
      margin-top: 20px;
    `)};
`
