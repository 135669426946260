import React from 'react'
import styled from 'react-emotion'

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 0 auto;
  padding: 0 24px;
  .Header {
    font-weight: bold;
    text-align: center;
  }
  .Header.Main {
    display: block;
  }
  .Header.Sub {
    display: none;
  }
  .Header.Minor {
    display: none;
  }
  .Topic {
    font-weight: bold;
  }
  .Detail {
    text-align: justify;
  }
  .Tab {
    padding-right: 60px;
  }
  @media (max-width: 1024px) {
    max-width: 746px;
    font-size: 18px;
    .Header.Main {
      display: none;
    }
    .Header.Sub {
      display: block;
    }
    .Header.Minor {
      display: none;
    }
  }
  @media (max-width: 560px) {
    max-width: 380px;
    padding: 0px;
    font-size: 16px;
    .Header.Main {
      display: none;
    }
    .Header.Sub {
      display: none;
    }
    .Header.Minor {
      display: block;
    }
  }
`

const Consent = () => (
  <Container>
    <p className="Header Main">
      รายละเอียดนโยบายของ บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท
      ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์
      <br />
      โบรคเกอร์ จำกัด บริษัท แคสแมท จำกัด และ บริษัท ทีเจเอ็น อินชัวร์รันส์
      โบรกเกอร์ จำกัด
      <br />
      เกี่ยวกับการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของลูกค้า
      <br />
      กรุณาอ่านและ/หรือทำความเข้าใจในเงื่อนไข และข้อตกลงในการใช้บริการ
      ก่อนกดปุ่ม “ยอมรับ”
    </p>
    <p className="Header Sub">
      รายละเอียดนโยบายของ บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด
      <br />
      บริษัท ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท แคสแมท จำกัด
      <br />
      และ บริษัท ทีเจเอ็น อินชัวร์รันส์ โบรกเกอร์ จำกัด
      <br />
      เกี่ยวกับการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของลูกค้า
      <br />
      กรุณาอ่านและ/หรือทำความเข้าใจในเงื่อนไข และข้อตกลงในการใช้บริการ
      <br />
      ก่อนกดปุ่ม “ยอมรับ”
    </p>
    <p className="Header Minor">
      รายละเอียดนโยบายของ บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท
      ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์
      <br />
      จำกัด บริษัท แคสแมท จำกัด
      <br />
      และ บริษัท ทีเจเอ็น อินชัวร์รันส์ โบรกเกอร์ จำกัด
      <br />
      เกี่ยวกับการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของลูกค้า
      <br />
      กรุณาอ่านและ/หรือทำความเข้าใจในเงื่อนไข และข้อตกลงในการใช้บริการ
      ก่อนกดปุ่ม “ยอมรับ”
    </p>
    <br />
    <p className="Topic" style={{alignSelf: 'flex-start'}}>
      การเคารพสิทธิในความเป็นส่วนตัวของท่าน
    </p>
    <div className="Detail">
      <span className="Tab" />
      บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท ทีคิวเอ็ม ไลฟ์
      อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท แคสแมท จำกัด และ บริษัท ทีเจเอ็น
      อินชัวร์รันส์ โบรกเกอร์ จำกัด
      มีความตระหนักดีว่าท่านมีความประสงค์ที่จะได้รับความปลอดภัยสูงสุดในสิทธิความเป็นส่วนตัวของท่าน
      บริษัทฯ จึงให้ความสำคัญ และ เคารพสิทธิในความเป็นส่วนตัว ของท่าน
      ในการดำเนินการเก็บรวมรวม ใช้ ประมวลผลและเปิดเผยข้อมูลส่วนบุคคลของท่าน
      ที่สามารถระบุตัวตนของ ท่านได้ อาจรวมถึงชื่อ สัญชาติ
      ที่อยู่ข้อมูลของลักษณะที่อยู่อาศัยหมายเลขโทรศัพท์ หมายเลขโทรสาร รายละเอียด
      บัญชีธนาคาร รายละเอียดบัตรเครดิต ความสนใจส่วนบุคคล ที่อยู่ E-mail Address
      ภาพถ่ายของท่าน หมายเลขประจำตัวที่ราชการออกให้แก่ท่าน ข้อมูลชีวภาพ
      เชื้อชาติ วันเกิด สถานะการสมรสข้อมูลของสมาชิกในครอบครัวศาสนา ข้อมูลสุขภาพ
      ข้อมูลยานพาหนะ และการประกันภัย
      โดยบริษัทฯจะดำเนินการโดยใช้มาตรการที่เข้มงวดในการรักษาความปลอดภัยตามวัตถุประสงค์ที่ท่านได้ให้ความยินยอมไว้เท่านั้น
      ตลอดจนป้องกันมิให้มีการนำข้อมูลส่วนบุคคลของท่านไปใช้โดยมิได้รับการอนุญาตจากท่านก่อน
    </div>
    <br />
    <p className="Topic" style={{alignSelf: 'flex-start'}}>
      การเก็บรวบรวมข้อมูลส่วนบุคคล
    </p>
    <div style={{paddingLeft: '60px'}}>
      <div className="Topic">
        วัตถุประสงค์ และ
        การให้ข้อมูลส่วนบุคคลเพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือมีความจำเป็นต้องให้ข้อมูลส่วนบุคคลเพื่อเข้าทำสัญญา
      </div>
      <div className="Detail">
        <span className="Tab" />
        บริษัทฯ มีวัตถุประสงค์ขอเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        เพื่อวัตถุประสงค์การตรวจสอบ การวิเคราะห์ การประมวลผล การบริหารจัดการ
        และใช้ข้อมูลของท่านเท่าที่จำเป็นและเหมาะสม
        หรือตามที่เห็นว่าจะเป็นประโยชน์แก่ท่าน
        สำหรับข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน เช่น ชื่อ นามสกุล เพศ
        เชื้อชาติ ที่อยู่ เบอร์โทรศัพท์ วัน เดือน ปีเกิด
        หมายเลขบัตรประจำตัวประชาชน สถานะการสมรส ข้อมูลด้านสุขภาพ
        หรือความเชื่อทางศาสนา เป็นต้น
        เพื่อปฏิบัติตามกฎหมายหรือสัญญาหรือมีความจำเป็นต้องให้ข้อมูลส่วนบุคคลเพื่อการเข้าทำสัญญาตามความจำเป็นเท่านั้น
        <br />
        <span className="Tab" />
        บริษัทฯ จะดำเนินการใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบุไว้อย่างระมัดระวัง
        และใช้มาตรการกำกับดูแลการปฏิบัติตามเงื่อนไขและข้อกำหนด นโยบาย
        และจรรยาบรรณของบริษัทฯ ในการดำเนินการปฏิบัติ ตรวจสอบ ป้องกัน
        และดำเนินคดีตามกฎหมาย
        รวมถึงการใช้ข้อมูลส่วนบุคคลเพื่อแก้ปัญหาด้านการสนับสนุนลูกค้า
        <br />
        <span className="Tab" />
        สำหรับวัตถุประสงค์ทางกฎหมาย บริษัทฯ อาจใช้ข้อมูลส่วนบุคคลที่บริษัทฯ
        เก็บรวบรวมเพื่อสอบสวนและยุติคำร้องเรียน หรือระงับข้อพิพาท
        หรือตามที่ได้รับอนุญาตหรือต้องกระทำตามกฎหมายที่บังคับใช้ บริษัทฯ
        อาจใช้ข้อมูลส่วนบุคคลของท่านเมื่อบริษัทฯ ต้องได้รับคำปรึกษา
        ได้รับคำแนะนำ หรือถูกร้องขอให้กระทำเช่นนั้น โดยที่ปรึกษาทางกฎหมาย
        หรือโดยหน่วยงานทางกฎหมาย รัฐบาล
        หรือหน่วยงานราชการอื่นทั้งในประเทศและต่างประเทศ
      </div>
      <br />
      <div className="Topic">ข้อมูลที่ขอจัดเก็บ บริษัทฯ ขอจัดเก็บ</div>
      <div className="Detail">
        <span className="Tab" />
        และใช้ข้อมูลของท่านตามที่ปรากฎในหน้าเว็บขายประกันประเภทต่าง ๆ
        ตามที่ท่านให้ความสนใจ โดยข้อมูลที่ขอเก็บ รวบรวม ใช้ และเปิดเผย
        ดังรายละเอียดที่ให้ท่านกรอกข้อมูลบนหน้าเว็บดังกล่าว
      </div>
      <br />
      <div className="Topic">
        ระยะเวลาในการเก็บ รวบรวม ใช้ และ เปิดเผยข้อมูล
      </div>
      <div className="Detail">
        <span className="Tab" />
        เป็นเวลา 10 ปี นับจากวันที่ได้รับความยินยอม
      </div>
      <br />
      <div className="Topic">
        ความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านที่เก็บรวบรวมไว้ให้แก่บุคคลหรือหน่วยงานอื่น
      </div>
      <div className="Detail">
        <span className="Tab" />
        บริษัทฯ
        อาจมีความจำเป็นต้องดำเนินการเปิดเผยข้อมูลส่วนบุคคลของท่านที่เก็บรวบรวมไว้ให้แก่บุคคลหรือหน่วยงานอื่นเฉพาะกลุ่มบริษัทในเครือ
        บริษัทลูก บริษัทที่เกี่ยวข้องกัน และ
        บริษัทที่อยู่ภายใต้การควบคุมเดียวกัน รวมถึงการดำเนินธุรกิจของบริษัทฯ
        ไปยังตัวแทน ผู้ค้า ผู้จัดหา พาร์ทเนอร์ ผู้รับจ้าง เท่านั้น
      </div>
      <br />
      <div className="Topic">สิทธิที่เกี่ยวกับข้อมูลของท่าน</div>
      <div className="Detail">
        <span className="Tab" />
        ท่านสามารถใช้สิทธิที่เกี่ยวกับข้อมูลของท่านโดยใช้ความเป็นของเจ้าของข้อมูลส่วนบุคคลของท่านตามที่บริษัทฯ
        แจ้งท่านไว้ในหนังสือฉบับนี้
      </div>
      <ol className="Detail" style={{paddingLeft: '80px', marginBottom: '0'}}>
        <li>สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลของท่าน</li>
        <li>
          สิทธิการขอให้บริษัทฯ
          ส่งข้อมูลของท่านไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น
        </li>
        <li>
          สิทธิการขอคัดค้าน การเก็บ รวบรวม ใช้ หรือเปิดเผยข้อมูลใดๆ
          ที่เคยอนุญาตไว้ก่อนหน้านั้นแล้ว
          เจ้าของข้อมูลมีสิทธิ์ขอคัดค้านภายหลังได้
        </li>
        <li>
          สิทธิขอให้บริษัทฯ ดำเนินการลบหรือทำลายข้อมูลของท่าน
          เว้นแต่กรณีที่บริษัทฯ
          ต้องปฏิบัติตามกฎหมายที่เกี่ยวข้องในการเก็บรักษาข้อมูลดังกล่าว
        </li>
        <li>
          สิทธิขอให้บริษัทฯ ดำเนินการแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์
          และทำให้ข้อมูลเป็นปัจจุบัน
        </li>
        <li>
          สิทธิการขอระงับใช้ข้อมูล เพื่อไม่ให้บริษัทฯ นำข้อมูลของท่านไปประมวลผล
          เว้นแต่กรณีที่บริษัทฯ
          มีสิทธิประมวลผลข้อมูลของท่านต่อไปเพื่อปฏิบัติหน้าที่ตามสัญญาระหว่างบริษัทและท่าน
          หรือโดยอาศัยสิทธิอันชอบด้วยกฎหมายอื่น
        </li>
      </ol>
      <br />
      <div className="Topic">สิทธิเพื่อการถอนความยินยอม</div>
      <div className="Detail">
        <span className="Tab" />
        ท่านสามารถใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลจะถอนความยินยอมเมื่อใดก็ได้
        โดยท่านสามารถแจ้งมายังสถานที่ติดต่อ หรือวิธีการติดต่อที่บริษัทฯ
        แจ้งท่านไว้ในหนังสือฉบับนี้
      </div>
      <br />
      <div className="Topic">สถานที่ติดต่อ และวิธีการติดต่อ</div>
      <div className="Detail">
        <span className="Tab" />
        ในกรณีที่ท่านต้องการความช่วยเหลือจากบริษัทฯ
        ไม่ว่าในกรณีเกี่ยวกับข้อมูลเพิ่มเติม ความผิดพลาด ข้อสงสัยใดๆ
        ที่เกิดขึ้นโดยไม่ได้รับอนุญาตจากท่าน ท่านสามารถติดต่อบริษัทฯ
        ได้ดังนี้&nbsp;
        <a href="https://www.tqm.co.th/">https://www.tqm.co.th/</a> หรือทางอีเมล
        <a
          href="mailto:dpo@tqm.co.th"
          style={{textDecoration: 'none', color: 'black'}}>
          : dpo@tqm.co.th
        </a>
      </div>
    </div>
    <br />
    <p className="Topic" style={{alignSelf: 'flex-start'}}>
      การใช้ข้อมูลส่วนบุคคล
    </p>
    <div className="Detail">
      <span className="Tab" />
      บริษัทฯ
      จะใช้ข้อมูลของท่านเพื่อประกอบการดำเนินธุรกิจเพื่อประโยชน์ของท่านเพื่อวัตถุประสงค์เพื่อส่งเสริมการขาย
      หรือการประชาสัมพันธ์ผลิตภัณฑ์และบริการต่างๆ หรือสิทธิประโยชน์
      หรือข้อเสนอพิเศษต่างๆ ของผลิตภัณฑ์หรือบริการต่างๆ
    </div>
    <br />
    <p className="Topic" style={{alignSelf: 'flex-start'}}>
      การขอความยินยอมเพื่อดำเนินการเก็บรวมรวม ใช้
      ประมวลผลและเปิดเผยข้อมูลส่วนบุคคลของท่าน
    </p>
    <div className="Detail">
      <span className="Tab" />
      การขอความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
      บริษัทฯ
      ขอทราบคุณสมบัติของผู้ให้ข้อมูลเพื่อให้มั่นใจว่าท่านสามารถให้ข้อมูลส่วนบุคคลได้ตามที่กฎหมายกำหนด
      <br />
      <span className="Tab" />
      ข้าพเจ้าเป็นเจ้าของข้อมูลส่วนบุคคลสามารถให้ข้อมูลของตนได้
      และมิได้เป็นผู้เยาว์ที่มีอายุไม่เกินสิบปี หรือเป็นคนไร้ความสามารถ
      หรือเป็นคนเสมือนไร้ความสามารถ
      <br />
      <span className="Tab" />
      ในกรณีถ้าเจ้าของข้อมูลส่วนบุคคลเป็นผู้เยาว์มีอายุไม่เกินสิบปี
      บริษัทฯได้ขอความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์หรือเป็นคนไร้ความสามารถ
      บริษัทฯได้ขอความยินยอมจากผู้อนุบาลที่มีอำนาจกระทำการแทนคนไร้ความสามารถ
      หรือเป็นคนเสมือนไร้ความสามารถบริษัทฯได้ขอความยินยอมจากผู้พิทักษ์ที่มีอำนาจกระทำการแทนคนเสมือนไร้ความสามารถแล้ว
    </div>
    <br />
    <p className="Topic" style={{alignSelf: 'flex-start'}}>
      นโยบายความเป็นส่วนตัว
    </p>
    <div className="Detail">
      <span className="Tab" />
      ข้าพเจ้าผู้สมัคร/ผู้ใช้บริการได้อ่าน
      และเข้าใจข้อกำหนดและเงื่อนไขดังกล่าวข้างต้นโดยตลอดแล้ว
      และขอตกลงยินยอมให้บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด บริษัท
      ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์บริษัท แคสแมท จำกัด และบริษัท
      ทีเจเอ็น อินชัวร์รันส์ โบรกเกอร์ จำกัด เก็บรวบรวม ใช้
      และ/หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
      ภายใต้ข้อกำหนดและเงื่อนไขดังกล่าวข้างต้น
    </div>
    <br /> <br /> <br />
  </Container>
)

export default Consent
