import React from 'react'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../../../core/style'

const Panel = styled.div`
  width: 700px;
  border: 1px solid #66b8fd;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 56px;

  ${props =>
    responsive.mobile(css`
      width: 280px;
    `)};
`
const FormContainer = styled.div`
  margin: 36px 0 22px;
`

const PanelForm = ({children}) => (
  <Panel>
    <FormContainer>{children[0]}</FormContainer>
    {children[1]}
  </Panel>
)

export default PanelForm
