import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {compose, withState} from 'recompose'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'
import withSEO from '../../../share/withSEO'

import Banner from '../../../../static/images/tqm-life/768_300@2x.png'
import BannerMobile from '../../../../static/images/tqm-life/mobile320_300@2x.png'

import Navigator from '../../Navigator'
import Button from '../../../share/Button'
import Tooltip from '../../../share/Tooltip'
import {fadeIn, responsive} from '../../../../core/style'
import {url2Obj} from '../../../../core/helper'
import Form from './Form'

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: transparent;
        border-bottom-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 260px;
        background-color: #7f7f7f;
      }
    }
  }
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  min-height: 460px;

  /* right: calc(50% - 400px); */
  /* top: 80px; */
  min-width: 397px;

  ${props =>
    responsive.tablet(css`
      /* right: calc(50% - 360px); */
      position: unset;
    `)};

  ${props =>
    responsive.mobile(css`
      /* right: calc(50% - 148px); */
      position: unset;
      min-width: 296px;
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 37px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 18px;
    `)};
`

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const NoonSmartBanner = styled.div`
  display: flex;
  justify-content : center ;
  align-items: flex-start;
  position: relative;
  /* background-image: url(${Banner}); */
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: hidden;
  ${props =>
    responsive.mobile(css`
      /* background-image: url(${BannerMobile}); */
      /* height: calC(100vh - 80px); */
      height: auto;
      background-position-x: calc(50% - 80px);
      position: unset;
    `)};

  ${props =>
    responsive.tablet(css`
      height: auto;
      background-position-x: calc(50% - 80px);
      position: unset;
    `)}
`

const ContainerBox = styled.div`
  position: relative;
  width: 100%;
  /* max-width: 350px; */
  max-width: 585px;
  min-height: 295px;
  margin-top: 35px;
  margin-bottom: 70px;
  ${props =>
    responsive.mobile(css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 300px;
      min-height: 450px;
      /* position: unset; */
    `)};
/*
  ${props =>
    responsive.tablet(css`
      display: flex;
      height: auto;
      background-position-x: calc(50% - 80px);
    `)} */
`
const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.expand && props.theme.GREY80};
  animation: ${fadeIn} 2s;
`

const BoxOnce = styled.div`
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  width: ${props => (props.expand ? '100%' : '190px')};

  height: ${props => (props.expand ? '450px' : '140px')};
  z-index: ${props => (props.expand ? 1 : 0)};
  top: ${props => (props.expand ? '0' : props.top)};
  left: ${props => (props.expand ? '0' : props.left)};

  transition: all
      ${props =>
        props.expand
          ? props.transition
            ? props.expanding
              ? '0.5s'
              : '0s'
            : '0.5s'
          : '0.5s'},
    z-index
      ${props => (!props.transition ? (props.expand ? '1ms' : '1s') : '0s')},
    background 0s, color 0s;

  border-radius: 5px;
  border: ${props =>
    props.expand && `1px solid ${props.theme.EXTRALIGHTORANGE}`};
  background-color: ${props => (props.expand ? 'white' : '#66B8FD')};
  .shield-alt {
    /* right: ${props => props.expand && -75}px; */
  }
  .coins {
    /* right: ${props => props.expand && -64}px; */
  }
  .hand-holding-heart {
    /* right: ${props => (props.expand ? -92 : -45)}px; */
  }
  .child {
    /* right: -13px; */
  }
  .briefcase {
    /* right: ${props => props.expand && -56}px; */
  }
  .piggy-bank {
    /* right: ${props => (props.expand ? -90 : -48)}px; */
  }
  :hover {
    cursor: ${props => !props.expand && 'pointer'};
    background-color: ${props => !props.expand && '#008AFC'};
    color: ${props => !props.expand && 'white'};
    .shield-alt,
    .coins,
    .hand-holding-heart,
    .child,
    .briefcase,
    .piggy-bank {
      color: ${props => !props.expand && 'rgba(178, 117, 0, 0.3)'};
    }
  }

  ${props =>
    responsive.mobile(css`
      width: ${props.expand ? '100%' : '200px'};
      height: ${props.expand ? '100%' : '162px'};
      top: ${props.expand ? '0' : props.topMobile};
      left: ${props.expand ? '0' : props.leftMobile};

      margin-bottom: 5px;
      position: unset;

      .shield-alt {
        /* right: ${props.expand && -90}px; */
      }
      .coins {
        /* right: ${props.expand && -110}px; */
      }
      /* .hand-holding-heart {
        right: ${props.expand && -100}px; */
        /* top: ${props.expand && 95}px; */
      }
      .child {
        /* right: ${props.expand && -45}px; */
      }
      .briefcase {
        /* right: ${props.expand && -106}px; */
      }
      .piggy-bank {
        /* right: ${props.expand && -116}px; */
      }
    `)};
`

const IconContainer = styled.div`
  position: absolute;
  top: ${props => (props.expand ? 38 : 25)}px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s, top 0s;
  ${props =>
    responsive.mobile(css`
      display: none;
      position: unset;
    `)};
`

const IconContainerMobile = styled(IconContainer)`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 16px;
    `)};
`
const ArrowLeft = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 5px;
`
const InfoTop = styled.div`
  margin-top: 10px;
  font-size: ${props => (props.expand ? 16 : 12)}px;
  ${props =>
    responsive.mobile(css`
      margin: 0;
    `)};
`
const InfoMiddle = styled.div`
  font-size: ${props => (props.expand ? 16 : 12)}px;
`
const InfoBottom = styled.div`
  font-size: ${props => (props.expand ? 16 : 12)}px;
`
const ContainerHastag = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`
const HeaderHastag = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  margin-top: 130px;
  font-size: 12px;
  ${props =>
    responsive.mobile(css`
      margin-top: 65px;
    `)};
`

const Hastag = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 80px;
  padding: 4px 15px 3px;
  border-radius: 5px;
  margin: 7px 5px 0;
  background-color: ${props => props.theme.GREY10};
  color: ${props => props.theme.GREY50};
  font-size: 12px;
`
const ContainerChevron = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: absolute;
  width: 290px;
  height: 20px;
  left: 55px;
  top: 76px;
  animation: ${fadeIn} 2s;

  ${props =>
    responsive.mobile(css`
      width: 228px;
      left: 37px;
    `)};
`
const ChevronArrow = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => props.theme.ORANGE};
  cursor: pointer;
`

const ContainerButton = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 22px;
`

const CustomButton = styled(Button)`
  width: 150px;
  height: 30px;
  border-radius: 15px;
  font-size: 12px;
  border: unset;
  margin: 0 15px;
`

const Back = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 26px;
  font-size: 16px;
  color: ${props => props.theme.GREY50};
  position: absolute;
  left: 18px;
  top: 14px;
  z-index: 1;
  cursor: pointer;
  animation: ${fadeIn} 2s;
`
const NoonSmartBannerPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border : unset;
  position: relative;
  background-image: url(${Banner});
  width: 768px;
  height: 300px;
  background-size: cover;
  background-position-x: center;


  ${props =>
    responsive.mobile(css`
      width: 100%;
      background-image: url(${BannerMobile});

      /* height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px); */
    `)};

    @media  (max-width: 400px) {
        width: 300px;
      }
  /* overflow: scroll; */
  /* ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)}; */


`

const BannerIconBackground = styled.span`
  display: flex;
  float: left;
  align-items: center;
  justify-content: center;
  background-color: #008afc;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 12px;
`
const ContentSelect = styled.div`
  background-image: url(${Banner});
  background-size: cover;
  width: 768px;
  height: 300px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 425px) {
    background-image: url(${BannerMobile});
    background-repeat: no-repeat;
    background-size: cover;
    height: 383px;
    justify-content: center;
  }
`
const WrapTextSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0;
  padding: 20px;
  @media (max-width: 425px) {
    display: flex;
    margin: 0px;
    justify-content: flex-start;
  }
`
const TitleContent = styled.span`
  width: 100%;
  font-size: 30px;
  color: #008afc;
  font-weight: 400;
  @media (max-width: 425px) {
    width: auto;
    font-size: 20px;
    .selectTitle {
      margin-top: 10px;
    }
  }
`
const BannerIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 20px;
`
const BannerText = styled.div`
  font-size: 30px;
  color: #008afc;
  ${props =>
    responsive.mobile(css`
      font-size: 24px;
      width: 300px;
    `)};
`
const BannerSubText = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 372px;
  height: 50px;
  font-size: 16px;
  color: #666666;
  margin-top: 10px;
  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      width: 300px;
    `)};
`

const BoxList = [
  {
    iconTag: 'fas',
    iconName: 'shield-alt',
    infoTop: 'วางแผนปกป้อง',
    infoBottom: 'ภาระที่อาจจะตกถึงครอบครัว',
    infoTopTablet: 'วางแผนปกป้องภาระที่อาจจะตกถึงครอบครัว',
    infoBottomTablet: 'ที่อาจจะตกถึงครอบครัว',
    infoTopMobile: 'วางแผนปกป้องภาระ',
    infoBottomMobile: 'ที่อาจจะตกถึงครอบครัว',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'ระยะเวลา'}],
    type: 'DebtProtection',
  },
  {
    iconTag: 'fas',
    iconName: 'coins',
    infoTop: 'วางแผนเพื่อสร้างความคุ้มครอง',
    infoBottom: 'ด้านรายได้ของครอบครัว',
    infoTopTablet: 'วางแผนเพื่อสร้างความความคุ้มครองด้านรายได้ของครอบครัว',
    infoMidTablet: 'ความคุ้มครองด้าน',
    infoBottomTablet: 'รายได้ของครอบครัว',
    infoTopMobile: 'วางแผนเพื่อสร้างความ',
    infoMidMobile: 'ความคุ้มครองด้าน',
    infoBottomMobile: 'รายได้ของครอบครัว',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'สะสมทรัพย์'}, {name: 'ชั่วระยะเวลา'}],
    type: 'FamilyIncomeProtection',
    left: '205px',
    leftMobile: '155px',
  },
  {
    iconTag: 'fas',
    iconName: 'hand-holding-heart',
    infoTop: 'วางแผนเงินเกษียณ',
    infoBottom: 'ที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า',
    infoTopTablet: 'วางแผนเงินเกษียณที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า',
    infoBottomTablet: 'ดูแลเราไปจนแก่เฒ่า',
    infoTopMobile: 'วางแผนเงินเกษียณ',
    infoMidMobile: 'ที่ใช้อยู่ดูแลเรา',
    infoBottomMobile: 'ไปจนแก่เฒ่า',
    hasTag: [{name: 'สะสมทรัพย์'}, {name: 'บำนาญ'}],
    type: 'Retirement',

    // top: 'calc(100% - 140px)',
    left: '410px',
    topMobile: 'calc(100% - 295px)',
    leftMobile: '0',
  },
  {
    iconTag: 'fas',
    iconName: 'child',
    infoTop: 'วางแผนคุ้มครอง',
    infoBottom: 'ค่าใช้จ่ายการศึกษาบุตร',
    infoTopTablet: 'วางแผนคุ้มครองค่าใช้จ่ายการศึกษาบุตร',
    infoBottomTablet: 'ค่าใช้จ่ายการศึกษาบุตร',
    infoTopMobile: 'วางแผนคุ้มครอง',
    infoBottomMobile: 'ค่าใช้จ่ายการศึกษาบุตร',
    hasTag: [{name: 'สะสมทรัพย์'}],
    type: 'Child',

    top: 'calc(100% - 140px)',
    // left: '205px',
    topMobile: 'calc(100% - 295px)',
    leftMobile: '155px',
  },
  {
    iconTag: 'fas',
    iconName: 'briefcase',
    infoTop: 'วางแผนลดหย่อน',
    infoBottom: 'ภาษีด้วยประกัน',
    infoTopTablet: 'วางแผนลดหย่อนภาษีด้วยประกัน',
    infoBottomTablet: 'ด้วยประกัน',
    infoTopMobile: 'วางแผนลดหย่อน',
    infoBottomMobile: 'ภาษีด้วยประกัน',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'สะสมทรัพย์'}, {name: 'บำนาญ'}, ,],
    type: 'TaxReduction',
    left: '205px',
    // top: 'calc(100% + 15px)',
    top: 'calc(100% - 140px)',

    topMobile: 'calc(100% - 140px)',
    leftMobile: '0',
  },
  {
    iconTag: 'fas',
    iconName: 'piggy-bank',
    infoTop: 'วางแผนสะสมเงิน',
    infoBottom: 'พร้อมทุนประกัน',
    infoTopTablet: 'วางแผนสะสมเงินพร้อมทุนประกัน',
    infoBottomTablet: 'พร้อมทุนประกัน',
    infoTopMobile: 'วางแผนสะสมเงิน',
    infoBottomMobile: 'พร้อมทุนประกัน',
    hasTag: [{name: 'สะสมทรัพย์'}],
    type: 'Saving',

    // left: '205px',
    left: '410px',
    top: 'calc(100% - 140px)',
    // top: 'calc(100% + 15px)',
    top: 'calc(100% - 140px)',
    topMobile: 'calc(100% - 140px)',
    leftMobile: '155px',
  },
]

const Box = ({
  iconName,
  infoTop,
  infoTopMobile,
  infoBottom,
  infoBottomMobile,
  infoMidMobile,
  iconTag,
  hasTag,
  setExpand,
  expand,
  type,
  top,
  topMobile,
  left,
  leftMobile,
  transition,
  setTransition,
  expanding,
  setExpanding,
}) => (
  <BoxOnce
    top={top}
    topMobile={topMobile}
    left={left}
    leftMobile={leftMobile}
    className={iconName}
    transition={transition}
    expanding={expanding}
    expand={expand === iconName}
    onClick={() => {
      // setExpand(iconName)
      // setTransition(true)
      // setExpanding(true)
      navigate(`/smart/InsuranceCategory/life/starter?type=${type}`)
    }}>
    <InfoContainerBox expand={expand === iconName}>
      <IconContainer expand={expand === iconName}>
        <CustomIcon icon={[iconTag, iconName]} />
        <InfoTop expand={expand === iconName}>{infoTop}</InfoTop>
        <InfoBottom expand={expand === iconName}>{infoBottom}</InfoBottom>
      </IconContainer>
      <IconContainerMobile expand={expand === iconName}>
        <CustomIcon icon={[iconTag, iconName]} />
        <InfoTop expand={expand === iconName}>
          {iconName === 'shield-alt' || iconName === 'coins'
            ? expand === iconName
              ? infoTop
              : infoTopMobile
            : infoTop}
        </InfoTop>
        <InfoMiddle expand={expand === iconName}>
          {expand !== iconName && iconName === 'coins' && infoMidMobile}
        </InfoMiddle>
        <InfoBottom expand={expand === iconName}>
          {iconName === 'shield-alt' || iconName === 'coins'
            ? expand === iconName
              ? infoBottom
              : infoBottomMobile
            : infoBottom}
        </InfoBottom>
      </IconContainerMobile>
      <HeaderHastag expand={expand === iconName}>
        ประเภทของประกันที่เกี่ยวข้อง
      </HeaderHastag>
      <ContainerHastag expand={expand === iconName}>
        {hasTag.map(i => (
          <Hastag expand={expand === iconName} key={i.name}>
            {i.name}
          </Hastag>
        ))}
      </ContainerHastag>
      <ContainerButton expand={expand === iconName}>
        <CustomTooltip
          placement="bottom"
          title={'ช่องทางสำหรับมือใหม่ซื้อแบบไม่มากไม่น้อยเกินไป'}>
          <CustomButton
            onClick={() =>
              navigate(`/smart/InsuranceCategory/life/starter?type=${type}`)
            }
            icons={['fa', 'search']}>
            คำนวณความคุ้มครอง
          </CustomButton>
        </CustomTooltip>
      </ContainerButton>
    </InfoContainerBox>
  </BoxOnce>
)

const LifeStartLanding = ({
  expand,
  setExpand,
  transition,
  setTransition,
  expanding,
  setExpanding,
  type,
  location: {search},
}) => {
  const BoxIndex = BoxList.findIndex(i => i.iconName === expand)
  if (type === '') {
    return (
      <NoonSmartWrapper>
        <NoonSmartBanner>
          <Container>
            {/* <NoonSmartHeader>แบบประกันที่ใช่</NoonSmartHeader>
            <NoonSubSmartHeader>ใครๆ ก็ชอบ</NoonSubSmartHeader> */}
            {/* <Navigator /> */}
            <ContentSelect className="5555">
              <WrapTextSelect>
                <TitleContent classname="selectTitle">
                  <BannerIconBackground>
                    <BannerIcon icon={('fas', 'list-ol')} />
                  </BannerIconBackground>
                  <BannerText>วางแผนซื้อประกันชีวิต</BannerText>
                </TitleContent>
              </WrapTextSelect>
            </ContentSelect>
            <ContainerBox>
              {BoxList.map((i, index) => (
                <Box
                  key={index}
                  {...i}
                  expand={expand}
                  setExpand={setExpand}
                  transition={transition}
                  setTransition={setTransition}
                  expanding={expanding}
                  setExpanding={setExpanding}
                />
              ))}
              <Back
                expand={expand}
                onClick={() => {
                  setExpand('')
                  setTransition(false)
                  setExpanding(false)
                }}>
                <ArrowLeft icon={['fas', 'angle-left']} />
                กลับ
              </Back>
              <ContainerChevron expand={expand}>
                <ChevronArrow
                  onClick={() =>
                    BoxIndex !== 0
                      ? setExpanding(false) ||
                        setExpand(BoxList[BoxIndex - 1].iconName)
                      : setExpanding(false) ||
                        setExpand(BoxList[BoxList.length - 1].iconName)
                  }
                  icon={['fas', 'chevron-circle-left']}
                />
                <ChevronArrow
                  onClick={() =>
                    BoxIndex !== BoxList.length - 1
                      ? setExpanding(false) ||
                        setExpand(BoxList[BoxIndex + 1].iconName)
                      : setExpanding(false) || setExpand(BoxList[0].iconName)
                  }
                  icon={['fas', 'chevron-circle-right']}
                />
              </ContainerChevron>
            </ContainerBox>
          </Container>
        </NoonSmartBanner>
      </NoonSmartWrapper>
    )
  } else {
    return <Form type={type} BoxList={BoxList} search={search} />
  }
}

const enhancer = compose(
  withSEO(
    'noon Smart Life - ค้นหาประกันชีวิตที่เหมาะสมสำหรับคุณ',
    'noon Smart-Life ค้นหาแบบประกันชีวิตง่ายๆ รายละเอียดครบถ้วน ตามวัตถุประสงค์ที่คุณต้องการ หมดปัญหากับข้อมูลที่ไม่ครบถ้วน เรามีระบบที่ช่วยบอกรายละเอียดเพื่อใช้เป็นเครื่องมือในการหาข้อมูล ก่อนการตัดสินใจซื้อประกันชีวิต เพื่อความคุ้มค่าและความคุ้มครองที่สูงสุด',
    'เครื่องมือค้นหาประกันชีวิต วางแผนประกันชีวิต เลือกซื้อประกันชีวิต,ประกันชีวิต,สะสมทรัพย์,ตลอดชีพ,บำนาญ,ชั่วระยะเวลา,เปรียบเทียบประกันชีวิต',
  ),
  connect(
    (state, props) => ({
      type: url2Obj(props.location.search).type || '',
    }),
    {},
  ),
  withState('expand', 'setExpand', ''),
  withState('transition', 'setTransition', false),
  withState('expanding', 'setExpanding', false),
)

export default enhancer(LifeStartLanding)
