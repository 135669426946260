import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withProps, withHandlers} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {
  addProductCompare,
  deleteProductCompare,
} from '../../../../ducks/product'

const Layout = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => (props.unclickable ? 'not-allowed' : 'pointer')};
  padding-left: 20px;
  min-height: 38px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  > svg {
    margin-right: 4px;
    color: #66b8fd;
  }
  > div {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #66b8fd;
  }

  ${props =>
    props.isAdded &&
    css`
      background-color: #008afc;
      > svg {
        color: #ffffff;
      }
      > div {
        color: #ffffff;
      }
    `};
`

const CompareContainer = props => (
  <Layout
    unclickable={props.compareList.length === 5 && !props.isAdded}
    onClick={props.onClick}
    isAdded={props.isAdded}>
    <FontAwesomeIcon
      icon={props.isAdded ? ['far', 'check-square'] : ['far', 'square']}
    />
    <div>เลือกเพื่อเปรียบเทียบ</div>
  </Layout>
)

const enhance = compose(
  connect(
    (state, props) => ({
      compareList: state.product.compareList,
    }),
    {
      addProductCompare,
      deleteProductCompare,
    },
  ),
  withProps(props => {
    const isAdded = props.compareList.some(data => data.id === props.data.id)
    const selectedIndex = props.compareList.findIndex(
      data => data.id === props.data.id,
    )
    return {
      isAdded: isAdded,
      selectedIndex: selectedIndex,
    }
  }),
  withHandlers({
    onClick: props => () => {
      if (props.isAdded) {
        props.deleteProductCompare(props.selectedIndex, props.compareList)
      } else {
        const companyOfficialLogoUrl = props.data.companyOfficialLogoUrl
        const companyName = props.data.companyName
        const productName = props.data.productName
        const premium = props.data.premium
        const id = props.data.id
        const promotionCode = props.data.promotionCode
        const amountNet = props.data.amountNet

        props.compareList.length < 5 &&
          props.addProductCompare({
            companyOfficialLogoUrl,
            companyName,
            productName,
            premium,
            id,
            promotionCode,
            amountNet,
          })
      }
    },
  }),
)

export default enhance(CompareContainer)
