import React from 'react'
import styled, {css} from 'react-emotion'
import {Field} from 'redux-form'
import {compose, withState, withHandlers} from 'recompose'

import moment from '../../../core/moment-buddhist'
import TextInput from './TextInput'
import TextInputPercent from './TextInputPercent'
import CustomFileInput from './FileInput'
import {CustomTextArea, TextAreaAutoResize} from './TextArea'
import CustomSelect from './Selector'
import BirthDatePicker from './BirthDatePicker'
import RangePicker from './RangePicker'
import Address from './Address'
import Slider from './Slider'
import GoogleTextInput from './GoogleTextInput'
import GoogleSelector from './GoogleSelector'
import DatePicker from './DatePicker'
import {PasswordInput, ShowPassword, ShowPasswordIcon} from './Password'
import {CheckboxContainer, CheckboxInput, CustomCheckbox} from './Checkbox'
import {RadioButtonLabel, CustomRadioGroup} from './RadioInput'
import Switch from './Switch'
import Currency from './Currency'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {displayNumber, addressStructure} from '../../../core/helper'
import {responsive} from '../../../core/style'
import {calendarCss} from './style'

const CompanyLogo = styled.img`
  height: 20px;
  width: 20px;
  margin: 0 10px 1px 0;

  border-radius: 5px;
`

export const FieldContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 0 0 15px;

  color: white;

  > .input-birthdate-container,
  > .input-date-container {
    > div {
      width: 100%;
      > div {
        width: 100%;
        > .react-datepicker__input-container {
          width: 100%;
        }
      }
    }
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 150px;

  font-size: 16px;
  line-height: 20px;
  margin-right: 25px;
  font-family: ${props => props.theme.headerFont};

  color: black;
`

export const InputContainer = styled.div`
  width: 100%;

  ${prop =>
    responsive.mobile(css`
      display: flex;
      justify-content: left;
    `)};
`

export const ErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: -20px;
`

export const ErrorText = styled.span`
  position: absolute;
  white-space: nowrap;

  width: 100%;
  margin-top: 2px;

  color: #ff0034;

  font-size: 12px;
  text-align: left;
`
const Red = styled.span`
  color: ${props => props.theme.RUBY};
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => props.theme.PINKRED};
  margin-right: 5px;
`

export const RenderField = ({
  className,
  input,
  type,
  label,
  placeholder,
  options = [],
  hidden,
  disabled,
  hideError,
  meta: {touched, error},
  errorOpacity = 0.5,
  errorLabelColored = true,
  asyncError,
  readOnly,
  errorColor = 'red',
  searchable = false,
  isShowPassword,
  isPasswordDisplayed,
  togglePasswordDisplay,
  inputChangeState,
  inputChangeStateUpdater,
  showPasswordHandler,
  isNoWrap = true,
  multi = false,
  isRequire = false,
  currencyStep = 50000,
  normalize = () => {},
  didChange = () => {},
  min = 0,
  max = 100000000,
  isNegation = false,
  themeColor,
  onBlurInput,
  isCustomOption = false,
  onEnter,
  mode,
  errorMessage,
  autoTextarea = true,
  autoComplete = 'on',
  filterOptions,
  lang = 'th',
  ...otherProps
}) => (
  <FieldContainer className={className} hidden={hidden} type={type}>
    {label && type !== 'checkbox' && (
      <Label className="label" htmlFor={input.name}>
        {label}
        {isRequire && <Red>*</Red>}
      </Label>
    )}
    <InputContainer className={`input-${type}-container`}>
      {(() => {
        switch (type) {
          case 'currency':
            return (
              <Currency
                {...input}
                min={min}
                max={max}
                step={currencyStep}
                placeholder={placeholder}
                onChange={value => {
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
                onBlur={e => {
                  const value = e.target.value
                  if (onBlurInput) {
                    if (inputChangeState === true) {
                      setTimeout(() => didChange(value))
                    } else {
                      inputChangeStateUpdater(false)
                    }
                    input.onBlur(value)
                  } else {
                    input.onBlur(value)
                  }
                }}
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onKeyUp={e => e.key === 'Enter' && e.preventDefault()}
              />
            )
          case 'checkbox':
            return (
              <CheckboxContainer className="checkboxContainer">
                <CheckboxInput
                  options={options}
                  {...input}
                  checked={isNegation ? !input.value : input.value}
                  id={input.name}
                  readOnly={readOnly}
                  errorColor={errorColor}
                  errorOpacity={errorOpacity}
                  type="checkbox"
                  className="checkbox"
                  onChange={e => {
                    setTimeout(() => didChange(e))
                    input.onChange(
                      isNegation ? !e.target.checked : e.target.checked,
                    )
                  }}>
                  <RadioButtonLabel className="label" htmlFor={input.name}>
                    {label}
                  </RadioButtonLabel>
                </CheckboxInput>
              </CheckboxContainer>
            )
          case 'checkboxes':
            return (
              <CustomCheckbox
                {...input}
                value={
                  input.value instanceof Array ? input.value : [input.value]
                }
                options={
                  otherProps.checkAll
                    ? [
                        {
                          label: otherProps.labelAll
                            ? otherProps.labelAll
                            : 'เลือกทั้งหมด',
                          value: 'all',
                        },
                        ...options,
                      ]
                    : options
                }
                disabled={disabled}
                onChange={e => {
                  if (otherProps.checkAll) {
                    if (e.includes('all')) {
                      if (!input.value.includes('all')) {
                        const allOptions = [
                          'all',
                          ...options.map(value => value.value),
                        ]
                        setTimeout(() => didChange(allOptions))
                        input.onChange(allOptions)
                      } else {
                        const index = e.findIndex(value => value === 'all')
                        e.splice(index, 1)
                        setTimeout(() => didChange(e))
                        input.onChange(e)
                      }
                    } else if (input.value.includes('all')) {
                      setTimeout(() => didChange([]))
                      input.onChange([])
                    } else if (e.length === options.length) {
                      const allOptions = [
                        'all',
                        ...options.map(value => value.value),
                      ]
                      setTimeout(() => didChange(allOptions))
                      input.onChange(allOptions)
                    } else {
                      setTimeout(() => didChange(e))
                      input.onChange(e)
                    }
                  } else {
                    setTimeout(() => didChange(e))
                    input.onChange(e)
                  }
                }}
              />
            )
          case 'company':
            return (
              <CustomCheckbox
                {...input}
                value={
                  input.value instanceof Array ? input.value : [input.value]
                }
                // options={
                //   otherProps.checkAll
                //     ? [{label: 'เลือกทั้งหมด', value: 'all'}, ...options]
                //     : options
                // }
                disabled={disabled}
                onChange={e => {
                  if (otherProps.checkAll) {
                    if (e.includes('all')) {
                      if (!input.value.includes('all')) {
                        const allOptions = [
                          'all',
                          ...options.map(value => value.value),
                        ]
                        setTimeout(() => didChange(allOptions))
                        input.onChange(allOptions)
                      } else {
                        const index = e.findIndex(value => value === 'all')
                        e.splice(index, 1)
                        setTimeout(() => didChange(e))
                        input.onChange(e)
                      }
                    } else if (input.value.includes('all')) {
                      setTimeout(() => didChange([]))
                      input.onChange([])
                    } else if (e.length === options.length) {
                      const allOptions = [
                        'all',
                        ...options.map(value => value.value),
                      ]
                      setTimeout(() => didChange(allOptions))
                      input.onChange(allOptions)
                    } else {
                      setTimeout(() => didChange(e))
                      input.onChange(e)
                    }
                  } else {
                    setTimeout(() => didChange(e))
                    input.onChange(e)
                  }
                }}>
                {[{label: 'เลือกทั้งหมด', value: 'all'}, ...options].map(
                  (company, index) => (
                    <CheckboxInput value={company.value} key={index}>
                      {company.logo && (
                        <CompanyLogo src={company.logo}></CompanyLogo>
                      )}

                      {company.label}
                    </CheckboxInput>
                  ),
                )}
              </CustomCheckbox>
            )
          case 'switch':
            return (
              <Switch
                {...input}
                lang={lang}
                disabled={disabled}
                onChange={e => {
                  input.onChange(e)
                  setTimeout(() => didChange(e))
                }}
              />
            )
          case 'radio':
            return (
              <input
                type="radio"
                onClick={() => input.onChange(!input.value)}
                checked={input.value}
              />
            )
          case 'radios':
            return (
              <CustomRadioGroup
                {...input}
                options={options}
                onChange={e => {
                  setTimeout(() => didChange(e.target.value))
                  input.onChange(e.target.value)
                }}
              />
            )
          case 'selector':
            return (
              <CustomSelect
                {...input}
                onChange={e => {
                  setTimeout(() => didChange(e.value))
                  input.onChange(e.value)
                }}
                onBlur={() => input.onBlur()}
                filterOptions={filterOptions}
                options={options}
                multi={multi}
                autoload={false}
                searchable={searchable}
                placeholder={placeholder}
                disabled={disabled}
                error={!hideError && touched && error}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                readOnly={readOnly}
                errorOpacity={errorOpacity}
                isNoWrap={isNoWrap}
                themeColor={themeColor}
                isCustomOption={isCustomOption}
              />
            )
          case 'textarea':
            return (
              <CustomTextArea
                {...input}
                rows="4"
                disabled={disabled}
                placeholder={placeholder}
                error={!hideError && touched && error}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                readOnly={readOnly}
                errorOpacity={errorOpacity}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
                onKeyPress={e => {
                  var isShift
                  if (window.event) {
                    isShift = !!window.event.shiftKey
                  } else {
                    isShift = !!e.shiftKey
                  }
                  if (e.key === 'Enter' && !isShift && onEnter) {
                    e.preventDefault()
                    inputChangeStateUpdater(false)
                    onEnter(e.target.value)
                  }
                }}
              />
            )
          case 'autotextarea':
            const name = `auto-${input.name}-textarea`
            return (
              <TextAreaAutoResize
                {...input}
                autosize={autoTextarea}
                className={name}
                disabled={disabled}
                placeholder={placeholder}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
                onKeyPress={e => {
                  var isShift
                  if (window.event) {
                    isShift = !!window.event.shiftKey
                  } else {
                    isShift = !!e.shiftKey
                  }
                  if (e.key === 'Enter' && !isShift && onEnter) {
                    e.preventDefault()
                    inputChangeStateUpdater(false)
                    onEnter(e.target.value)
                  }
                }}
              />
            )
          case 'birthdate':
            return (
              <BirthDatePicker
                {...input}
                className="birthdate"
                calendarClassName={calendarCss}
                dateFormat="DD-MM-BBBB"
                dateFormatCalendar="MMMM BBBB"
                yearFormat="BBBB"
                minDate={moment('01-01-1918', 'DD-MM-YYYY')}
                maxDate={moment()}
                selected={
                  typeof input.value === 'string' && input.value
                    ? moment(input.value, 'DD-MM-BBBB')
                    : input.value
                }
                placeholderText={placeholder}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={disabled}
                onChange={value => {
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
              />
            )
          case 'date':
            return (
              <BirthDatePicker
                {...input}
                className="birthdate"
                calendarClassName={calendarCss}
                dateFormat="MM/DD/YYYY"
                dateFormatCalendar="MMMM BBBB"
                yearFormat="BBBB"
                minDate={moment()}
                selected={
                  typeof input.value === 'string' && input.value
                    ? moment(input.value, 'MM/DD/YYYY')
                    : input.value
                }
                placeholderText={placeholder}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={disabled}
                onChange={value => {
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
              />
            )

          case 'rangeDate':
            return (
              <RangePicker
                {...input}
                placeholder={placeholder}
                format="DD-MM-YYYY"
                locale="th-TH"
                disabled={disabled}
                disabledDate={current =>
                  current && current < moment().startOf('day')
                }
                onBlur={() => input.onBlur()}
                onChange={m => {
                  input.onChange(m)
                  setTimeout(() => didChange(m))
                }}
                showToday={true}
              />
            )
          case 'address':
            return (
              <Address
                input={input}
                didChange={didChange}
                onBlur={e => {}}
                options={addressStructure}
                autoload={false}
                searchable={searchable}
                error={!hideError && touched && error}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                errorOpacity={errorOpacity}
                disabled={disabled}
              />
            )
          case 'datepicker':
            return (
              <DatePicker
                {...input}
                placeholder={placeholder}
                {...otherProps}
              />
            )
          case 'password':
            return [
              <PasswordInput
                {...input}
                key="password"
                className={input.name}
                type="password"
                disabled={disabled}
                placeholder={placeholder}
                error={!hideError && touched && (error || asyncError)}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                errorOpacity={errorOpacity}
                readOnly={readOnly}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
              />,
              <ShowPassword
                key="show-password"
                className="show-password"
                isShowPassword={isShowPassword}
                onClick={() => showPasswordHandler(input.name)}>
                <ShowPasswordIcon
                  name={isPasswordDisplayed ? 'fas fa-eye-slash' : 'fas fa-eye'}
                />
                {isPasswordDisplayed ? `ซ่อนรหัสผ่าน` : `แสดงรหัสผ่าน`}
              </ShowPassword>,
            ]
          case 'file':
            return (
              <CustomFileInput
                input={input}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                error={!hideError && touched && (error || asyncError)}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                errorOpacity={errorOpacity}
                readOnly={readOnly}
                didChange={didChange}
              />
            )
          case 'percent':
            return (
              <TextInputPercent
                {...input}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                normalize={normalize}
                error={!hideError && touched && (error || asyncError)}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                errorOpacity={errorOpacity}
                readOnly={readOnly}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                  inputChangeStateUpdater(true)
                }}
                onBlur={e => {
                  const value = e.target.value
                  if (inputChangeState === true) {
                    setTimeout(() => didChange(value))
                  } else {
                    inputChangeStateUpdater(false)
                  }
                  input.onBlur(value)
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    inputChangeStateUpdater(false)
                    didChange(e.target.value)
                  }
                }}
              />
            )
          case 'slider':
            return (
              <Slider
                {...input}
                {...otherProps}
                value={parseInt(input.value)}
                max={max}
                min={min}
                onChange={value => {
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                }}
              />
            )
          case 'googleInput':
            return (
              <GoogleTextInput
                {...input}
                placeholder={placeholder}
                normalize={normalize}
                mode={mode}
                errorMessage={touched && error}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                  inputChangeStateUpdater(true)
                }}
              />
            )
          case 'googleSelector':
            return (
              <GoogleSelector
                {...input}
                onChange={e => {
                  setTimeout(() => didChange(e.value))
                  input.onChange(e.value)
                }}
                onBlur={() => input.onBlur()}
                options={options}
                multi={multi}
                autoload={false}
                searchable={searchable}
                placeholder={placeholder}
                disabled={disabled}
                errorMessage={touched && error}
                error={!hideError && touched && error}
                errorLabelColored={errorLabelColored}
                errorColor={errorColor}
                readOnly={readOnly}
                errorOpacity={errorOpacity}
                isNoWrap={isNoWrap}
              />
            )
          case 'fundSearch':
            return (
              <TextInput
                {...input}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                normalize={normalize}
                error={!hideError && touched && (error || asyncError)}
                readOnly={readOnly}
                autoComplete={autoComplete}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                  inputChangeStateUpdater(true)
                }}
                onBlur={e => {
                  const value = e.target.value
                  if (onBlurInput) {
                    if (inputChangeState === true) {
                      setTimeout(() => didChange(value))
                    } else {
                      inputChangeStateUpdater(false)
                    }
                    input.onBlur(value)
                  } else {
                    input.onBlur(value)
                  }
                }}
                {...otherProps}
              />
            )
          default:
            return (
              <TextInput
                {...input}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                normalize={normalize}
                error={!hideError && touched && (error || asyncError)}
                readOnly={readOnly}
                autoComplete={autoComplete}
                onChange={e => {
                  const value = e.target.value
                  input.onChange(value)
                  setTimeout(() => didChange(value))
                  inputChangeStateUpdater(true)
                }}
                onBlur={e => {
                  const value = e.target.value
                  if (onBlurInput) {
                    if (inputChangeState === true) {
                      setTimeout(() => didChange(value))
                    } else {
                      inputChangeStateUpdater(false)
                    }
                    input.onBlur(value)
                  } else {
                    input.onBlur(value)
                  }
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    inputChangeStateUpdater(false)
                    didChange(e.target.value)
                  }
                }}
              />
            )
        }
      })()}
    </InputContainer>
    {type !== 'address' && !hideError && touched && (error || asyncError) && (
      <ErrorContainer className="error-container">
        <ExclamationCircle icon={['fas', 'exclamation-circle']} />
        <ErrorText
          className="error"
          type={type}
          errorOpacity={errorOpacity}
          errorColor={errorColor}>
          {error || asyncError}
        </ErrorText>
      </ErrorContainer>
    )}
  </FieldContainer>
)

const CustomField = props => <Field {...props} component={RenderField} />

export const enhancer = compose(
  withState('isPasswordDisplayed', 'togglePasswordDisplay', false),
  withState('inputChangeState', 'inputChangeStateUpdater', false),
  withHandlers({
    showPasswordHandler: ({
      isPasswordDisplayed,
      togglePasswordDisplay,
    }) => name => {
      togglePasswordDisplay(!isPasswordDisplayed)
      var x = document.getElementsByClassName(name)
      if (x[1].type === 'password') {
        x[1].type = 'text'
      } else {
        x[1].type = 'password'
      }
    },
  }),
)

export default enhancer(CustomField)
