import React from 'react'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../../../core/style'
import {compose, withState} from 'recompose'
import {change, formValueSelector} from 'redux-form'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const HeaderOption = styled.div`
  height: 24px;
  margin-bottom: 30px;
  ${props =>
    responsive.mobile(css`
      margin-bottom: 15px;
    `)};
`
const Header = styled.span`
  font-size: 16px;
  font-weight: ${props => props.isSimple && 'bold'};
  cursor: ${props => !props.isSimple && 'pointer'};
  margin: 0 5px;
  color: ${props =>
    props.isSimple ? props.theme.BLUE : props.theme.EXTRALIGHTBLUE};
`
const Or = styled.span`
  color: ${props => props.theme.EXTRALIGHTBLUE};
`

const HeaderOptions = ({isSimple, children, change}) => (
  <Container>
    <HeaderOption>
      <Header
        onClick={() => change('Planning', 'isSimple', true)}
        isSimple={isSimple && isSimple !== 'false'}>
        แบบง่าย
      </Header>
      <Or>|</Or>
      <Header
        onClick={() => change('Planning', 'isSimple', false)}
        isSimple={!(isSimple && isSimple !== 'false')}>
        แบบละเอียด
      </Header>
    </HeaderOption>
    {isSimple && isSimple !== 'false' ? children[0] : children[1]}
  </Container>
)

const selectorForm = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      isSimple: selectorForm(state, 'isSimple'),
    }),
    {change},
  ),
)

export default enhancer(HeaderOptions)
