import React from 'react'
import {compose, withState, withProps} from 'recompose'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'

import withSEO from '../share/withSEO'
import {Header as NoonQuestionHeader} from '../NoonQuestion/components'
import {isClient} from '../NoonQuestion/common/helper'
import BackgroundContent from '../../static/images/tqm-life/768_300@2x.png'
import BackgroundContentMobile from '../../static/images/tqm-life/mobile320_300@2x.png'
import AboutImg from '../../static/images/tqm-life/360_300@2x.png'
import Partner from './Partner'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 768px;
  margin: auto;

  @media (max-width: 425px) {
    width: 100%;
    max-width: 425px;
  }
`
const ContentSelect = styled.div`
  background-image: url(${BackgroundContent});
  background-size: cover;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 425px) {
    background-image: url(${BackgroundContentMobile});
    background-repeat: no-repeat;
    background-size: cover;
    height: 383px;
    justify-content: center;
  }
`
const WrapButton = styled.div`
  @media (max-width: 425px) {
    margin-bottom: 60px;
  }
`
const WrapContent = styled.div``
const ContentAbout = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  div {
    flex: 1;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const ContentPartner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
`
const ContentContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
`
const Img = styled.img`
  width: 100%;
  height: auto;
  margin: 50px 0;
  @media (max-width: 425px) {
    width: 100%;
  }
`
const TextContent = styled.div`
  padding: 10px 8px 0 0;
  font-size: 16px;
  color: #666666;
  @media (max-width: 425px) {
    font-size: 14px;
  }
`
const BoldContent = styled.span`
  font-weight: 600;
`
const TitleContent = styled.h3`
  font-size: 30px;
  color: #008afc;
  margin-top: 33px;
  font-weight: 400;
  @media (max-width: 425px) {
    font-size: 20px;
    .selectTitle {
      margin-top: 10px;
    }
  }
`
const WrapAddress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 140px;
  @media (max-width: 550px) {
    padding: 0 20px;
  }
`
const AddressText = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  :first-child {
    align-items: flex-start;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
`
const EmailText = styled.div`
  color: #66b8fd;
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
`
const WrapTextSelect = styled.div`
  width: 350px;
  margin-right: 72px;
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 425px) {
    margin: 0px;
    justify-content: space-around;
  }
`
const Button = styled.button`
  background: #66b8fd;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  width: 241px;
  height: 38px;
  margin-top: 15px;
  border: none;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
`
const SearchIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: #ffffff;
`
const AddressIcon = styled(FontAwesomeIcon)`
  color: white;
`
const WrapIcon = styled.div`
  background: #66b8fd;
  height: 40px;
  overflow: hidden;
  min-width: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  @media (max-width: 425px) {
    width: 45px;
    margin-right: 10px;
  }
`
const WrapText = styled.div`
  width: 400px;
`
const Landing = ({
  isOnTop,
  gender,
  setGender,
  location,
  showNoonQuestionHeader,
}) => (
  <Container>
    <ContentSelect>
      <WrapTextSelect>
        <TitleContent classname="selectTitle">
          เลือกแบบประกันที่คุณสนใจ
        </TitleContent>
        <WrapButton>
          <Button
            onClick={() =>
              navigate(`/smart/InsuranceCategory/life/intermediate`)
            }>
            ค้นหาแบบประกันด้วยตัวเอง <SearchIcon icon={['fas', 'search']} />
          </Button>
          <Button
            onClick={() => navigate(`/smart/InsuranceCategory/life/starter`)}>
            วางแผนซื้อประกันชีวิต <SearchIcon icon={['fas', 'list-ol']} />
          </Button>
        </WrapButton>
      </WrapTextSelect>
    </ContentSelect>
    <ContentAbout>
      <WrapContent>
        <TitleContent style={{marginBottom: '0px'}}>เกี่ยวกับเรา</TitleContent>
        <TextContent>
          <BoldContent>ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์</BoldContent>{' '}
          ที่ปรึกษาประกันภัยชีวิตและการเงินชั้นนำของไทย
          มีนโยบายพัฒนาศักยภาพของพนักงานอย่างต่อเนื่อง
          ทั้งอบรมให้ความรู้ตามงานในหน้าที่และรวมถึงความรู้ด้านประกันภัยชีวิต
          อีกด้วย ส่งเสริมให้พนักงานใช้ความคิดในการพัฒนางานของตนเอง
          เพื่อนำไปสู่เส้นทางการเจริญเติบโตใน ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์
          โบรคเกอร์ ต่อไป ปัจจุบัน ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์
          มีพนักงานมืออาชีพให้บริการอยู่ทั่วประเทศกว่า 4,000 คน มีสาขา 96
          แห่งทั่วประเทศสามารถทำงานที่สำนักงานใหญ่ กรุงเทพฯ
          หรือในต่างจังหวัดทั่วไทย
        </TextContent>
      </WrapContent>
      <WrapContent>
        <Img src={AboutImg} />
      </WrapContent>
    </ContentAbout>
    <ContentPartner>
      <TitleContent>พันธมิตร</TitleContent>
      <Partner />
    </ContentPartner>
    <ContentContact>
      <TitleContent>ติดต่อเรา</TitleContent>
      <WrapAddress>
        <AddressText>
          <WrapIcon>
            <AddressIcon icon={['fas', 'map-marker-alt']} />
          </WrapIcon>
          <WrapText>
            ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ (สำนักงานใหญ่) 123
            ถนนลาดปลาเค้า แขวงจรเข้บัว เขตลาดพร้าว กรุงเทพฯ 10230
          </WrapText>
        </AddressText>
        <AddressText>
          <WrapIcon>
            <AddressIcon icon={['fas', 'phone-alt']} />
          </WrapIcon>
          <WrapText>โทรศัพท์ 02-119-8888</WrapText>
        </AddressText>
        <AddressText>
          <WrapIcon>
            <AddressIcon icon={['fas', 'fax']} />
          </WrapIcon>
          <WrapText>แฟกซ์ 02-119-8800</WrapText>
        </AddressText>
        <EmailText>
          <WrapIcon>
            <AddressIcon icon={['fas', 'envelope']} />
          </WrapIcon>
          <WrapText>info@tqm.co.th</WrapText>
        </EmailText>
      </WrapAddress>
    </ContentContact>
  </Container>
)

const enhancer = compose(
  withSEO(
    'TQM Life - ที่ปรึกษาประกันภัยชีวิตและการเงินชั้นนำของไทย',
    'มีนโยบายพัฒนาศักยภาพของพนักงานอย่างต่อเนื่อง ทั้งอบรมให้ความรู้ตามงานในหน้าที่และรวมถึงความรู้ด้านประกันภัยชีวิต อีกด้วย ส่งเสริมให้พนักงานใช้ความคิดในการพัฒนางานของตนเอง เพื่อนำไปสู่เส้นทางการเจริญเติบโตใน ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ ต่อไป ปัจจุบัน ทีคิวเอ็ม ไลฟ์ อินชัวร์รันส์ โบรคเกอร์ มีพนักงานมืออาชีพให้บริการอยู่ทั่วประเทศกว่า 4,000 คน มีสาขา 96 แห่งทั่วประเทศสามารถทำงานที่สำนักงานใหญ่ กรุงเทพฯ หรือในต่างจังหวัดทั่วไทย',
    'แบบประกันภัยครบวงจร,ซื้อประกันอะไรดี,ทุกเรื่องครบ จบเกี่ยวกับการประกันภัย',
  ),
  withState('gender', 'setGender', 'male'),
  withProps((props) => ({
    showNoonQuestionHeader:
      isClient && window.location.origin !== 'https://www.noon.in.th',
  })),
)

export default enhancer(Landing)
