import React from 'react'
import SimpleForm from './SimpleForm'
import AdvanceForm from './AdvanceForm'
import Header from '../HeaderOptionsForm'

const DebtProtectionForm = ({windowWidth}) => (
  <>
    <SimpleForm windowWidth={windowWidth} />
    {/* <AdvanceForm windowWidth={windowWidth} /> */}
  </>
)

export default DebtProtectionForm
