import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {compose} from 'recompose'
import styled, {css} from 'react-emotion'
import {Link} from '@reach/router'

import Banner from '../../static/images/Banner.png'
import BannerMobile from '../../static/images/BannerMobile.jpg'
import {responsive} from '../../core/style'
import withSEO from '../share/withSEO'

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  min-height: 460px;
  top: 80px;
  right: calc(50% - 400px);

  ${props =>
    responsive.tablet(css`
      right: calc(50% - 360px);
    `)};

  ${props =>
    responsive.mobile(css`
      right: calc(50% - 135px);
      top: calc(50% - 300px);
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 37px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 18px;
    `)};
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      margin-top: 21px;
      margin-bottom: 40px;
    `)};
`

const CategoryIcon = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 370px;
  height: 70px;
  background-color: #6a94cb;
  border-radius: 5px;
  margin: 10px 0;
  color: white;
  overflow: hidden;
  padding-left: 75px;

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }

  &.CustomLifeCategory {
    padding-left: 71px;
  }

  :hover {
    background-color: #265fa7;
    color: white;
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 35px;
      margin: 5px 0;

      padding-left: 26px;
      background-color: #265fa7;

      &.CustomLifeCategory {
        padding-left: 23px;
      }
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  z-index: 1;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
    `)};
`

const CategoryText = styled.div`
  font-size: 30px;
  margin-left: 25px;
  z-index: 1;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      margin-left: 8px;
    `)};
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  /* background-image: url(${Banner}); */
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      /* background-image: url(${BannerMobile}); */
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const NoonSmart = () => (
  <NoonSmartWrapper>
    <NoonSmartBanner>
      <Container>
        <NoonSmartHeader>NOON SMART</NoonSmartHeader>
        <NoonSubSmartHeader>แบบประกันที่ใช่ ใครๆ ก็ชอบ</NoonSubSmartHeader>
        <CategoryContainer>
          <CategoryIcon to={'/smart/InsuranceCategory'}>
            <CustomIcon icon={['fas', 'shield-alt']} />
            <CategoryText>ค้นหาแบบประกัน</CategoryText>
          </CategoryIcon>
          <CategoryIcon className="CustomLifeCategory" to={'/tax/calculator'}>
            <CustomIcon icon={['fas', 'piggy-bank']} />
            <CategoryText>วางแผนภาษี</CategoryText>
          </CategoryIcon>
        </CategoryContainer>
      </Container>
    </NoonSmartBanner>
  </NoonSmartWrapper>
)

const enhancer = compose(
  withSEO(
    'เริ่มค้นหาแบบประกันภัย - ประกันภัยที่ใช่ตามไลฟ์สไตล์คุณ',
    'noon Smart แหล่งข้อมูลของรายละเอียดทุกแบบประกันรวมไว้ให้คุณที่นี่ ให้คุณได้เช็คราคาและศึกษารายละเอียดก่อนตัดสินใจ',
    'เลือกซื้อประกันประกันชีวิต,ประกันสุขภาพ,ประกันรถยนต์ ระบบแนะนำประกัน ผลิตภัณฑ์ประกันภัยที่ครบถ้วน',
  ),
)

export default enhancer(NoonSmart)
