import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withState, withHandlers} from 'recompose'
import withSEO from '../../../share/withSEO'

import CarInfoForm from './CarInfoForm'
import ChoosingCoverageForm from './ChoosingCoverageForm'

const WizardForm = ({page, nextPage, ...otherProps}) => (
  <React.Fragment>
    {page === 1 && (
      <CarInfoForm
        buttonText="เลือกความคุ้มครอง"
        nextPage={nextPage}
        {...otherProps}
      />
    )}
    {page === 2 && <ChoosingCoverageForm buttonText="เลือกแบบประกัน" />}
  </React.Fragment>
)

const enhancer = compose(
  withSEO(
    'noon Smart Motor',
    'noon Smart-Motor เช็คราคาประกันรถยนต์ เปรียบเทียบราคา ด้วยระบบที่สามารถตอบโจทย์ ความคุ้มค่าตามความต้องการของผู้บริโภคในยุคดิจิตัล',
    'เครื่องมือ,เช็คราคาประกันรถยนต์,ข้อมูลประกันรถยนต์,ชั้น1,ชั้น2,ชั้น2+,ชั้น3,ชั้น3+,รถยนต์ส่วนบุคคล,รถเก๋ง,รถกระบะ,ค่าเสียหายส่วนแรก,คุ้มครองผู้ขับขี่,คุ้มครองคู่กรณี',
  ),
  connect(
    (state, props) => ({
      initialValues: {
        carUsage: '110',
        redLabel: true,
        coverCarSelfRepair: true,
        specifyOtherDriver: true,
      },
    }),
    {},
  ),

  withState('page', 'setPage', 1),
  withHandlers({
    nextPage: ({page, setPage}) => () => {
      setPage(page + 1)
    },
  }),
  lifecycle({
    componentDidMount() {},
  }),
)
export default enhancer(WizardForm)
