import React from 'react'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import Life from './Life'
import Hs from './Hs'
import Auto from './Auto'
import Ci from './Ci'
import NoProductCard from './NoProductCard'
import Rider from './Rider'
import CiRider from './CiRider'
import CompareContainer from './CompareContainer'
import Package from './Package'

import { setFavoriteProduct } from '../../../../ducks/product'

const TryThisContainer = props => <div>TryThisContainer</div>

const ProductCard = ({ Component, setFavoriteProduct, ...restProps }) => (
  <Component onFavorite={setFavoriteProduct} {...restProps} />
)

const enhance = compose(
  withProps(props => {
    let type = 'unknown'

    if (props.data.insurancePolicyType === 'LifeInsurancePolicy') {
      type = 'life'
    }

    if (props.data.insurancePolicyType === 'HealthInsurancePolicy') {
      // List of subType=['hs', 'hb', 'cr', 'ci', 'lc']
      switch (props.data.policies[0].subType) {
        case 'hs':
        case 'hb':
        case 'cr':
        case 'lc':
          // TODO: open comment type = props.data.policies[0].subType
          type = 'hs'
          break
        case 'ci':
          type = 'ci'
          break
        default:
          break
      }

      if (
        props.data.category === 'life' &&
        props.data.policies[0].subType !== 'ci' &&
        !props.noRider
      ) {
        type = 'rider'
      } else if (
        props.data.category === 'life' &&
        props.data.policies[0].subType === 'ci' &&
        !props.noRider
      ) {
        type = 'ci_rider'
      }
    }

    if (props.data.insurancePolicyType === 'AutoInsurancePolicy') {
      // List of subType=['AI/RCC', 'ADD', 'ADB', 'PA', 'Auto', 'TA']

      type = 'auto'
    }

    if (props.data.insurancePolicyType === 'PackageInsurancePolicy') {
      type = 'package'
    }

    return {
      type: type,
      Component: {
        life: Life,
        hs: Hs,
        ci: Ci,
        auto: Auto,
        unknown: NoProductCard,
        rider: Rider,
        ci_rider: CiRider,
        package: Package,
      }[type],
      SpecialActionComponent:
        {
          compare: CompareContainer,
          tryThis: TryThisContainer,
        }[props.specialAction] || props.specialAction,
    }
  }),
  connect(
    (state, props) => ({
      localFavorite: state.product.favoriteProduct[props.data.id],
      isLogin: state.auth.isLogin,
    }),
    {
      setFavoriteProduct,
    },
  ),
)

export default enhance(ProductCard)
