import React from 'react'
import {compose, withState, withHandlers} from 'recompose'
import {httpGet, httpPost} from '../../../../core/httpClient'
import Modal from '../../../share/Modal'
import PrepareDataPanel from './PrepareDataPanel'
import TermAndConditionPanel from './TermAndConditionPanel'
import SendCompletedPanel from './SendCompletedPanel'
import SpidusStore from '../../core/spidus/spidusStore'
import styled from 'react-emotion'
import {Button} from '../../components'
import objectPath from 'object-path'

const SendTaxButton = styled(Button)`
  width: 200px !important;
  margin-bottom: 30px;
`

const RdSmartTaxModal = props => (
  <>
    <SendTaxButton onClick={props.onClickSendButton}>
      {props.children}
    </SendTaxButton>
    <Modal isShowModal={props.isOpen} setIsShowModal={props.closeModal}>
      {props.state === 'accept_term_and_condition' && (
        <TermAndConditionPanel onSubmit={props.onAcceptTermAndCondition}>
          {props.termAndCondition}
        </TermAndConditionPanel>
      )}
      {props.state === 'prepare_data' && (
        <PrepareDataPanel onSubmit={props.onPrepareData} />
      )}
      {props.state === 'send_completed' && (
        <SendCompletedPanel
          code={props.code}
          onSubmit={props.onSendCompleted}
        />
      )}
    </Modal>
  </>
)

const enhancer = compose(
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    openModal: props => () => {
      props.setIsOpen(true)
    },
    closeModal: props => () => {
      props.setIsOpen(false)
    },
  }),
  withState('state', 'setState', null),
  withState('termAndCondition', 'setTermAndCondition', null),
  withState('code', 'setCode', null),
  withHandlers({
    onClickSendButton: props => async () => {
      // const response = await httpGet(`rd_smart_tax/check_send_data`, {
      //   sessionId: SpidusStore.getSessionId(),
      // })
      // props.setTermAndCondition(response.data.data.termAndCondition)
      props.setState('accept_term_and_condition')
      props.openModal()
    },
    onAcceptTermAndCondition: props => async () => {
      props.setState('prepare_data')
    },
    onPrepareData: props => async () => {
      try {
        const response = await httpPost(`rd_smart_tax/send_data_pnd91`, {
          sessionId: SpidusStore.getSessionId(),
        })
        props.setCode(response.data.data.controlCode)
        props.setState('send_completed')
      } catch (e) {
        const message =
          objectPath.get(e, 'response.data.message') || 'เกิดข้อผิดพลาดบางอย่าง'
        alert(message)
      }
    },
    onSendCompleted: props => async () => {
      props.closeModal()
    },
  }),
)

export default enhancer(RdSmartTaxModal)
