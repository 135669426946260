import React from 'react'
import {connect} from 'react-redux'
import {compose, withProps, withState} from 'recompose'
import {reduxForm, Field as ReduxFormField} from 'redux-form'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '../../share/Button'
import Field from '../../share/Field'
import Modal from '../../share/Modal'
import Tooltip from '../../share/Tooltip'
import windowSize from '../../../core/react-window-size'
import Loading from '../../share/Loading'
import {navigate} from '@reach/router'
import {DatePicker} from '../../NoonTax/components'
import {toDate} from '../../NoonTax/components/DatePicker'
import LeadResult from './LeadResult'

import {
  phoneFormatter,
  formatParser,
  ThaiDateFormatter,
  normalizeDate,
  isPhoneNumber,
  isEmail,
} from '../../../core/helper'
import {buyProduct} from '../../../ducks/product'
import avatar02 from '../../../static/images/avatar-02.svg'
import {responsive, breakpoints} from '../../../core/style'
import {httpPost, API_HOST} from '../../../core/httpClient'

const LANG = 'en'

const CustomModal = styled(Modal)`
  .ant-modal-body > svg:first-child {
    position: absolute;
    top: 13px;
  }
`

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: #ffffff;
  border-radius: 5px;
  opacity: 1;
`

export const ContainerBody = styled.div`
  width: 100%;
  padding: 0 60px;
  ${props =>
    responsive.mobile(css`
      padding: 0 20px;
    `)};
`
export const ContainerFooter = styled.div`
  display: flex;
  width: 100%;
  > * {
    flex: 1;
  }
`

const Header = styled.div`
  font-size: 20px;
  margin-bottom: 30px;
  width: 100% !important;
  height: 50px;
  background: #008afc;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
    `)};
`

const SubHeader = styled.div`
  font-size: 16px;
  ${props =>
    responsive.mobile(css`
      font-size: 14px;
    `)};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #7f7f7f;
          }
        }
      }
    `)};
`

const HeaderSale = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 92px;
  margin-bottom: 22px;
`

const CustomCheckbox = styled(Field)`
  margin: unset;
  width: 100%;
  max-width: 250px;
  margin: 15px 0;

  .checkboxContainer {
    margin-right: unset;
    justify-content: center;
  }

  .ant-checkbox {
    margin-top: 5px;
  }
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .label {
    margin-left: 10px;
    font-size: 12px;
  }
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const Selector = styled(Field)`
  margin: 15px 0;

  display: flex;

  > * {
    flex: 1;
  }

  > div:last-child > div {
    max-width: 175px !important;
    ${props =>
      responsive.mobile(css`
        max-width: 100% !important;
      `)};
  }

  .input-googleSelector-container {
    margin: 0;

    .Select {
      border-radius: 5px;
      text-align: center;
      font-size: 16px;

      > .Select-menu-outer {
        width: 250px;
        margin-top: 11px;
        left: -1px;
        .Select-menu {
          border-radius: 0 0 5px 5px;
          background-color: white;
          border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
          border-top: 0;
          padding: 0;

          .Select-option {
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            padding: 8px 0 8px 15px;
            width: 250px;
            height: 40px;
            :hover {
              background-color: rgba(255, 212, 130, 0.5);
              color: black;
            }
          }
        }
      }
    }
  }

  .label {
    width: 50%;
    min-width: 100px;
    justify-content: left;
    ${props =>
      responsive.mobile(css`
        width: 100%;
      `)};
  }
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const GenderButton = styled(Field)`
  width: 100%;
  > div {
    > div {
      justify-content: center;
      .male {
        width: 125px;
      }
      .female {
        width: 125px;
      }
    }
  }
  .label {
    min-width: 100px;
  }

  ${props =>
    responsive.mobile(css`
      .input-switch-container {
        justify-content: center;
      }
    `)};
`

const GenderSelector = styled(Field)`
  display: flex;
  width: 100%;
  > div {
    width: 50%;
    ${props =>
      responsive.mobile(css`
        width: 100% !important;
      `)};
    > div {
      justify-content: left;
      .male {
        width: 88px;
        font-size: 16px;
      }
      .female {
        width: 88px;
        font-size: 16px;
      }
    }
  }
  .label {
    width: 50%;
    min-width: 100px;
    justify-content: left;
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 10px;
      flex-direction: column;
    `)};
`

const CustomField = styled(Field)`
  display: flex;
  width: 100%;
  > div {
    width: 50%;
    justify-content: left;
    ${props =>
      responsive.mobile(css`
        width: 100% !important;
      `)};
    input {
      width: 175px;
      text-align: left;
      ${props =>
        responsive.mobile(css`
          width: 100% !important;
        `)};
    }
  }
  .label {
    min-width: 100px;
    width: 50%;
    justify-content: left;
    ${props =>
      responsive.mobile(css`
        width: 100%;
      `)};
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 10px;
      flex-direction: column;
    `)};
`

const BirthDatePicker = styled(Field)`
  display: flex;
  > div {
    flex: 1;
    > div {
      margin: 0px;
      ${props =>
        responsive.mobile(css`
          width: 100% !important;
        `)};
      > div:last-child {
        width: 175px;
        ${props =>
          responsive.mobile(css`
            width: 100% !important;
          `)};
        > div {
          > input {
            text-align: left !important;
          }
        }
      }
    }
  }
  .label {
    flex: 1;
    justify-content: left;
  }
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const GoogleInput = styled(Field)`
  margin: 15px 0;
`

export const SubmitButton = styled(Button)`
  width: 100% !important;
  height: 52px;
  border-radius: 0px;
  margin-top: 20px;
  font-size: 16px;
  border-top: 1px solid #66b8fd;
  border-bottom: unset;
  border-left: unset;
  border-right: unset;
  border-radius: 0px;
  :hover {
    border-top: 1px solid #66b8fd;
    border-bottom: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0px;
  }
`

export const CloseButton = styled(Button)`
  width: 100% !important;
  border-radius: 0px;
  height: 52px;
  margin-top: 20px;
  font-size: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #eeeef2;
  border-bottom: unset;
  border-left: unset;
  border-right: unset;
  border-radius: 0px;
  color: #666666;
  :hover {
    border-top: 1px solid #eeeef2;
    border-bottom: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0px;
    color: #666666;
    background-color: #ffffff;
  }
`

const Profile = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 22px;
`
const Info = styled.div`
  font-size: 16px;
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.YELLOW};
  width: 17px;
  height: 15px;
`

const CustomInfoIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: -35px;
  top: 26px;
  color: #7f7f7f;
  border-right-color: #7f7f7f;
  font-size: 18px;
  ${props =>
    responsive.mobile(css`
      position: absolute;
      top: -5px;
      right: 0px;
    `)};
`
const ContainerStarIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 68px;
`
const ContainerMotorResponse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 273px;
`
const MotorResponseHeader = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const MotorResponseH1 = styled.span`
  font-size: 16px;
  line-height: 2;
`
const MotorResponseH2 = styled.span`
  font-size: 12px;
  color: #7f7f7f;
  margin: 0px 8px;
`

const ContainerInfoDate = styled.div`
  position: relative;
`
const CustomLoading = styled(Loading)`
  .loading-container {
    position: absolute;
  }
`

const optionsTime = [
  {label: '08.00-12.00', value: '08.00-12.00'},
  {label: '12.00-16.00', value: '12.00-16.00'},
  {label: '16.00-20.00', value: '16.00-20.00'},
]

const optionsUrgent = [
  {label: 'มาก (ภายใน 24 ชม.)', value: 1},
  {label: 'ปานกลาง (ภายใน 3 วัน)', value: 2},
  {label: 'น้อย (ภายใน 1 สัปดาห์)', value: 3},
]

const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastName) {
    errors.lastName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }

  if (!values.telnumber) {
    errors.telnumber = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.telnumber)) {
    errors.telnumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (values.email !== '' && !isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  if (!values.time) {
    errors.time = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  errors._error = Object.keys(errors).length
  return errors
}

const LabelContainer = styled.div`
  display: flex;
  align-content: center;
  margin-top: 5px;
`

const LabelLink = styled.div`
  color: blue;
  text-decoration: underline;
`

const CustomDatePicker = ({input, ...props}) => (
  <DatePicker {...input} {...props} />
)

const CustomLabel = () => (
  <LabelContainer>
    ยอมรับ
    <LabelLink onClick={() => window.open(`/policy`, '_blank')}>
      นโยบายและเงื่อนไขการใช้งาน
    </LabelLink>
  </LabelContainer>
)
const MotorResponse = () => (
  <ContainerMotorResponse>
    <MotorResponseHeader>ขอบคุณที่ใช้บริการของ NOON</MotorResponseHeader>

    <MotorResponseH1>เจ้าหน้าที่จะติดต่อกลับในเวลาที่ระบุไว้</MotorResponseH1>
    <MotorResponseH2>
      ดำเนินการโดย บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด
    </MotorResponseH2>
  </ContainerMotorResponse>
)

const ModalCatalogue = ({
  isShowModal,
  setIsShowModal,
  handleSubmit,
  saleRep,
  category,
  params,
  premium,
  id,
  buyProduct,
  promotionCode,
  packageId,
  vat,
  amount,
  amountNet,
  insureLevel,
  companyId,
  paramsEncode,
  productName,
  windowWidth,
  cover,
  isLoading,
  error,
  isLogin,
  packageReferenceCode,
  insuranceCompanyName,
  packageInfo,
  leadPersonalInfo,
  setIsShowLeadPackageResultModal,
  isShowLeadPackageResultModal,
  setLeadParams,
  leadParams,
}) => (
  <CustomModal
    isShowModal={isShowModal}
    setIsShowModal={setIsShowModal}
    width="500px"
    hideCustomIcon>
    {!leadPersonalInfo.fullname && !isShowLeadPackageResultModal ? (
      <CustomLoading isLoading={isLoading}>
        <Container
          noValidate
          onSubmit={handleSubmit(async value => {
            if (category === 'package') {
              const params = {
                personalFirstName: value.firstName,
                personalLastName: value.lastName,
                personalGender: value.gender,
                personalBirthdate: value.birthdate,
                personalPhoneNumber: value.telnumber,
                personalEmail: value.email,
                personalContactTime: value.time,
                ...packageInfo,
              }
              setLeadParams(params)
              const response = await httpPost(
                `${API_HOST}user_package_quotations`,
                params,
              )
              // let packageData = JSON.parse(response.data.data.data)

              if (response.data.data === 'success') {
                setIsShowLeadPackageResultModal(true)
              }
            } else {
              buyProduct(
                {
                  premium,
                  value,
                  promotionCode,
                  packageId,
                  id,
                  params,
                  vat,
                  amount,
                  amountNet,
                  insureLevel,
                  companyId,
                  paramsEncode,
                  cover,
                },
                category,
              )
            }
          })}>
          <Header>กรอกข้อมูลเพื่อให้ตัวแทนติดต่อกลับ</Header>
          <ContainerBody>
            <GenderSelector label="เพศ" name="gender" type="switch" />
            <CustomField
              label="ชื่อ"
              name="firstName"
              placeholder="กรุณาระบุ"
              hideError
            />
            <CustomField
              label="นามสกุล"
              name="lastName"
              placeholder="กรุณาระบุ"
              hideError
            />
            <ContainerInfoDate>
              <BirthDatePicker
                label="วันเกิด"
                name="birthdate"
                type="datepicker"
                viewMode="months"
                viewDate={
                  new Date((new Date().getFullYear() - params.age).toString())
                }
                hideError
                placeholder="กรุณาเลือก"
                hideLabelHeader
              />
            </ContainerInfoDate>
            <CustomField
              name="telnumber"
              label="เบอร์โทร"
              placeholder="กรุณาระบุ"
              format={phoneFormatter}
              parse={formatParser}
              hideError
            />
            <CustomField
              name="email"
              label="อีเมล"
              placeholder="กรุณาระบุ"
              hideError
            />
            <Selector
              name="time"
              type="selector"
              label="เวลาที่สะดวกให้ติดต่อ"
              options={optionsTime}
              placeholder="กรุณาระบุ"
              hideError
            />
          </ContainerBody>
          <ContainerFooter>
            <CloseButton
              htmlType="button"
              onClick={() => setIsShowModal(false)}>
              ย้อนกลับ
            </CloseButton>
            <SubmitButton htmlType="submit" disabled={error}>
              ยืนยัน
            </SubmitButton>
          </ContainerFooter>
        </Container>
      </CustomLoading>
    ) : (
      <LeadResult
        setIsShowModal={setIsShowModal}
        setIsShowLeadPackageResultModal={setIsShowLeadPackageResultModal}
        leadParams={leadParams}
      />
      // <Container>
      //   <HeaderSale>ตัวแทนของคุณ</HeaderSale>
      //   <Profile
      //     src={
      //       category === 'health'
      //         ? saleRep[packageId].avatar || avatar02
      //         : saleRep[id].avatar || avatar02
      //     }
      //   />
      //   {category === 'health'
      //     ? saleRep[packageId].avatar && (
      //         <Info>
      //           เลขประจำตัว{' '}
      //           {category === 'health'
      //             ? saleRep[packageId].avatar
      //             : saleRep[id].licenseNo}
      //         </Info>
      //       )
      //     : saleRep[id].licenseNo && (
      //         <Info>
      //           เลขประจำตัว{' '}
      //           {category === 'health'
      //             ? saleRep[packageId].avatar
      //             : saleRep[id].licenseNo}
      //         </Info>
      //       )}
      //   <Info>
      //     {category === 'health' ? saleRep[packageId].name : saleRep[id].name}
      //   </Info>
      //   <ContainerStarIcon>
      //     {[1, 2, 3, 4, 5].map(item => (
      //       <CustomIcon key={item} icon={['fas', 'star']} />
      //     ))}
      //   </ContainerStarIcon>
      // </Container>
    )}
  </CustomModal>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.product.isLoadingModal,
      birthdate: state.auth.user,
      initialValues: {
        gender: props.params.gender,
        email: state.auth.user.email,
        firstName: state.auth.user.firstNameTh,
        lastName: state.auth.user.lastNameTh,
        telnumber: state.auth.user.phoneNumber,
        birthdate: toDate(state.auth.user.birthdate),
      },
      saleRep: state.product.saleRep,
      leadPersonalInfo: state.product.leadPersonalInfo,
    }),
    {buyProduct},
  ),
  reduxForm({
    form: 'ModalCatalogue',
    destroyOnUnmount: false,
    validate,
    enableReinitialize: true,
  }),
  withState(
    'isShowLeadPackageResultModal',
    'setIsShowLeadPackageResultModal',
    false,
  ),
  withState('leadParams', 'setLeadParams', {}),
)
export default windowSize(enhancer(ModalCatalogue))
