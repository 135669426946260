import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withHandlers, withState} from 'recompose'
import styled, {css} from 'react-emotion'
import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Tooltip from '../../../../../share/Tooltip'
import {
  displayNumber,
  inRange,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import Panel from '../PanelForm'
import {
  Unit,
  TextInput,
  Summary,
  Label,
  CustomButton,
  LabelSummary as LabelSum,
} from '../ShareFieldForm'
import {responsive, breakpoints} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'
import PersonalForm from '../../../../../share/PersonalFormTQM'

const FooterFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 298px;
  background-color: #66b8fd;
  ${props =>
    responsive.mobile(css`
      height: 580px;
    `)};
`
const CustomPersonalForm = styled(PersonalForm)`
  margin-left: -37px;
  ${props =>
    responsive.mobile(css`
      margin-left: 0px;
    `)};
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 48px;
  margin-bottom: 30px;

  .rmf {
    .label {
      max-width: 250px;
    }
  }
  .gpf {
    .label {
      max-width: 250px;
    }
  }

  ${props =>
    responsive.mobile(css`
      position: relative;
      margin: 0;
      height: 100%;
    `)};
`

const CustomTextInput = styled(TextInput)`
  ${props =>
    responsive.mobile(css`
      .label {
        padding-right: 30px;
      }
    `)};
`
const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #e5e5e5;
      }
      > .ant-tooltip-inner {
        /* display: flex;
        align-items: center;
        justify-content: center; */
        font-size: 12px;
        background-color: #e5e5e5;
        color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #e5e5e5;
          }
        }
      }
    `)};
`
const CustomTooltipRight = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.tablet(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #7f7f7f;
          }
        }
      }
    `)};
`

const TextFooter = styled.div`
  width: 650px;
  font-size: 16px;
  color: white;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 20px;
  ${props =>
    responsive.mobile(css`
      height: unset;
    `)};
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`
const ContainerCustomText = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 322px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 15px 25px;
  ${props =>
    responsive.mobile(css`
      border: unset;
      padding: unset;
      width: 100%;
      margin-left: 0;
    `)};
`

const CustomText = styled.div`
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  display: grid;
  grid-template-columns: 25% 14% 55% 6%;
  font-weight: bold;
  color: white;
  font-size: 20px;
  margin: 5px 0;
  > div {
    :nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    :nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
    :nth-child(3) {
      grid-column-start: 3;
      grid-column-end: 4;
    }
    :nth-child(4) {
      grid-column-start: 4;
      grid-column-end: 5;
    }
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin: 15px 0;
      grid-template-columns: 10% 40% 26%;
      padding-left: 60px;
      > div {
        :nth-child(1) {
          grid-column-start: 2;
          grid-column-end: 3;
        }
        :nth-child(2) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
        :nth-child(3) {
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 5;
        }
        :nth-child(4) {
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 4;
          grid-column-end: 5;
        }
      }
    `)};
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 30px;
  color: #7f7f7f;
  border-right-color: #7f7f7f;

  ${props =>
    responsive.mobile(css`
      position: absolute;
      top: 2px;
      right: 20px;
    `)};
`
const CustomIconFooter = styled(CustomIcon)`
  margin-left: 0;
  color: #e5e5e5;
  border-right-color: #e5e5e5;

  ${props =>
    responsive.mobile(css`
      position: unset;
      /* position: absolute;
      top: -23px;
      right: 40px; */
    `)};
`

const LabelSummary = styled(Label)`
  display: flex;
  flex-direction: column;
  text-align: right;
  color: ${props => (props.summary > 100000 ? 'red' : 'black')};
  ${props =>
    responsive.mobile(css`
      text-align: left;
    `)};
`
const LabelSummary2 = styled(LabelSummary)`
  color: ${props => (props.summary > 500000 ? 'red' : 'black')};
`
const ErrorSummary = styled.div`
  display: ${props => (props.summary > 100000 ? 'flex' : 'none')};
  font-size: 12px;
  padding: 1px 0 0;
  color: red;
`
const ErrorSummary2 = styled(ErrorSummary)`
  display: ${props => (props.summary > 500000 ? 'flex' : 'none')};
`

const ContainerUnitText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 320px;
  ${props =>
    responsive.mobile(css`
      position: relative;
      justify-content: center;
    `)};
`
const UnitText = styled.div`
  /* margin-right: 15px; */
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
      margin-right: 0;
    `)};
`
const BathText = styled.div`
  margin-left: 10px;
`

const FooterSummary = styled.div`
  /* margin-right: 15px; */
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      display: flex;
      flex-direction: row;
      font-size: 20px;
    `)};
`

const Summary1 = ({firstSummary}) => (
  <LabelSummary summary={firstSummary}>
    <LabelSum>{displayNumber(Math.round(firstSummary))}</LabelSum>
    <ErrorSummary summary={firstSummary}>สูงสุด 100,000 บาท/ ปี</ErrorSummary>
  </LabelSummary>
)

const Summary2 = ({secondSummary}) => (
  <LabelSummary2 summary={secondSummary}>
    <LabelSum>{displayNumber(Math.round(secondSummary))}</LabelSum>
    <ErrorSummary2 summary={secondSummary}>
      สูงสุด 500,000 บาท/ ปี
    </ErrorSummary2>
  </LabelSummary2>
)

const HealthSummary = ({firstSummary, thirdSummary, mobile}) => (
  <FooterSummary>
    {firstSummary >= 100000 ? 0 : displayNumber(Math.round(thirdSummary))}
    {/* {mobile && <BathText>บาท</BathText>} */}
  </FooterSummary>
)

const LifeSummary = ({firstSummary, forthSummary, mobile}) => (
  <FooterSummary>
    {firstSummary >= 100000 ? 0 : displayNumber(Math.round(forthSummary))}
    {/* {mobile && <BathText>บาท</BathText>} */}
  </FooterSummary>
)

const RetireSummary = ({fiveSummary, mobile}) => (
  <FooterSummary>
    {fiveSummary <= 0
      ? 0
      : Number.isNaN(fiveSummary)
      ? 0
      : displayNumber(Math.round(fiveSummary))}
    {/* {mobile && <BathText>บาท</BathText>} */}
  </FooterSummary>
)

const TaxReductionForm = ({
  onSummary,
  firstSummary,
  secondSummary,
  thirdSummary,
  forthSummary,
  fiveSummary,
  toolTipAmount,
  toolTipAmount2,
  windowWidth,
  timeOut,
  initTimeOut,
}) => (
  <Panel>
    <div>
      <CustomPersonalForm />
      {/* <Header>สมมติฐาน</Header> */}
      <FieldContainer>
        <CustomTextInput
          name="income"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'รายได้พึงประเมิน (บาท/ปี)'
              : 'รายได้พึงประเมิน'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onSummary()
              }, 500),
            )
          }}
        />

        <Unit>บาท/ปี</Unit>
      </FieldContainer>
      <FieldContainer>
        <CustomTextInput
          name="lifePremiumCost"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เบี้ยประกันชีวิตทั่วไปที่ซื้อแล้ว (บาท/ปี)'
              : 'เบี้ยประกันชีวิตทั่วไปที่ซื้อแล้ว'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 100000)}
          format={displayNumber}
          parse={numberParser}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onSummary()
              }, 500),
            )
          }}
        />
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title="สูงสุด 100,000 บาท/ ปี">
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
      <FieldContainer>
        <CustomTextInput
          name="healthPremiumCost"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เบี้ยประกันสุขภาพที่ซื้อแล้ว (บาท/ปี)'
              : 'เบี้ยประกันสุขภาพที่ซื้อแล้ว'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 15000)}
          format={displayNumber}
          parse={numberParser}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onSummary()
              }, 500),
            )
          }}
        />
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title="สูงสุด 15,000 บาท/ ปี">
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'รวมสิทธิ (บาท/ปี)'
              : 'รวมสิทธิ'}
          </Label>
          <CustomField
            name="summary_1"
            component={Summary1}
            firstSummary={firstSummary}
          />
        </Summary>
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title="สูงสุด 100,000 บาท/ ปี">
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
      <FieldContainer>
        <CustomTextInput
          name="premiumRetire"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เบี้ยประกันประเภทบำนาญที่ซื้อแล้ว (บาท/ปี)'
              : 'เบี้ยประกันประเภทบำนาญที่ซื้อแล้ว'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, toolTipAmount)}
          format={displayNumber}
          parse={numberParser}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onSummary()
              }, 500),
            )
          }}
        />
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title={`สูงสุด ${displayNumber(Math.round(toolTipAmount))} บาท/ปี`}>
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
      <FieldContainer>
        <CustomTextInput
          className="rmf"
          name="rmf"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ที่ลงทุนแล้ว (บาท/ปี)'
              : 'กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ที่ลงทุนแล้ว'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, toolTipAmount2)}
          format={displayNumber}
          parse={numberParser}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onSummary()
              }, 500),
            )
          }}
        />
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title={`สูงสุด ${displayNumber(Math.round(toolTipAmount2))} บาท/ปี`}>
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
      <FieldContainer>
        <CustomTextInput
          className="gpf"
          name="providentFund"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'กองทุนสำรองเลี้ยงชีพ/ กบข./ กองทุนสงเคราะห์ครูที่ลงทุนแล้ว (บาท/ปี)'
              : 'กองทุนสำรองเลี้ยงชีพ/ กบข./ กองทุนสงเคราะห์ครูที่ลงทุนแล้ว'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, toolTipAmount2)}
          format={displayNumber}
          parse={numberParser}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onSummary()
              }, 500),
            )
          }}
        />
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title={`สูงสุด ${displayNumber(Math.round(toolTipAmount2))} บาท/ปี`}>
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'รวมสิทธิ (บาท/ปี)'
              : 'รวมสิทธิ'}
          </Label>
          <CustomField
            name="summary_2"
            component={Summary2}
            secondSummary={secondSummary}
          />
        </Summary>
        <Unit>บาท/ปี</Unit>
        <CustomTooltipRight
          placement={`${
            windowWidth <= breakpoints.tablet ? 'bottom' : 'right'
          }`}
          title="สูงสุด 500,000 บาท/ ปี">
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltipRight>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <TextFooter>
        <SearchFactor
          type="TaxReduction"
          windowWidth={windowWidth}
          searchValue={firstSummary >= 100000 ? 0 : forthSummary}
        />
      </TextFooter>
      <ContainerCustomText>
        <CustomText>
          <CustomField
            name="health"
            component={HealthSummary}
            firstSummary={firstSummary}
            thirdSummary={thirdSummary}
            mobile={windowWidth <= breakpoints.mobile}
          />
          {/* <ContainerUnitText> */}
          {/* {windowWidth > breakpoints.mobile && <UnitText>บาท</UnitText>} */}
          <UnitText>บาท</UnitText>
          <UnitText>สำหรับประกันสุขภาพ</UnitText>
          <CustomTooltip
            placement={`${
              windowWidth <= breakpoints.mobile ? 'bottom' : 'right'
            }`}
            title="ลดหย่อนตามที่จ่ายจริง แต่ไม่เกิน 15,000 และเมื่อรวมกับเบี้ยประกันชีวิตทั่วไปแล้วไม่เกิน 100,000 บาท">
            <CustomIconFooter icon={['fas', 'info-circle']} />
          </CustomTooltip>
          {/* </ContainerUnitText> */}
        </CustomText>
        <CustomText>
          <CustomField
            name="searchValue"
            component={LifeSummary}
            firstSummary={firstSummary}
            forthSummary={forthSummary}
            mobile={windowWidth <= breakpoints.mobile}
          />
          {/* <ContainerUnitText> */}
          {/* {windowWidth > breakpoints.mobile && <UnitText>บาท</UnitText>} */}
          <UnitText>บาท</UnitText>
          <UnitText>สำหรับประกันชีวิต</UnitText>
          <CustomTooltip
            placement={`${
              windowWidth <= breakpoints.mobile ? 'bottom' : 'right'
            }`}
            title="ลดหย่อนตามที่จ่ายจริง แต่ไม่เกิน 100,000 และเมื่อรวมกับเบี้ยประกันสุขภาพแล้วไม่เกิน 100,000 บาท">
            <CustomIconFooter icon={['fas', 'info-circle']} />
          </CustomTooltip>
          {/* </ContainerUnitText> */}
        </CustomText>
        <CustomText>
          <CustomField
            name="retire"
            component={RetireSummary}
            fiveSummary={fiveSummary}
            mobile={windowWidth <= breakpoints.mobile}
          />
          {/* <ContainerUnitText> */}
          {/* {windowWidth > breakpoints.mobile && <UnitText>บาท</UnitText>} */}
          <UnitText>บาท</UnitText>
          <UnitText>สำหรับประกันบำนาญ</UnitText>
          <CustomTooltip
            placement={`${
              windowWidth <= breakpoints.mobile ? 'bottom' : 'right'
            }`}
            title="ลดหย่อนตามที่จ่ายจริง แต่ไม่เกิน 200,000 และเมื่อรวมกับ กองทุนสำรองเลี้ยงชีพ/กบข./กองทุนสงเคราะห์ครูฯ/กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)/กองทุนการออมแห่งชาติ แล้วไม่เกิน 500,000 บาท">
            <CustomIconFooter icon={['fas', 'info-circle']} />
          </CustomTooltip>
          {/* </ContainerUnitText> */}
        </CustomText>
      </ContainerCustomText>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'arrow-right']}
        disabled={forthSummary < 0}>
        {' '}
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(
        state,
        'income',
        'lifePremiumCost',
        'healthPremiumCost',
        'premiumRetire',
        'rmf',
        'providentFund',
      ),
    }),
    {change},
  ),
  withState('firstSummary', 'setFirstSummary', 0),
  withState('secondSummary', 'setSecondSummary', 0),
  withState('thirdSummary', 'setThirdSummary', 0),
  withState('forthSummary', 'setForthSummary', 0),
  withState('fiveSummary', 'setFiveSummary', 0),
  withState('toolTipAmount', 'setToolTipAmount', 0),
  withState('toolTipAmount2', 'setToolTipAmount2', 0),
  withState('timeOut', 'initTimeOut', null),
  withHandlers({
    onSummary: ({
      totalList,
      setFirstSummary,
      setSecondSummary,
      setThirdSummary,
      setForthSummary,
      setFiveSummary,
      setToolTipAmount,
      setToolTipAmount2,
      change,
    }) => () => {
      const {
        income = '0',
        lifePremiumCost = '0',
        healthPremiumCost = '0',
        premiumRetire = '0',
        rmf = '0',
        providentFund = '0',
      } = totalList
      let first = convertNaNtoZero(income)
      let second = convertNaNtoZero(lifePremiumCost)
      let third = convertNaNtoZero(healthPremiumCost)
      let forth = convertNaNtoZero(premiumRetire)
      let five = convertNaNtoZero(rmf)
      let six = convertNaNtoZero(providentFund)

      let tooltipAmount = first * 0.15 > 200000 ? 200000 : first * 0.15
      let tooltipAmount2 = first * 0.15 > 500000 ? 500000 : first * 0.15

      let totalHealth = second + third

      setToolTipAmount(convertNaNtoZero(tooltipAmount))
      setToolTipAmount2(convertNaNtoZero(tooltipAmount2))

      setFirstSummary(totalHealth)
      change('Planning', 'summary_1', totalHealth)

      setSecondSummary(forth + five + six)
      change('Planning', 'summary_2', forth + five + six)

      setThirdSummary(15000 - third)
      change('Planning', 'health', totalHealth >= 100000 ? 0 : 15000 - third)

      setForthSummary(100000 - (second + third))
      change(
        'Planning',
        'searchValue',
        totalHealth >= 100000 ? 0 : 100000 - (second + third),
      )

      setFiveSummary(tooltipAmount + 100000 - totalHealth - forth)
      change(
        'Planning',
        'retire',
        convertNaNtoZero(tooltipAmount + 100000 - totalHealth - forth) <= 0
          ? 0
          : convertNaNtoZero(tooltipAmount + 100000 - totalHealth - forth),
      )
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onSummary()
    },
  }),
)

export default enhancer(TaxReductionForm)
