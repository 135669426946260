import React from 'react'
import {compose, branch} from 'recompose'
import styled from 'react-emotion'
import {withSpidus, withValue} from '../enhances'

const Main = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  display: flex;
  padding: 27px 86px;
  > div {
    text-align: center;
    font-family: 'Prompt';
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
  }

  > div:last-child {
    > span {
      font-family: 'Prompt';
      font-weight: 700;
    }
    > span:first-child {
      color: #ffb933;
    }
  }
  margin-bottom: 30px;
  @media (max-width: 500px) {
    max-width: 311px;
    flex-direction: column;
    padding: 20px 70px;
  }
`
const Text = props => (
  <Main className={props.className}>
    <div>คุณมีเวลาวางแผนภาษี&nbsp;</div>
    <div>
      <span>{props.value} วัน&nbsp;</span>
      <span>เท่านั้น</span>
    </div>
  </Main>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
)

export default enhance(Text)
